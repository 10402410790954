import Joi from '@hapi/joi';
import { getApiUrl, httpClient, phoneAllowedSymbols, phoneValidateRule, TFormInitParams } from '@plarin/core';
import { action, makeObservable } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { saveAuthTokens } from '../../components/app';
import { AConfirmPhoneStore } from '../../components/auth/auth-confirm-phone-form/confirm-phone.store';
import { navigateTo } from '../../routing/navigation';
import { routerPaths } from '../../routing/router-path';
import { STATUS } from '../../utils/constants';
import { TPhoneValidateReq, TPhoneValidateResp, TRegistrationPhoneReq, TRegistrationPhoneResp } from './types';

export type TRegistrationPhoneFormData = TRegistrationPhoneReq & TPhoneValidateReq;

const initData: TFormInitParams<TRegistrationPhoneFormData> = {
  phone: {
    validators: {
      change: phoneAllowedSymbols.allow(''),
      blur: phoneValidateRule,
      submit: Joi.string().required(),
    },
  },
  code: {
    validators: {
      blur: Joi.string().required(),
      submit: Joi.string().required(),
    },
  },
  // @ts-ignore
  captcha_token: {},
};

export class RegistrationPhoneFormStore extends AConfirmPhoneStore {
  constructor() {
    super(initData);
    makeObservable<this>(this, {
      submit: action.bound,
      onSendCode: action.bound,
      registration: action.bound,
      registrationSuccess: action.bound,
      phoneValidate: action.bound,
      phoneValidateSuccess: action.bound,
    });
  }

  onSendCode = () => {
    this.phoneValidate(this.data).then();
  };

  submit = this.onSubmit(() => {
    this.errors = this.validateAll('phone');
    this.registration(this.data).then();
  });

  registration = async (data: TRegistrationPhoneReq) => {
    this.execRequest<[TRegistrationPhoneResp]>([
      httpClient.post(getApiUrl<paths>('/api/v1/user/registration/phone'), { phone: `+${data.phone}` }),
    ])
      .then(this.registrationSuccess)
      .catch(this.onError);
  };

  registrationSuccess = ([resp]: [TRegistrationPhoneResp]) => {
    if (resp.status === STATUS.SUCCESS) {
      this.needConfirm = true;
      this.error = undefined;
      this.alertProps = undefined;
    }
  };

  phoneValidate = async (data: TPhoneValidateReq) => {
    this.execRequest<[TPhoneValidateResp]>([
      httpClient.post(getApiUrl<paths>('/api/v1/user/phone/validate'), { phone: `+${data.phone}`, code: data.code }),
    ])
      .then(this.phoneValidateSuccess)
      .catch(this.onError);
  };

  phoneValidateSuccess = ([resp]: [TPhoneValidateResp]) => {
    if (resp.status === STATUS.SUCCESS) {
      const {
        // expires,
        access_token: accessToken,
        refresh_token: refreshToken,
        refresh_expires: refreshExpires,
        // permissions,
        // roles,
      } = resp.data!;
      saveAuthTokens(accessToken, refreshToken, refreshExpires).then();
      navigateTo(routerPaths.auth.ROOT);
    }
  };

  retryCode = async () =>
    httpClient.post(getApiUrl<paths>('/api/v1/user/phone/send_code'), {
      phone: `+${this.data.phone}`,
    });
}
