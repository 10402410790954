export const textInvalidRequest = {
  errorUserAccount: {
    titlePage: 'Не удалось подключиться к вашей учетной записи',
    textDescription:
      'Ваши данные в порядке, но мы не можем подключиться к вашему аккаунту из-за технической проблемы на нашей стороне и уже занимаемся исправлением.',
    textRecommendation:
      'Пожалуйста попробуйте подключиться повторно через некоторое время. Если проблема возникает многократно, свяжитесь со службой поддержки по адресу',
    textButton: 'Обновить страницу',
  },
  errorWsList: {
    titlePage: 'Не удалось получить список пространств',
    textDescription:
      'Ваши данные в порядке, но мы не можем получить список пространств из-за технической проблемы на нашей стороне и уже занимаемся исправлением.',
    textRecommendation:
      'Пожалуйста попробуйте обновить список пространств через некоторое время. Если проблема возникает многократно, свяжитесь со службой поддержки по адресу',
    textButton: 'Обновить список',
  },
} as const;
