import { accessTokenName, Link, refreshTokenName } from '@plarin/core';
import { EmailReadIcon, LockIcon, PersonIcon, PhoneIcon, Typography } from '@plarin/design';
import { ModalActionNamesEnum, Modal } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { resetStores } from '../../components/app';
import { useAppDataStore } from '../../components/app-data-provider';
import { IntercomGlobal } from '../../components/intercom-provider/global-intercom';
import { routerPaths } from '../../routing/router-path';
import { getLocalSelectedWS } from '../../utils/localStorageSelectedWS';
import { LegalInformation } from './legal-information';
import { ProfileInfo } from './profile-info';
import { ChangeEmailContent, ChangeNameContent, ChangeNumberContent } from './profile-info/modalRender';
import { ProfilePassword } from './profile-password';
import { AddPasswordContent, ChangePasswordContent } from './profile-password/modalRender';
import { ProfileSocial } from './profile-social';
import { ModalContentType } from './profile.types';
import classes from './style.module.scss';

const renderModalContent = (type?: ModalContentType): React.ReactNode => {
  switch (type) {
    case 'changeAccountName':
      return <ChangeNameContent />;
    case 'changeAccountEmail':
      return <ChangeEmailContent />;
    case 'changePhonePhone':
      return <ChangeNumberContent />;
    case 'changePassword':
      return <ChangePasswordContent />;
    case 'addPassword':
      return <AddPasswordContent />;
  }
};

const Profile = observer(() => {
  const {
    profileStore: {
      modalVisible,
      closeModal,
      modalAction,
      modalContentType,
      modalTitle,
      disabledActions,
      disabledExit,
      WSRole,
      workspaces,
      data,
    },
  } = useAppDataStore();

  const HeaderIcon = () => {
    switch (modalContentType) {
      case 'changeAccountName':
        return <PersonIcon color="white" />;
      case 'changeAccountEmail':
        return <EmailReadIcon color="white" />;
      case 'changePhonePhone':
        return <PhoneIcon color="white" />;
      case 'changePassword':
        return <LockIcon color="white" />;
      case 'addPassword':
        return <LockIcon color="white" />;
    }
  };

  const Title = (
    <div className={classes.title}>
      <Typography size="PageLabel" weight={700}>
        Личный кабинет
      </Typography>
    </div>
  );

  const selectedWS = getLocalSelectedWS(data.user_id);
  const headerIsWhite = !(WSRole || workspaces?.map(el => el.workspace_id).includes(selectedWS || ''));

  const checkTokens = () => {
    const accessToken = localStorage.getItem(accessTokenName);
    const refreshToken = localStorage.getItem(refreshTokenName);

    if (!accessToken || !refreshToken) {
      IntercomGlobal.shutdown();
      resetStores();
      window.location.href = routerPaths.unAuth.LOGIN;
    }
  };

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, [closeModal]);

  const { profileStore } = useAppDataStore();

  useEffect(() => {
    profileStore.loadProfileWithoutRedirect();
  }, []);

  // Проверяем наличие токенов и делаем логаут, если их нет.
  useEffect(() => {
    const intervalId = setInterval(checkTokens, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={classes.profileWrapper}>
      {headerIsWhite && Title}
      <div className={classes.content}>
        <ProfileInfo />
        <ProfilePassword />
        <ProfileSocial />
        <LegalInformation />
      </div>

      <Modal
        open={modalVisible}
        closeModal={closeModal}
        workspaceHeader="profile"
        title={modalTitle}
        icon={HeaderIcon()}
        firstAction={modalAction}
        secondAction={{
          action: () => closeModal(),
          actionTitle: ModalActionNamesEnum.Cancel,
        }}
        disabledActions={disabledActions}
        disabledExit={disabledExit}
      >
        <>{renderModalContent(modalContentType)}</>
      </Modal>
    </div>
  );
});

export default Profile;
