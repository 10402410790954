import { IconStatus, Typography } from '@plarin/design';
import { Checkbox } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { TReportEntity } from '../../../../../../types/reports/reports';
import classes from './style.module.scss';
import { CheckesBlockProps, ClickCheckType, TState } from './types';

const entitiesData: TState[] = [
  { title: 'Рекламные кабинеты', checked: false, toReq: 'client' },
  { title: 'Рекламные кампании', checked: true, toReq: 'ad_plan' },
  { title: 'Группы объявлений', checked: false, toReq: 'ad_group' },
  { title: 'Объявления', checked: false, toReq: 'ad' },
];
const statusesData = [
  { title: 'Активные', checked: true, toReq: 1 },
  { title: 'Остановленные', checked: true, toReq: 0 },
  { title: 'Архивные', checked: false, toReq: 2 },
];

const initialCheckedData = (data: TState[], initialData?: TReportEntity[] | string[]) => {
  if (initialData) {
    return data.map(elem =>
      initialData.includes(elem.toReq as never) ? { ...elem, checked: true } : { ...elem, checked: false },
    );
  }
  return data;
};

export const CheckesBlock = ({ updateData, initialEntities, initialStatuses }: CheckesBlockProps) => {
  const [entities, setEntities] = useState<TState[]>(initialCheckedData(entitiesData, initialEntities));
  // @ts-ignore
  const [statuses, setStatuses] = useState<TState[]>(initialCheckedData(statusesData, initialStatuses));

  const clickCheck = useCallback(({ title, setState }: ClickCheckType) => {
    setState(prev => {
      return prev.map(el => (el.title === title ? { ...el, checked: !el.checked } : { ...el }));
    });
  }, []);

  const getCheckedItems = (data: TState[]): any => {
    return data.filter(el => el.checked).map(el => el.toReq);
  };

  const prepareUpdateData = (entities: TState[], statuses: TState[]): any => {
    const selectedEntities = getCheckedItems(entities);
    // Если ни одна опция среди entities не выбрана, то берем все entities для отчёта
    const entitiesForRequest = selectedEntities.length ? selectedEntities : entitiesData.map(el => el.toReq);
    const statusesForRequest = getCheckedItems(statuses);
    return { entities: entitiesForRequest, statuses: statusesForRequest };
  };

  useEffect(() => {
    updateData(prepareUpdateData(entities, statuses));
  }, [updateData, entities, statuses]);

  return (
    <div className={classes.checkBlock}>
      <div className={classes.advManage}>
        <div className={classes.checks}>
          <Typography size="Main" weight={600} componentProps={{ className: classes.checksTitle }}>
            Включить в отчет
          </Typography>

          {entities.map(el => (
            <div
              key={el.title}
              onClick={() => clickCheck({ title: el.title, setState: setEntities })}
              className={clsx(classes.box)}
            >
              <Checkbox className={classes.checkbox} value={el.checked} size="small" />
              <Typography size="Main">{el.title}</Typography>
            </div>
          ))}
        </div>

        <div className={classes.checks}>
          <Typography size="Main" weight={600} componentProps={{ className: classes.checksTitle }}>
            Статус
          </Typography>

          {statuses.map((el, index) => (
            <div
              key={index}
              onClick={() => clickCheck({ title: el.title, setState: setStatuses })}
              className={classes.box}
            >
              <Checkbox className={classes.checkbox} value={el.checked} size="small" />
              <IconStatus className={classes.iconStatus} status={el.title} />

              <Typography size="Main" componentProps={{ className: classes.statusText }}>
                {el.title}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
