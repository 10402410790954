import { isUrlProd } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDataStore } from '../app-data-provider';
import { MainMenu } from './main-menu';
import classes from './style.module.scss';

export const MenuBlock = observer(() => {
  const {
    menu: {
      menuVisible,
      isMenuHover,
      applyHover,
      removeHover,
      toggleVisibleMenu,
      menuData,
      openSection,
      selectMenuItem,
      deselectMenuItem,
      setMenuProjects,
      updateMenuData,
    },
    profileStore: {
      isAuthorized,
      workspaceShortname,
      wsDictionary: { projects },
    },
  } = useAppDataStore();

  const location = useLocation();
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    workspaceShortname && Object.keys(projects).length && !isUrlProd && setMenuProjects(projects);
    setForceRefresh(!forceRefresh); // заставляет корректно выбраться пункт меню, если мы заходим по прямой ссылке в один из Проетов в главном меню, например: plarin.ru/MyWorkspace/projects/64cba5d63ed1f52250d28ccd
  }, [workspaceShortname, projects]);

  useEffect(() => {
    const paths = location.pathname.split('/');
    const inWorkspace = paths.includes(workspaceShortname);
    let menuSectionPath: string | undefined = paths[2];
    let menuItemPath: string | undefined = paths[3];

    if (inWorkspace && menuSectionPath && menuItemPath) {
      // исходя из url мы находимся в разделе воркспейса, для которого нужно выделить соответствующий пункт в меню
      const menuSection = menuData.find(section => section.path === menuSectionPath);
      const menuItem = menuSection?.menuItems.find(item => item.menuItemKey === menuItemPath);

      // разворачиваем секцию меню
      menuSection && !menuSection.isOpen && openSection(menuSection.sectionIndex);
      // выделяем пункт меню
      menuSection && menuItem && selectMenuItem(menuItem?.menuItemKey, true, menuSection.sectionIndex);
    } else {
      // мы на какой-то странице, у которой нет соответствующего пункта в главном меню приложения
      deselectMenuItem();
    }
  }, [location.pathname, forceRefresh]);

  if (!isAuthorized) {
    return null;
  }

  return (
    <>
      <div className={clsx(classes.leftBlock, !menuVisible && classes.nonVisible)}>
        <MainMenu withHeader menuVisible={menuVisible} toggleVisibleMenu={toggleVisibleMenu} menuData={menuData} />
      </div>
      <div
        className={clsx(classes.newBlock, !menuVisible && isMenuHover && classes.visibleNewBlock, classes.menuWrapper)}
        onMouseEnter={applyHover}
        onMouseLeave={removeHover}
      >
        <MainMenu menuVisible={menuVisible} toggleVisibleMenu={toggleVisibleMenu} menuData={menuData} />
      </div>
    </>
  );
});
