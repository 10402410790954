import { isUrlProd } from '@plarin/utils';
import { GridApi, IRowNode } from 'ag-grid-community';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../buttons';
import {
  BidObjectType,
  BidObjectTypeEnum as ObjType,
  BidLimitsInput,
  BidTypeInput,
  BidValueInput,
  BidValueRelativeInput,
  fixedBidsAreDifferent,
  getCommonFixedBid,
  getCurrencySymbol,
  getItemsType,
  grabFixedBids,
  isKnownCurrency,
  minMaxCheck,
  SaveBlockingMessages,
  setCommonFixedBid,
  TAccountCurrency,
  TNewFixBids,
} from '../inputs';
import { MainTooltip } from '../tooltip';
import { getMaxBid, getMinBid } from './bidsUtils';
import { BidChart, ListProjections } from './components';
import { getItemsTypeForAdsReq, addPackageLimitMinMax, isPackageLimitBidError } from './strategy-form-utils';
import classes from './style.module.scss';
import {
  AvailableAdsItemResp,
  AvailableAdsProps,
  BidResp,
  FixBidSaveActionProps,
  TGetProjection,
  TRows,
} from './types';

type TFixedEditBidProps = {
  close: () => void;
  selectedRows: TRows;
  getProjection: TGetProjection;
  onSaveAction: (requestData: FixBidSaveActionProps, gridApi: GridApi) => Promise<BidResp>;
  gridApi: GridApi;
  getAvailableAds: (args: AvailableAdsProps) => any;
};

export enum FixedBidModes {
  current = 'Текущую',
  relative = 'Относительно текущей',
}

const inputsModeVariants = [
  {
    value: FixedBidModes.current,
    label: FixedBidModes.current,
  },
  {
    value: FixedBidModes.relative,
    label: FixedBidModes.relative,
  },
];

export const FixedEditBid = ({
  close,
  selectedRows,
  getProjection,
  onSaveAction,
  gridApi,
  getAvailableAds,
}: TFixedEditBidProps) => {
  const [mode, setMode] = useState(FixedBidModes.current); // 'Текущую'
  const [currency] = useState<TAccountCurrency>(isKnownCurrency(selectedRows[0].accountCurrency));
  const [selectedItems, setSelectedItems] = useState<TRows>(selectedRows);
  const [isPackageLimitBidAdded, setIsPackageLimitBidAdded] = useState(false);
  const [errorPackageLimitBid, setErrorPackageLimitBid] = useState(false);

  /* Лимиты размера ставки ///////////////////// */

  const [allMaxBids, setAllMaxBids] = useState<number[]>(
    Array.from(new Set(selectedItems.map(row => row.packageLimitBidMax))),
  );
  const [allMinBids, setAllMinBids] = useState<number[]>(
    Array.from(new Set(selectedItems.map(row => row.packageLimitBidMin))),
  );

  const [biggestMaxBid, setBiggestMaxBid] = useState<number | undefined>(getMaxBid(allMaxBids));
  const [smallestMaxBid, setSmallestMaxBid] = useState<number | undefined>(getMinBid(allMaxBids));

  const [biggestMinBid, setBiggestMinBid] = useState<number | undefined>(getMaxBid(allMinBids));
  const [smallestMinBid, setSmallestMinBid] = useState<number | undefined>(getMinBid(allMinBids));

  const [customMaxBid, setCustomMaxBid] = useState<number | undefined>(smallestMaxBid);
  const [customMinBid, setCustomMinBid] = useState<number | undefined>(biggestMinBid);

  const [isShownMinMax, setIsShownMinMax] = useState(false);

  /* Массив с новыми данными по ставками ///////////////////// */

  const itemsType: BidObjectType = useMemo(() => getItemsType(window.location.pathname), []);

  const [newBidsValues, setNewBidsValues] = useState<TNewFixBids>(grabFixedBids(selectedItems, itemsType));

  /* Состояния кнопки сохранения ///////////////////// */

  const [maySave, setMaySave] = useState(false);
  const [blockingMessage, setBlockingMessage] = useState('');
  const [customLimitsError, setCustomLimitsError] = useState(false); // пользователь задал некорректные кастомные лимиты для размера ставки

  // ///////////////////////////////////////////

  // массив с уникальными значениями текущих (пришедших с бэкеда) ставок. Собирается, даже если выбрана только 1 кампания. Если выделены несколько кампаний, позволит понять, разные у них ставки или одинаковые
  const uniqueUnchangedBidValuesSet = Array.from(new Set(selectedItems.map(row => row.price)));

  const rowsUnchangedBids = useMemo(() => grabFixedBids(selectedItems, itemsType), [selectedItems]);

  const areInputsDisabled = [biggestMaxBid, smallestMaxBid, biggestMinBid, smallestMinBid].some(
    bid => bid === undefined,
  );

  const removeItem = (objId: string | number) => {
    const newSelected = selectedItems.filter(el => {
      if (itemsType === ObjType.ad_group) return el.adGroupId !== objId;
      if (itemsType === ObjType.ad_plan) return el.adPlanId !== objId;
      if (itemsType === ObjType.banner) return el.adId !== objId;

      return true;
    });

    setSelectedItems(newSelected);

    setNewBidsValues(
      newBidsValues.filter(newBidsData =>
        newSelected.find(item => {
          if (itemsType === ObjType.ad_group) return item.adGroupId === newBidsData.item_id;
          if (itemsType === ObjType.ad_plan) return item.adPlanId !== objId;
          if (itemsType === ObjType.banner) return item.adId !== objId;

          return false;
        }),
      ),
    );
  };

  const minMaxOk = minMaxCheck(getCommonFixedBid(newBidsValues), customMinBid, customMaxBid);

  const updateCommonBid = (newBid: number) => {
    setNewBidsValues(setCommonFixedBid(newBidsValues, newBid));
  };

  const getBidById = (id: string, nodes: IRowNode[]): number => {
    const key = nodes.filter(row => row.id === id)[0].key;
    return newBidsValues.find(bidData => bidData.item_id === key)?.bid || -1;
  };

  const checkIfCanSave = () => {
    // Ошибка, если проблемы с полем package_limit_bid
    if (errorPackageLimitBid) {
      setBlockingMessage(SaveBlockingMessages.itemErrorVA);
      return setMaySave(false);
    }

    // ошибка, когда мы не понимаем, что редактируем: адплан, гр.кампаний или баннеры
    if (!itemsType) {
      setBlockingMessage(SaveBlockingMessages.itemTypeError);
      return setMaySave(false);
    }

    if (mode === FixedBidModes.current) {
      // I. Выбраны несколько кампаний
      if (selectedItems.length > 1) {
        // 1.1 Выбраны несколько кампаний с разными ставками
        if (uniqueUnchangedBidValuesSet.length > 1) {
          // 1.2 выбраны несколько кампаний с разными ставками, новая ставка не была задана
          if (getCommonFixedBid(newBidsValues) === -1) {
            setBlockingMessage(SaveBlockingMessages.setOneNewBidForMultiple);
            return setMaySave(false);
          }

          // 1.3 новая ставка для нескольких кампаний с разными ставками была задана и находится в допустимом диапазоне
          if (minMaxOk) {
            setBlockingMessage('');
            return setMaySave(true);
          }
          // 1.4. обработка исключения, когда новая ставка задана, но не попадает, в min-max диапазон, находится ниже
        }

        // 2. Выбраны несколько кампаний с одинаковой ставкой
        if (uniqueUnchangedBidValuesSet.length === 1) {
          // 2.1 У нескольких выбранных кампаний была одинаковая ставка и её пока не изменили
          if (getCommonFixedBid(newBidsValues) === +selectedItems[0].price) {
            setBlockingMessage(SaveBlockingMessages.setOneNewBidForMultiple); // здесь есть неразрывные пробелы
            return setMaySave(false);
          }

          // 2.2. У нескольких выбранных кампаний была одинаковая ставка и её заменили на новую, новое значение попадает в min-max диапазон
          if (minMaxOk) {
            setBlockingMessage('');
            return setMaySave(true);
          }
        }
        // 2.3 обработка исключения, когда новая ставка задана, но не попадает, в min-max диапазон, находится ниже
      }

      // II. Выбрана одна кампания
      if (selectedItems.length === 1) {
        // 3.1 если выбрана одна кампания, проверям, чтобы новая ставка отличалась от старой
        if (newBidsValues[0].bid === +selectedItems[0].price) {
          setBlockingMessage(SaveBlockingMessages.setNewBid);
          return setMaySave(false);
        }

        // 3.2. проверяем, чтобы ставка находилась в допустимом интеврале: больше минимума, меньше максимума
        if (minMaxOk) {
          setBlockingMessage('');
          return setMaySave(true);
        }

        // 3.3. обработка исключения, когда новая ставка задана, но не попадает, в min-max диапазон, находится ниже TODO: компонент с таблицей выбранных ставок роняет приложение, если одна из ставок превысила max
      }

      // 4. Проверяем, чтобы ставка укладывалась в min-max диапазон
      if (customMaxBid && getCommonFixedBid(newBidsValues) > customMaxBid) {
        setBlockingMessage(SaveBlockingMessages.maxLimitError + ` ${customMaxBid} ${getCurrencySymbol(currency)}`);
        return setMaySave(false);
      }

      if (customMinBid && getCommonFixedBid(newBidsValues) <= customMinBid) {
        setBlockingMessage(SaveBlockingMessages.setNewBid);
        return setMaySave(false);
      }
    }

    // *****************************************************************
    if (mode === FixedBidModes.relative) {
      // 5.0. Если пользователь криво задал собственные лимиты ставки и в инпутах для лимитов включилась ошибка, мы выключаем кнопку "Сохранить"
      if (customLimitsError) {
        setBlockingMessage(SaveBlockingMessages.limitsError);
        return setMaySave(false);
      }

      // 5.1 Меняем ставку 1 кампании в режиме 'Относительно текущей'
      if (selectedItems.length === 1) {
        // 5.3 Ставка 1 кампании не была изменена
        if (newBidsValues[0].bid === +selectedItems[0].price) {
          setBlockingMessage(SaveBlockingMessages.setNewBid);
          return setMaySave(false);
        }

        // 5.3 Ставка 1 кампании была изменена
        setBlockingMessage('');
        return setMaySave(true);
      }

      // 6. Меняем ставку нескольких кампаний в режиме 'Относительно текущей'

      // проверяем, поменялась ли хоть одна ставка из-за действий пользователя
      const isChanged =
        newBidsValues.map(data => data.bid).toString() !== selectedItems.map(data => data.price).toString();

      isChanged ? setBlockingMessage('') : setBlockingMessage(SaveBlockingMessages.setNewDifferentBids);

      return setMaySave(isChanged);
    }

    // 7. Сюда мы не должны приходить ни при каких обстоятельствах
    setBlockingMessage('Произошла ошибка');
    return setMaySave(false);
  };

  const onSaveActionWrapper = (gridApi: GridApi) => {
    const requestData: FixBidSaveActionProps = newBidsValues.map(data => ({
      name: 'FIX_BID',
      obj_type: itemsType,
      obj_id: +data.item_id,
      network: data.network,
      bid: data.bid,
    }));

    onSaveAction(requestData, gridApi).then();

    close();
  };

  // предотвращает выделение пунктов меню при нажатии клавиш
  const stopPropagation = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  // При переключении между формами "Текущую" и "Относительно текущей" ресетит изменение размера ставки, а также допустимые минимум и максимум для ставок (у разных форм они будут разные).
  useEffect(() => {
    setNewBidsValues(grabFixedBids(selectedItems, itemsType));
  }, [mode]);

  useEffect(() => {
    if (mode === FixedBidModes.current) {
      setCustomMaxBid(smallestMaxBid);
      setCustomMinBid(biggestMinBid);
    }

    if (mode === FixedBidModes.relative) {
      setCustomMaxBid(biggestMaxBid);
      setCustomMinBid(smallestMinBid);
    }
  }, [mode, smallestMaxBid, biggestMaxBid, smallestMinBid, biggestMinBid]);

  // если в форме "Относительно текущей" пользователь скроет поля для лимитов ставки, пользовательские лимиты примут дефолтные значения
  useEffect(() => {
    if (mode === FixedBidModes.relative) {
      setCustomMaxBid(biggestMaxBid);
      setCustomMinBid(smallestMinBid);
    }
  }, [biggestMaxBid, isShownMinMax, mode, smallestMinBid]);

  useEffect(() => {
    setBiggestMaxBid(getMaxBid(allMaxBids));
    setSmallestMaxBid(getMinBid(allMaxBids));
  }, [allMaxBids, selectedItems]);

  useEffect(() => {
    setBiggestMinBid(getMaxBid(allMinBids));
    setSmallestMinBid(getMinBid(allMinBids));
  }, [allMinBids, selectedItems]);

  useEffect(() => {
    checkIfCanSave();
  });

  // Добавляем поля packageLimitBid
  useEffect(() => {
    const itemsTypeForRequest = getItemsTypeForAdsReq(itemsType);
    const ids = newBidsValues.map(i => Number(i.item_id));
    itemsTypeForRequest &&
      getAvailableAds({
        ids: ids,
        itemsType: itemsTypeForRequest,
      }).then((res: AvailableAdsItemResp[]) => {
        if (res) {
          addPackageLimitMinMax(res, selectedItems, setSelectedItems);
          setIsPackageLimitBidAdded(true);
        }
      });
  }, []);

  // когда пользователь удаляет из списка какую-то кампанию, обновляем коллекции минимальных и максимальных дефолтных лимитов для ставки
  useEffect(() => {
    if (isPackageLimitBidAdded) {
      if (!isPackageLimitBidError(selectedItems)) {
        setAllMaxBids(Array.from(new Set(selectedItems.map(row => row.packageLimitBidMax))));
        setAllMinBids(Array.from(new Set(selectedItems.map(row => row.packageLimitBidMin))));
      } else {
        setErrorPackageLimitBid(true);
      }
    }
  }, [selectedItems, isPackageLimitBidAdded]);

  useEffect(() => {
    setNewBidsValues(grabFixedBids(selectedItems, itemsType));
  }, [isPackageLimitBidAdded]);

  return (
    <div className={classes.mainWrap} onKeyDown={stopPropagation} tabIndex={-1}>
      <div className={classes.inputsWrap}>
        <div className={classes.formInputWrap}>
          <BidTypeInput
            mode={mode}
            setMode={setMode}
            inputsModeVariants={inputsModeVariants}
            header="Изменить ставку"
            tooltipMessage="Изменить ставку"
          />
        </div>

        {mode === FixedBidModes.current && (
          <div className={classes.mb12}>
            <BidValueInput
              selectedItems={newBidsValues}
              setNewBids={setNewBidsValues}
              bidsAreDifferent={fixedBidsAreDifferent(newBidsValues)}
              getCommonBid={getCommonFixedBid}
              setCommonBid={setCommonFixedBid}
              currency={currency}
              maxBid={smallestMaxBid} // если выбрано несколько ставок с разным максимумом, то конкретно для этого инпута делаем значением лимита ставки минимальный из имеющихся максимумов
              minBid={biggestMinBid}
              pinSize="12"
              header="Новая ставка"
              isDisabled={areInputsDisabled}
            />
          </div>
        )}

        {mode === FixedBidModes.relative && (
          <>
            <BidValueRelativeInput
              previousBids={rowsUnchangedBids}
              setNewBids={setNewBidsValues}
              currencySymbol={getCurrencySymbol(currency)}
              maxBid={customMaxBid && biggestMaxBid && customMaxBid < biggestMaxBid ? customMaxBid : biggestMaxBid}
              minBid={customMinBid && smallestMinBid && customMinBid > smallestMinBid ? customMinBid : smallestMinBid}
              openMinMax={() => setIsShownMinMax(!isShownMinMax)}
              isDisabled={areInputsDisabled}
            />

            {isShownMinMax && (
              <div className={classes.mb12}>
                <BidLimitsInput
                  currencySymbol={getCurrencySymbol(currency)}
                  maxBid={biggestMaxBid}
                  minBid={smallestMinBid}
                  customMaxBid={customMaxBid}
                  customMinBid={customMinBid}
                  setCustomMaxBid={setCustomMaxBid}
                  setCustomMinBid={setCustomMinBid}
                  customLimitsErrorSetter={setCustomLimitsError}
                  isDisabled={areInputsDisabled}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className={classes.contentWrap}>
        {selectedItems.length > 1 ? (
          <ListProjections
            itemsBids={newBidsValues}
            selectedRows={selectedItems}
            removeItem={removeItem}
            getProjection={getProjection}
            itemType={itemsType}
          />
        ) : (
          selectedItems.length === 1 &&
          !!newBidsValues[0].maxBid && (
            <BidChart
              bidValue={
                customMaxBid && newBidsValues[0].bid > customMaxBid ? selectedItems[0].price : newBidsValues[0].bid
              }
              setBidValue={updateCommonBid}
              selectedRows={selectedItems}
              getProjection={getProjection}
              mode={mode}
              trueMaxBid={Number(newBidsValues[0].maxBid)}
              itemType={itemsType}
            />
          )
        )}
      </div>

      <div className={classes.btnsContainer}>
        <MainTooltip
          tooltipMessage={blockingMessage}
          isVisible={!!blockingMessage}
          followCursor={true}
          component="span"
          placement="bottom-start"
        >
          <Button
            disabled={!maySave}
            variant="filled"
            size="small"
            color="primary"
            onClick={() => onSaveActionWrapper(gridApi)}
          >
            Сохранить
          </Button>
        </MainTooltip>

        <Button variant="outlined" size="small" color="primary" onClick={close}>
          Отменить
        </Button>
      </div>
    </div>
  );
};
