import { CellClassParams, Column, GetQuickFilterTextParams } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { returnCellRules } from '../../utils/manage-vk';
import { ActionsName } from '../actions-name';
import { NameNavigationCell } from '../name-navigation-cell';

enum DefaultColumnNamesEnum {
  ACCOUNTS,
  ADPLANS,
  ADGROUPS,
  ADS,
}

export const defaultColumns: Record<keyof typeof DefaultColumnNamesEnum, Column> = {
  ACCOUNTS: {
    headerName: 'Рекламный кабинет',
    headerComponentParams: { subheader: 'ID Рекламного кабинета' },
    field: 'accountName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      firstLine: 'accountName',
      secondLine: 'accountUsername',
      mayCopySecondLine: true,
      additionalInfoToCopy: 'accountId',
      currentStatusName: 'status',
      ActionsName: ActionsName,
      disableEditName: true,
      suppressCount: true,
      cellName: 'accounts',
      selectedTab: ManageVkTabNameEnum.ACCOUNTS,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) =>
      `${params.value} ${params.data.accountUsername} ${params.data.accountId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADPLANS: {
    headerName: 'Кампания',
    headerComponentParams: { subheader: 'ID Кампании' },
    field: 'adPlanName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      firstLine: 'adPlanName',
      secondLine: 'adPlanId',
      mayCopySecondLine: true,
      currentStatusName: 'status',
      ActionsName: ActionsName,
      suppressCount: true,
      cellName: 'adplans',
      selectedTab: ManageVkTabNameEnum.ADPLANS,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.adPlanId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADGROUPS: {
    headerName: 'Группа объявлений',
    headerComponentParams: { subheader: 'ID Группы объявлений' },
    field: 'adGroupName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      firstLine: 'adGroupName',
      secondLine: 'adGroupId',
      mayCopySecondLine: true,
      currentStatusName: 'status',
      ActionsName: ActionsName,
      suppressCount: true,
      cellName: 'adgroups',
      selectedTab: ManageVkTabNameEnum.ADGROUPS,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.adGroupId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADS: {
    headerName: 'Объявление',
    headerComponentParams: { subheader: 'ID Объявления' },
    field: 'adName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      firstLine: 'adName',
      secondLine: 'adId',
      mayCopySecondLine: true,
      currentStatusName: 'status',
      ActionsName: ActionsName,
      suppressCount: true,
      cellName: 'ads',
      selectedTab: ManageVkTabNameEnum.ADS,
    },

    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.data.adName} ${params.data.adId}`,
    minWidth: 300,
    lockPinned: true,
  },
};
