import { BasicStore, httpClient4 } from '@plarin/core';
import { NotificationErrorTitle } from '@plarin/utils';
import { makeObservable, observable, runInAction } from 'mobx';
import { IwsList } from '../../types/profile/types';
import { STATUS } from '../utils/constants';

export class WsStore extends BasicStore {
  wsList: IwsList[] = [];
  errorWsList: boolean = false;
  isLoading: boolean = false;
  isOpenDrawerNewWS: boolean = false;

  constructor() {
    super();
    makeObservable<this>(this, {
      wsList: observable,
      isLoading: observable,
      isOpenDrawerNewWS: observable,
      errorWsList: observable,
    });
  }

  setShowDrawerNewWS = (show: boolean) => runInAction(() => (this.isOpenDrawerNewWS = show));
  setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));
  setWsList = (data: IwsList[]) => runInAction(() => (this.wsList = data));
  setErrorWsList = (error: boolean) => runInAction(() => (this.errorWsList = error));

  getWsList = () => {
    this.setIsLoading(true);
    this.setErrorWsList(false);
    this.execRequest<IwsList[]>(httpClient4.get('/api/v1/user/workspace/list'))
      .then(data => {
        this.setWsList(data);
      })
      .catch(err => {
        this.setErrorWsList(true);
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
          message: err,
        });
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };
}
