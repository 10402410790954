import { DeleteIcon, PauseIcon, PlayIcon, PlusCircleIcon, SettingsIcon } from '@plarin/design';
import { GridApi, Popover } from '@plarin/inputs';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { ModalTypes } from '../../../../src/utils/integration-converters';
import { TAFStoreData } from '../../../../types/connect-af/types';
import { TGAStoreData } from '../../../../types/connect-ga/types';
import { TYMStoreData } from '../../../../types/connect-ym/types';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

const OptionsName = {
  PLAY: 'Включить интеграцию',
  PAUSE: 'Выключить интеграцию',
  REMOVE: 'Отключить аккаунт и удалить все данные',
  DELETE_INTEGRATION: 'Удалить интеграцию и все данные',
  ADD_APP: 'Добавить приложение',
  DELETE_APP: 'Удалить приложение',
};

type SettingsCellProps = {
  // TODO тип дата для YD
  data: TYMStoreData | TGAStoreData | TAFStoreData;
  page: 'ym' | 'ga' | 'yd' | 'af';
  gridApi: GridApi;
  agency?: string;
};

export const SettingsCellMenu = observer(({ data, page, gridApi, agency }: SettingsCellProps) => {
  // eslint-disable-next-line
  const { _id: parent_id, id: child_id, header1, plarin_status: status } = data;
  const {
    integrationsYM: { setModalData: setModalDataYM, enableYM },
    integrationsGA: { setModalData: setModalDataGA, enableGA },
    integrationsYD: { setModalData: setModalDataYD, enableYD },
    integrationsAF: { enableIntegrationAF, setModalDataAF },
  } = useAppDataStore();

  const YMoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? setModalDataYM({
              type: ModalTypes.pause,
              names: [header1],
              // @ts-ignore
              selectedRows: [data],
              gridApi: gridApi,
            })
          : enableYM({
              // @ts-ignore
              parentRows: data.isParent ? [data] : [],
              // @ts-ignore
              childRows: data.isParent ? [] : [data],
              gridApi,
            });
        setAnchorEl(null);
      },
    },
  ];

  // дополнительная опция для удаления YM-родителя
  const YmDisconnectAccount = [
    {
      name: OptionsName.REMOVE,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalDataYM({
          type: ModalTypes.remove,
          names: [header1],
          // @ts-ignore
          selectedRows: data.isParent ? [data] : [],
          gridApi: gridApi,
        });
        setAnchorEl(null);
      },
    },
  ];

  const GAoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? setModalDataGA({
              type: ModalTypes.pause,
              names: [header1],
              // @ts-ignore
              selectedRows: [data],
              gridApi: gridApi,
            })
          : enableGA({
              // @ts-ignore
              parentRows: data.isParent ? [data] : [],
              // @ts-ignore
              childRows: data.isParent ? [] : [data],
              gridApi,
            });
        setAnchorEl(null);
      },
    },
  ];

  // дополнительная опция для удаления ПФ-родителя
  const GaDisconnectAccount = [
    {
      name: OptionsName.REMOVE,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalDataGA({
          type: ModalTypes.remove,
          names: [header1],
          // @ts-ignore
          selectedRows: data.isParent ? [data] : [],
          gridApi: gridApi,
        });
        setAnchorEl(null);
      },
    },
  ];

  const YDoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? // @ts-ignore
            setModalDataYD({ type: 'pause', name: [header1], ids: [child_id || parent_id] })
          : // @ts-ignore
            enableYD({ db_ids: [child_id || parent_id] }, gridApi);
        setAnchorEl(null);
      },
    },
  ];

  // дополнительная опция для удаления YD-родителя
  const YdDisconnectAccount = [
    {
      name: OptionsName.REMOVE,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        // @ts-ignore
        setModalDataYD({ type: 'remove', name: [header1], ids: [parent_id] });
        setAnchorEl(null);
      },
    },
  ];

  const AFoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? // @ts-ignore
            setModalDataAF({
              type: ModalTypes.pause,
              // @ts-ignore
              selectedRows: [data],
              name: [header1],
              gridApi: gridApi,
            })
          : // @ts-ignore
            enableIntegrationAF({
              // @ts-ignore
              parentRows: data.isParent ? [data] : [],
              // @ts-ignore
              childRows: data.isParent ? [] : [data],
              gridApi,
            });
        setAnchorEl(null);
      },
    },
  ];

  // дополнительная опция для удаления AF-родителя
  const AFDisconnectAccount = [
    {
      name: OptionsName.DELETE_INTEGRATION,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalDataAF({
          type: ModalTypes.remove,
          // @ts-ignore
          selectedRows: [data],
          name: [data.header1],
        });
        setAnchorEl(null);
      },
    },
  ];

  // дополнительная опция для добавления приложения
  const AFAddApp = [
    {
      name: OptionsName.ADD_APP,
      iconLeft: () => <PlusCircleIcon />,
      action: () => {
        setModalDataAF({
          type: ModalTypes.addApp,
          // @ts-ignore
          selectedRows: [data],
          name: [data.header1],
        });
        setAnchorEl(null);
      },
    },
  ];

  // дополнительная опция для удаления приложения
  const AFDeleteApp = [
    {
      name: OptionsName.DELETE_APP,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalDataAF({
          type: ModalTypes.deleteApp,
          // @ts-ignore
          selectedRows: [data],
          name: [data.header1],
        });
        setAnchorEl(null);
      },
    },
  ];

  const options = useMemo(() => {
    switch (page) {
      case 'ym':
        return data.isParent ? [...YMoptions, ...YmDisconnectAccount] : YMoptions;
      case 'ga':
        return data.isParent ? [...GAoptions, ...GaDisconnectAccount] : GAoptions;
      case 'yd':
        return data.isParent ? [...YDoptions, ...YdDisconnectAccount] : YDoptions;
      case 'af': {
        // @ts-ignore
        if (agency || data._parent_type === 'agency') {
          return data.isParent ? [...AFoptions, ...AFAddApp, ...AFDisconnectAccount] : [...AFoptions, ...AFDeleteApp];
        }
        return data.isParent ? [...AFoptions, ...AFDisconnectAccount] : [...AFoptions];
      }
    }
  }, [status, page, agency, data]);

  // //////////////////////////

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const onOpen = useCallback((e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div
        ref={ref => {
          if (!ref) return;
          ref.onclick = e => onOpen(e, ref);
        }}
        className={clsx(classes.settingsIcon, !!anchorEl && classes.forceVisibility)}
      >
        <SettingsIcon size={16} color="secondary" />
      </div>

      <Popover anchorEl={anchorEl} isOpen={!!anchorEl} setIsOpen={onClose} dataList={options} />
    </>
  );
});
