import { CheckIcon } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import classes from './customMenuItem.module.scss';

interface ICustomMenuItem extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.Ref<HTMLDivElement>;
  onClick?: (e?: React.MouseEvent) => void;
  sizeItem?: 'small' | 'medium' | 'large';
  className?: string;
  hover?: boolean;
  selected?: boolean;
  selectedIcon?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  getRef?: (el: HTMLDivElement | null) => void;
  value?: string;
  disabled?: boolean;
  placeholder?: boolean;
}

export const CustomMenuItem: React.FC<ICustomMenuItem> = ({
  disabled,
  leftIcon,
  rightIcon,
  sizeItem = 'medium',
  hover = true,
  children,
  className,
  onClick,
  selected,
  selectedIcon = true,
  placeholder,
  getRef,
  value, // начение для компоненты select
  ...res
}) => {
  return (
    <div
      onClick={e => {
        if (disabled) return null;
        onClick && onClick(e);
      }}
      className={clsx(
        classes.menuListItem,
        disabled && classes.disabled,
        placeholder && classes.displayNone,
        selected && classes.menuListItemSelected,
        hover && classes.menuListItemHover,
        className && className,
        sizeItem && classes[sizeItem],
      )}
      ref={el => {
        getRef && getRef(el);
      }}
      {...res}
    >
      {leftIcon}
      {children}
      {rightIcon}
      {selected && selectedIcon && <CheckIcon className={classes.checked} />}
    </div>
  );
};

export const CustomMenuItemSelect: React.FC<ICustomMenuItem & { selectedItem: boolean }> = ({
  children,
  selectedItem,
  className,
  ...res
}) => {
  return (
    <CustomMenuItem
      {...res}
      hover={false}
      selected={false}
      selectedIcon={false}
      className={clsx(classes.bidTypeVariant, selectedItem && classes.selected, className && className)}
    >
      {children}
    </CustomMenuItem>
  );
};
