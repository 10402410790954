import { MenuIcon, Typography } from '@plarin/design';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { navigateTo } from '../../../routing/navigation';
import classes from './style.module.scss';

type TMenuFooterWorkspace = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MenuFooterWorkspace = observer(({ setOpen }: TMenuFooterWorkspace) => {
  const [footerHover, setFooterHover] = useState<boolean>(false);
  return (
    <>
      <div
        className={classes.workspaceFooter}
        onMouseOver={() => setFooterHover(true)}
        onMouseLeave={() => setFooterHover(false)}
        onClick={() => {
          setOpen(false);
          navigateTo('/wslist');
        }}
      >
        <MenuIcon className={classes.workspaceIconFooter} color={footerHover ? 'white' : 'main'} />
        <Typography size="Main" color={footerHover ? 'TextWhite' : 'TextPrimary'}>
          Список пространств
        </Typography>
      </div>
    </>
  );
});
