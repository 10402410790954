import { WsAvatar, WsItemType, EyeOffIcon, LockIcon } from '@plarin/design';
import { typografNames } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { HightLightText } from '../../../hightlight-text';
import { AvatarSkeleton } from '../../../preloader/skeleton/avatar-sceleton';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

interface Props extends ICellRendererParams {
  data: any;
  wsType: WsItemType;
  SettingMenu: React.FC<{ data: any }>;
}

export const SquareAvatarNameRenderer = ({ node, data, SettingMenu, api, wsType }: Props) => {
  const filterValue = api.getQuickFilter() || '';

  if (node.data.cellLoadingName && node.data?.cellLoadingName?.includes('name'))
    return <AvatarSkeleton size="long" height={17} />;

  return (
    <div className={classes.wrap}>
      <div className={classes.nameWrap}>
        {data.name && (
          <WsAvatar
            data={data}
            type={wsType}
            forceGray={node.data.status === 'inactive' || node.data.status === 'completed' || node.data.is_archived}
          />
        )}
        <EllipsisTooltip tooltipMessage={node.data.name} maxWidth={480}>
          <HightLightText
            size="Caption"
            weight={600}
            textEllips={true}
            text={typografNames(data.name)}
            filterValue={filterValue}
          />
        </EllipsisTooltip>
      </div>
      <div className={classes.menu}>
        {data.hidden && (
          <EllipsisTooltip tooltipMessage="Невидимая команда">
            <EyeOffIcon color="disabled" size={16} />
          </EllipsisTooltip>
        )}
        {data.closed && (
          <EllipsisTooltip tooltipMessage="Закрытая команда">
            <LockIcon color="disabled" size={16} />
          </EllipsisTooltip>
        )}
      </div>
      <SettingMenu data={node.data} />
    </div>
  );
};
