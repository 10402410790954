import { getOsIcon } from '@plarin/inputs/src/table/cell-renderers/two-lines-renderer/IconsToASecondLine';

export const PlatformOptions = [
  {
    value: 'placeholder',
    label: 'Платформа',
  },
  {
    value: 'ios',
    label: 'IOS',
    iconOs: getOsIcon(false, 'ios'),
  },
  {
    value: 'android',
    label: 'Android',
    iconOs: getOsIcon(false, 'android'),
  },
];

export const TimezoneOptions = [
  {
    value: 'placeholder',
    label: 'Часовой пояс',
  },
  { value: 'Etc/GMT-12', label: 'UTC−12:00 (Международная линия перемены даты)' },
  { value: 'Pacific/Pago_Pago', label: 'UTC−11:00 (Самоа)' },
  { value: 'Pacific/Honolulu', label: 'UTC−10:00 (Гавайское время)' },
  { value: 'America/Anchorage', label: 'UTC−09:00 (Аляскинское время)' },
  { value: 'America/Los_Angeles', label: 'UTC−08:00 (Тихоокеанское время)' },
  { value: 'America/Denver', label: 'UTC−07:00 (Горное время)' },
  { value: 'America/Chicago', label: 'UTC−06:00 (Центральное время)' },
  { value: 'America/New_York', label: 'UTC−05:00 (Восточное время)' },
  { value: 'America/Halifax', label: 'UTC−04:00 (Атлантическое время)' },
  { value: 'America/Sao_Paulo', label: 'UTC−03:00 (Бразилиа)' },
  { value: 'Atlantic/South_Georgia', label: 'UTC−02:00 (Южная Георгия и Южные Сандвичевы острова)' },
  { value: 'Atlantic/Azores', label: 'UTC−01:00 (Азорские острова)' },
  { value: 'Etc/GMT', label: 'UTC±00:00 (Среднее время по Гринвичу)' },
  { value: 'Europe/Berlin', label: 'UTC+01:00 (Центральноевропейское время)' },
  { value: 'Europe/Kyiv', label: 'UTC+02:00 (Восточноевропейское время)' },
  { value: 'Europe/Moscow', label: 'UTC+03:00 (Московское время)' },
  { value: 'Europe/Samara', label: 'UTC+04:00 (Самарское время)' },
  { value: 'Asia/Yekaterinburg', label: 'UTC+05:00 (Екатеринбургское время)' },
  { value: 'Asia/Omsk', label: 'UTC+06:00 (Омское время)' },
  { value: 'Asia/Krasnoyarsk', label: 'UTC+07:00 (Красноярское время)' },
  { value: 'Asia/Irkutsk', label: 'UTC+08:00 (Иркутское время)' },
  { value: 'Asia/Yakutsk', label: 'UTC+09:00 (Якутское время)' },
  { value: 'Asia/Vladivostok', label: 'UTC+10:00 (Владивостокское время)' },
  { value: 'Asia/Magadan', label: 'UTC+11:00 (Магаданское время)' },
  { value: 'Asia/Kamchatka', label: 'UTC+12:00 (Камчатское время)' },
  { value: 'Pacific/Tongatapu', label: 'UTC+13:00 (Тонга)' },
  { value: 'Pacific/Kiritimati', label: 'UTC+14:00 (Линийные острова)' },
];

export const CurrencyOptions = [
  {
    value: 'placeholder',
    label: 'Валюта',
  },
  {
    value: 'RUB',
    label: 'Рубли, ₽',
  },
  {
    value: 'USD',
    label: 'Доллар США, $',
  },
  {
    value: 'EUR',
    label: 'Евро, €',
  },
  {
    value: 'GBP',
    label: 'Фунт стерлингов, £',
  },
];
export const TYPE_CONNECTION_AF = {
  advertiser: 'Прямой pекламодатель',
  agency: 'Агентство',
};
