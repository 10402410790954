import { AvatarWorkspaceIcon, AvatarWorkspaceIconSelect, Typography, CheckIcon } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { WorkspaceResp } from 'types/profile/types';
import { navigateTo } from '../../../routing/navigation';
import { useAppDataStore } from '../../app-data-provider';
import classes from './menu-item-workspace.module.scss';

export type MenuItemWorkspaceProps = {
  isSelect: boolean;
  onClose: (flag: boolean) => void;
  workspaceName: string;
  workspaceId: string;
  worksSpaceShortName: string;
};

export const MenuItemWorkspace: React.FC<MenuItemWorkspaceProps> = ({
  isSelect,
  onClose,
  workspaceName,
  workspaceId,
  worksSpaceShortName,
  ...res
}) => {
  const {
    menu: { deselectMenuItem },
    profileStore: { setCurrentWS, currentWorkspace },
    integrationsVK: { refreshIntegrationStore },
  } = useAppDataStore();
  const [isHover, setHover] = useState(false);
  const { hide: hideIntercom } = useIntercom();

  const clickMenuItem = () => {
    onClose(true);
    deselectMenuItem();
    refreshIntegrationStore();
    setCurrentWS(workspaceId).then(() => {
      navigateTo(`/${worksSpaceShortName}`);
      hideIntercom();
    });
  };

  return (
    <div
      className={clsx(classes.workspaceItem, isSelect && classes.itemChecked)}
      onClick={clickMenuItem}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...res}
    >
      <div className={classes.workspaceItemBox}>
        {isSelect ? (
          <>
            <AvatarWorkspaceIconSelect
              className={clsx(classes.imgGap, classes.imgSelect, isHover && classes.imgSelectHover)}
            />
          </>
        ) : (
          <AvatarWorkspaceIcon className={classes.imgGap} />
        )}
        <EllipsisTooltip
          classNameTooltip={clsx(isSelect && classes.elipsisWidthHover, !isSelect && classes.elipsisWidth)}
          tooltipMessage={workspaceName}
        >
          <Typography size="AuthContent" color={isHover ? 'TextWhite' : ''} ellips={true}>
            {workspaceName}
          </Typography>
        </EllipsisTooltip>
      </div>
      {isSelect && <CheckIcon />}
    </div>
  );
};
