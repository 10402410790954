import { Column, CustomCellRendererSelector, GridApi } from '@plarin/inputs';
import { ManageVkTabNameEnum, getSafeParsedLocalStorage, isUrlProd } from '@plarin/utils';
import type { SelectionColumnDef, ICellRendererParams, IRowNode } from 'ag-grid-community';
import React from 'react';
import { TMetric, TMetricGroup } from '../../../types/metrics';
import { TWsDictionary } from '../../../types/profile/types';
import { ManageVkNameCellsEnum, STORAGE_ITEM } from '../../utils/constants';
import { selectionColumnDef } from '../../utils/get-selection-column-def';
import { SwitchStatusEntity } from '../switch-status-entity';
import { customColumns } from './custom-columns';
import { defaultColumns } from './default-columns';

// скрываем чекбокс, вместо него показываем лоадер
export const isLoading = (params: ICellRendererParams<any, any, any> | IRowNode<any>) => {
  return params.data.cellLoadingName || params.data.price === 'loading' || params.data.status === 'loading';
};

// настройки колонки с чекбоксами
export const getVkSelectionColumnDef = (isGroupingActive: boolean): SelectionColumnDef => {
  return {
    ...selectionColumnDef,
    maxWidth: isGroupingActive ? 64 : 40,
    minWidth: isGroupingActive ? 64 : 40,
    cellRendererParams: {
      suppressCount: true,
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      value: '',
      loaderPrecisePositioning: isGroupingActive ? -8 : undefined,
    },
    cellRendererSelector: params => {
      if (new Set(Object.keys(params.data)).has('orgHierarchy') && params.data.isGroupRow) {
        return { component: 'agGroupCellRenderer' };
      }
      if (isLoading(params)) {
        return { component: 'PreloaderRenderer' };
      }
      return { component: null };
    },
    cellClass: params => {
      return `manageNetworkStatusCode checkbox ${params.data.statusColor} ${
        isLoading(params.node) ? 'hideCheckbox' : 'hidePreloader'
      }`;
    },
    cellClassRules: {
      deleted: param => param.data.status === 'Архивное' || param.data.status === 'Архивная',
      active: param => param.data.status === 'Активная' || param.data.status === 'Активное',
      stopStatus: param =>
        param.data.status === 'Остановлена' ||
        param.data.status === 'Остановлено' ||
        param.data.deliveryStatus === 'not_delivering',
    },
  };
};

const createSwitchButton = (tabName: ManageVkTabNameEnum, data: any, gridApi: GridApi) => {
  return <SwitchStatusEntity tabNameVk={tabName} data={data} gridApi={gridApi} network="vk" />;
};

const getDefaultColumns = (tabName: ManageVkTabNameEnum, checkedColumns: string[] = []): Column[] => {
  const columns = [defaultColumns.ACCOUNTS, defaultColumns.ADPLANS, defaultColumns.ADGROUPS, defaultColumns.ADS];

  const checkedColumnAccountName = checkedColumns.includes('account_name');

  switch (tabName) {
    case ManageVkTabNameEnum.ACCOUNTS: {
      return columns
        .filter((_, index) => index === 0)
        .map(el => {
          if (el.field === 'accountName') {
            return {
              ...el,
              cellRendererParams: { ...el.cellRendererParams, tabName },
              headerComponentParams: { subheader: 'ID Рекламного кабинета', isPinned: true },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,

                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }

          return el;
        });
    }
    case ManageVkTabNameEnum.ADPLANS: {
      return columns
        .filter((_, index) => (checkedColumnAccountName && index === 0) || index === 1)
        .map(el => {
          if (el.field === 'adPlanName') {
            return {
              ...el,
              headerComponentParams: { subheader: 'ID Кампании', isPinned: true },
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }
          return el;
        });
    }
    case ManageVkTabNameEnum.ADGROUPS: {
      return columns
        .filter((_, index) => (checkedColumnAccountName && index === 0) || index === 1 || index === 2)
        .map(el => {
          if (el.field === 'adGroupName') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Группы объявлений', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }
          return el;
        });
    }
    case ManageVkTabNameEnum.ADS: {
      return columns
        .filter(
          (_, index) =>
            (checkedColumnAccountName && index === 0) ||
            (index === 1 && checkedColumns.includes('ad_plan_name')) ||
            index === 2 ||
            index === 3,
        )
        .map(el => {
          if (el.headerName === 'Объявление') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
                isAdsPreview: true,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Объявления', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }
          return el;
        });
    }
    default:
      return [];
  }
};

const needColumnsPaths = {
  mt_project: 'PROJECTS',
  status: 'STATUS',
  ad_plan_start: 'PERIOD',
  price: 'PRICE',
  satistics_fast: 'FAST_STAT',
  satistics_fast72: 'FAST_STAT_72',
  ad_plan_lifetime: 'BUDGET_LIFETIME',
  ad_plan_daily: 'BUDGET_DAILY',
};

const columnsDefault = {
  account_balance: 'DEFAULT',
  statistics_spent: 'DEFAULT',
  statistics_impressions: 'DEFAULT',
  statistics_clicks: 'DEFAULT',
  statistics_ctr: 'DEFAULT',
  statistics_cpc: 'DEFAULT',
  statistics_cpm: 'DEFAULT',
  statistics_ecpm: 'DEFAULT',
  ad_url: 'LINK',
};

const getColumns2 = (
  names: TMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageVkNameCellsEnum,
) => {
  const columns: Column[] = [];
  Object.keys(needColumnsPaths).forEach(path => {
    names.forEach(metric => {
      if (path === metric.path) {
        // @ts-ignore
        columns.push(customColumns(metric, wsDictionary, tabName)[needColumnsPaths[path]]);
      }
    });
  });
  return columns;
};

const otherColumns = (
  names: TMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageVkNameCellsEnum,
) => {
  const excludedPaths = ['account_name', 'ad_plan_name', 'ad_group_name', 'ad_name'];
  const columns: Column[] = [];

  // Используем Set для отслеживания уже добавленных колонок
  const addedPaths = new Set<string>();

  // Проходим по names и добавляем соответствующие колонки из columnsDefault в том порядке, в котором они появляются в names
  names
    .filter(el => !Object.keys(needColumnsPaths).includes(el.path))
    .filter(el => !excludedPaths.includes(el.path))

    .forEach(el => {
      if (el.checked || (Object.keys(columnsDefault).includes(el.path) && !addedPaths.has(el.path))) {
        const columnKey = el.checked
          ? el.path === 'ad_url'
            ? columnsDefault.ad_url
            : 'DEFAULT'
          : // @ts-ignore
            columnsDefault[el.path];

        columns.push(customColumns(el, wsDictionary, tabName)[columnKey]);
        addedPaths.add(el.path);
      }
    });

  return columns;
};

export const dynamicColumnsRenderer = (
  names: TMetric[],
  tabName1: ManageVkTabNameEnum,
  tabName2: keyof typeof ManageVkNameCellsEnum,
  wsDictionary: TWsDictionary,
) => {
  const checkedMetricsVK = getSafeParsedLocalStorage<string[]>(STORAGE_ITEM.localStorage.METRICSVK);

  return [
    ...getDefaultColumns(tabName1, checkedMetricsVK ? checkedMetricsVK : []),
    ...getColumns2(names, wsDictionary, tabName2),
    ...otherColumns(names, wsDictionary, tabName2),
  ];
};
