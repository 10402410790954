import { Typography } from '@plarin/design';
import { observer } from 'mobx-react';
import React from 'react';
import classes from './style.module.scss';

type BadgeNameAFProps = {
  agency: boolean;
};

export const BadgeNameAF = observer(({ agency }: BadgeNameAFProps) => {
  return (
    <Typography
      color="TextSecondary"
      size="SmallText"
      component="div"
      align="center"
      componentProps={{ className: classes.textBadge }}
    >
      {agency ? 'Агентский' : 'Рекламодатель'}
    </Typography>
  );
});
