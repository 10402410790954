import { DeleteIcon, MegaphoneIcon, PauseIcon, PlayIcon, RedoIcon } from '@plarin/design';
import React from 'react';
import { MTStatus } from '../../../types/manage-vk/types';
import { ActionKeysEnum, EditListEnum } from './commonEnums';

export type TEditMenuList = {
  key: ActionKeysEnum;
  icon: JSX.Element;
  label: string;
  typeEdit?: EditListEnum;
  status?: MTStatus | 'recovery';
}[];

export const editMenuList: TEditMenuList = [
  { key: ActionKeysEnum.start, icon: <PlayIcon />, label: 'Запустить', status: 'active' },
  { key: ActionKeysEnum.pause, icon: <PauseIcon />, label: 'Остановить', status: 'blocked' },
  {
    key: ActionKeysEnum.archive,
    icon: <DeleteIcon />,
    label: 'Архивировать',
    status: 'deleted',
  },
  {
    key: ActionKeysEnum.reestablish,
    icon: <RedoIcon />,
    label: 'Восстановить',
    status: 'recovery',
  },
  {
    key: ActionKeysEnum.bid,
    icon: <MegaphoneIcon />,
    label: 'Изменить стратегию',
    typeEdit: EditListEnum.bid,
  },
  // {
  //   key: ActionKeysEnum.limit,
  //   label: 'Изменить лимит',
  //   rightIcon: <SmallArrowRightIcon />,
  //   typeEdit: EditListEnum.limit,
  //   disabled: true,
  // },
  // {
  //   key: ActionKeysEnum.name,
  //   label: 'Изменить название',
  //   rightIcon: <SmallArrowRightIcon />,
  //   typeEdit: EditListEnum.name,
  // },
  // {
  //   key: ActionKeysEnum.timetable,
  //   label: 'Изменить расписание',
  //   rightIcon: <SmallArrowRightIcon />,
  //   typeEdit: EditListEnum.timeTable,
  //   disabled: true,
  // },
];
