import { Typography } from '@plarin/design';
import { Checkbox, CustomSelect, DateRangeInput, TDateOption } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { DatePeriod, PeriodGroups, TInitialStateDrawer } from '../../../../../../types/reports/reports';
import { dateAdapter, periodDatePickerAdapter } from '../../../../../utils/reports';
import { PeriodGroupsVariants } from './period-menu-variants';
import classes from './style.module.scss';

interface PeriodBlockProps {
  updateData: (data: Partial<TInitialStateDrawer>) => void;
  initialDate: DatePeriod;
  initialGroup: PeriodGroups;
  initialExcludeStatus?: boolean;
}

const NoData = (initialChecked: boolean) => ({
  title: 'Исключить кампании без данных за выбранный период',
  checked: initialChecked,
});

export const PeriodBlock = ({
  updateData,
  initialDate,
  initialGroup,
  initialExcludeStatus = true,
}: PeriodBlockProps) => {
  const [periodGroups, setPeriodGroups] = useState<PeriodGroups | string>(initialGroup);
  const [date, setDate] = useState<TDateOption | null>(null);
  const [excludeNoStat, setExcludeNoStat] = useState(NoData(initialExcludeStatus));

  useEffect(() => {
    date &&
      updateData({
        ...dateAdapter(date),
        periodGroups: periodGroups as PeriodGroups,
        exclude_no_stat: excludeNoStat.checked,
      });
  }, [updateData, date, periodGroups, excludeNoStat]);

  return (
    <div className={classes.periodBlock}>
      <span className={classes.checksTitle}>
        <Typography size="Main" weight={600}>
          Период отчета
        </Typography>
      </span>
      <div className={classes.selectBlock}>
        <DateRangeInput
          componentClasses={{ datePicker: classes.datePickerPosition, paper: classes.paper }}
          setDateOption={setDate}
          menuHide
          date={periodDatePickerAdapter(initialDate)}
          reportInput
          withQuarters
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        />

        <CustomSelect
          value={periodGroups}
          onChange={setPeriodGroups}
          listVariants={PeriodGroupsVariants}
          label="Группировка"
          menuHeight={144}
        />
      </div>
      <div
        onClick={() =>
          setExcludeNoStat(prev => {
            return { ...prev, checked: !prev.checked };
          })
        }
        className={clsx(classes.box, classes.noData)}
      >
        <Checkbox className={classes.checkbox} value={excludeNoStat.checked} size="small" />
        <Typography size="Main">{excludeNoStat.title}</Typography>
      </div>
    </div>
  );
};
