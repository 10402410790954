import { ExitIcon, MoreIcon, Typography } from '@plarin/design';
import { Button, IconButton } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { SettingsMenuTeams } from '../settingsMenuTeams';
import { getWsRole } from '../utils';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerFooter = observer(() => {
  const {
    teams: {
      dataDrawer: teamData,
      modalData,
      onCloseDrawer,
      changeTeam,
      leaveTeam,
      joinTeam,
      createTeam,
      isLoading,
      isErrorName,
      menuIsLoading,
      setMenuIsLoading,
      isDrawerDataChanged,
    },
    profileStore: { currentWorkspace, data },
  } = useAppDataStore();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isClickSave, setIsClickSave] = useState<boolean>(false);
  const moreIcon = useRef(null);

  const isAdmin = useMemo(() => {
    const wsRole = getWsRole(data.user_id, currentWorkspace);
    return wsRole === 'wsrole:admin' || wsRole === 'wsrole:owner';
  }, []);

  const isMember = useMemo(() => (teamData ? teamData.member_ids.includes(data.user_id) : false), [teamData]);
  const isLeader = useMemo(() => (teamData ? teamData.leader_id === data.user_id : false), []);

  const onOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = (e: React.MouseEvent | Event) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    !isLoading && setMenuIsLoading(false);
    isLoading && !!modalData.data && setIsClickSave(true);
  }, [isLoading, modalData.data]);

  // обычный пользователь, не имеющий права редактировать команду, может выйти из неё, только если команда не является закрытой
  if (teamData?.closed && !(isAdmin || isLeader)) {
    return <></>;
  }

  // если команда незакрытая, пользователь может сам вступать или выходить из неё
  if (teamData && !teamData.closed && !(isAdmin || isLeader)) {
    return (
      <div className={classes.viewFooter}>
        <div className={classes.viewFooterButton}>
          <ExitIcon color="primary60" />
          <Button
            onClick={
              isMember ? () => leaveTeam(teamData, data.user_id, isAdmin) : () => joinTeam(teamData, data.user_id)
            }
          >
            <Typography size="AuthContent" color="Primary60" weight={600}>
              {isMember ? 'Выйти из команды' : 'Присоедениться к команде'}
            </Typography>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <Button
        size="small"
        variant="filled"
        color="primary"
        loading={modalData.data ? false : isLoading && !menuIsLoading}
        disabled={
          !isDrawerDataChanged ||
          isErrorName ||
          !teamData?.name ||
          teamData.name.length === 0 ||
          teamData.name.trim() === '' ||
          (isLoading && (menuIsLoading || !!modalData.data))
        }
        onClick={() => {
          if (isLoading || isClickSave) {
            return;
          }
          if (teamData?.isEditDrawer) {
            setIsClickSave(true);
            changeTeam(data.user_id, isAdmin);
          } else {
            setIsClickSave(true);
            createTeam();
          }
        }}
      >
        Сохранить
      </Button>
      <Button
        disabled={isLoading}
        onClick={onCloseDrawer}
        size="small"
        variant="outlined"
        color="primary"
        className={classes.cancel}
      >
        Отменить
      </Button>

      {teamData && teamData.isEditDrawer && (
        <>
          <div ref={moreIcon} onClick={isLoading ? () => {} : onOpen}>
            <IconButton
              variant="outlined"
              color="primary"
              disabled={isLoading && !menuIsLoading && !modalData.data}
              loading={!modalData.data ? isLoading && menuIsLoading : isLoading}
            >
              <MoreIcon color={isLoading ? 'disabled' : 'primary60'} />
            </IconButton>

            <SettingsMenuTeams data={teamData} onClose={onClose} actionAnchorEl={anchorEl} isEditDrawer />
          </div>
        </>
      )}
    </div>
  );
});
