import { ErrorIcon, Typography } from '@plarin/design';
import { typografText } from '@plarin/utils';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { Popover } from '../popover';
import { IPopover } from '../popover/type';
import classes from './style.module.scss';

interface IHintPopup {
  propsNameError?: string;
  titleIcon?: JSX.Element;
  isShowIcon?: boolean;
  zIndex?: number;
  triggerIcon: JSX.Element;
  textBody?: string | JSX.Element;
  isHeaderBox?: boolean;
  classNameTrigger?: string;
  classBoxIcon?: string;
  marginTop?: number;
  saveZoneEvent?: boolean;
}

export const HintPopup = ({
  propsNameError,
  isShowIcon = true,
  zIndex = -1,
  textBody,
  triggerIcon,
  titleIcon,
  isHeaderBox,
  classNameTrigger,
  classBoxIcon,
  marginTop = 0,
  saveZoneEvent,
  ...res
}: IHintPopup & Partial<IPopover> & React.HTMLAttributes<HTMLDivElement>) => {
  const issuesRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const onClose = (e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      <div className={clsx(classes.containerTrigger, classNameTrigger)}>
        <div
          className={clsx(classes.boxIcon, classBoxIcon)}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          ref={issuesRef}
        >
          {triggerIcon}
        </div>
      </div>
      <Popover
        {...res}
        classNameWrapper={classes.boxHint}
        zIndex={zIndex}
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        marginTop={marginTop}
        isOpen={isOpen || isHover}
        setIsOpen={onClose}
        anchorEl={issuesRef.current}
      >
        <>
          <div className={clsx(classes.saveWrapper, saveZoneEvent && classes.saveZoneEvent)} />
          {isHeaderBox ? (
            <div className={classes.headerBox}>
              <div className={classes.headerTitle}>
                {isShowIcon &&
                  (titleIcon ?? (
                    <div className={clsx(classes.iconBlock, classes.danger)}>
                      <ErrorIcon color="white" />
                    </div>
                  ))}
                <Typography weight={600} size="AuthSubheader" color="TextPrimary">
                  {propsNameError}
                </Typography>
              </div>
            </div>
          ) : (
            <Typography
              componentProps={{ className: classes.headerTitleLite }}
              weight={600}
              size="AuthSubheader"
              color="TextPrimary"
            >
              {propsNameError}
            </Typography>
          )}
          <Typography componentProps={{ className: classes.textBody }} size="AuthContent" weight={400}>
            {typeof textBody === 'string' ? typografText(textBody) : <div className={classes.gapBody}>{textBody}</div>}
          </Typography>
        </>
      </Popover>
    </>
  );
};
