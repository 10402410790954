import { BasicStore, httpClient, httpClient2 } from '@plarin/core';
import { NotificationErrorTitle, NotificationMessage, NotificationTitle } from '@plarin/utils';
import { AxiosResponse } from 'axios';
import { makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  Account,
  Cabinet,
  CabinetsStatus,
  ConnectedAccount,
  MTCabinetExtended,
  TAccountsMTStoreData,
  TConnectedAccountMTStoreData,
  TConnectMTAccountResp,
  TConnectMTURLData,
  TDisconnectMTData,
} from '../../types/integration/types';
import { logout } from '../components/app';
import { NetworkEnum, SyncStatusEnum } from '../dictionary/integrations';
import { DrawerStep } from '../enums';
import { routerPaths } from '../routing/router-path';
import { STATUS } from '../utils/constants';
import { accountsAndCabinetsConverter, refreshCabinets, returnNetwork } from '../utils/integration-converters';
import { mtToAccount, mtToCabinet, mtToConnectedAccount } from '../utils/integration-mappers-mt';

type OpenDrawerProps = { stepInDrawer?: DrawerStep; network?: NetworkEnum };

export class IntegrationsVKStore extends BasicStore {
  accounts?: Account[];
  cabinets?: Cabinet[];
  accountData?: ConnectedAccount;
  isDrawerVisible = false;
  stepInDrawer?: DrawerStep;
  isDrawerClosable = true;
  visibleModal = false;
  accountId?: string;
  network?: NetworkEnum;
  errorAfterSuccess?: string;
  someAction?: () => void;
  isLoading?: boolean;
  isTableLoading?: boolean = false;
  filterValue?: string;
  hasError: boolean = false;
  showActionBar: boolean = false;

  constructor() {
    super();
    makeObservable<this>(this, {
      accounts: observable,
      cabinets: observable,
      accountData: observable,
      isDrawerVisible: observable,
      stepInDrawer: observable,
      network: observable,
      errorAfterSuccess: observable,
      isDrawerClosable: observable,
      someAction: observable,
      isLoading: observable,
      isTableLoading: observable,
      visibleModal: observable,
      filterValue: observable,
      hasError: observable,
      showActionBar: observable,
    });
  }

  refreshIntegrationStore = () => {
    this.accounts = undefined;
    this.cabinets = undefined;
  };

  setFilterValue = (value: string) => runInAction(() => (this.filterValue = value));
  setHasError = (value: boolean) => runInAction(() => (this.hasError = value));
  setShowActionBar = (value: boolean) => runInAction(() => (this.showActionBar = value));

  openDrawer = ({ stepInDrawer, network }: OpenDrawerProps = {}) => {
    this.setIsDrawerVisible(true);
    this.setStepInDrawer(stepInDrawer);
    this.setNetwork(network);
  };

  closeDrawer = () => {
    if (this.isDrawerClosable) {
      this.setIsDrawerVisible(false);
      this.clearStateFromDrawer();
    }
  };

  openModal = () => this.setVisibleModal(true);
  closeModal = () => this.setVisibleModal(false);
  setStepInDrawer = (stepInDrawer?: DrawerStep) => runInAction(() => (this.stepInDrawer = stepInDrawer));
  setNetwork = (network?: NetworkEnum) => runInAction(() => (this.network = network));
  setError = (error?: string) => runInAction(() => (this.error = error));
  setErrorAfterSuccess = (error?: string) => runInAction(() => (this.errorAfterSuccess = error));
  setSomeAction = (func?: () => void) => runInAction(() => (this.someAction = func));
  setLoading = (status: boolean) => runInAction(() => (this.isLoading = status));
  setAccounts = (accounts: Account[]) => runInAction(() => (this.accounts = accounts));
  setCabinets = (cabinets: Cabinet[]) => runInAction(() => (this.cabinets = cabinets));
  setTableLoading = (status?: boolean) => runInAction(() => (this.isTableLoading = status));
  setVisibleModal = (status: boolean) => runInAction(() => (this.visibleModal = status));

  fetchClients = async () => {
    this.setHasError(false);

    const items = localStorage.getItem('accountsData');
    if (items) {
      return this.loadSuccess(JSON.parse(items));
    }

    // toast.dismiss();
    // this.setNotifications([]);

    this.setTableLoading(true);

    await httpClient
      .get('/api/v1/user/auth/is_authorized') // этот запрос нужен вот зачем: https://plarin.slack.com/archives/C67TAFBCM/p1653921075366419
      .then(() => {
        this.execRequestAllSettled([httpClient2.get<TAccountsMTStoreData>('/api/v1/connect/mt/integration/list')])
          .then(data => {
            // @ts-ignore // fixme:  fixTypes
            this.loadSuccess(data);
          })
          .catch(err => {
            this.setHasError(true);
            this.setShowActionBar(false);
          })
          .finally(() => {
            this.setTableLoading(false);
          });
      })
      .catch(err => {
        this.setTableLoading(false);
        this.setHasError(true);
        this.setShowActionBar(false);
      });
  };

  private loadSuccess = (result: [PromiseSettledResult<TAccountsMTStoreData>]) => {
    const data = result[0].status === 'fulfilled' ? result[0].value || [] : [];

    const errorIndexes: number[] = [];
    result.forEach((item, index) => item.status === 'rejected' && errorIndexes.push(index));

    errorIndexes.forEach(item =>
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.LOAD_ERROR,
        message: `Не удалось загрузить аккаунты ${returnNetwork(item)}. Обновите таблицу или попробуйте позднее.`,
      }),
    );

    if (errorIndexes.length) {
      this.setHasError(true);
      this.setShowActionBar(false);
    } else {
      this.setShowActionBar(true);
    }

    runInAction(() => ([this.accounts, this.cabinets] = accountsAndCabinetsConverter([data])));
  };

  connectAccount = async (network: NetworkEnum, workspaceShortname: string) => {
    runInAction(() => {
      this.isLoading = true;
    });
    await this.execRequest<TConnectMTAccountResp>(
      httpClient.get<TConnectMTURLData>('/api/v1/connect/mt/url', {
        params: {
          network: network,
          url: `${window.location.origin}/${workspaceShortname}${routerPaths.integrations.ACCOUNT}`,
        },
      }),
    )
      .then(this.getUrlSuccess)
      .catch(this.setError)
      .finally(() =>
        runInAction(() => {
          this.isLoading = false;
        }),
      );
  };

  getAccountDataByIdMT = async () => {
    this.setLoading(true);
    await this.execRequest<TConnectedAccountMTStoreData>(
      httpClient2.get<TConnectedAccountMTStoreData>(`/api/v1/connect/mt/integration/info/${this.accountId}`),
    )
      .then(this.getAccountDataSuccessMT)
      .catch(this.setErrorAfterSuccess)
      .finally(() => {
        this.setStepInDrawer(DrawerStep.ConnectResult);
        this.setIsDrawerVisible(true);
        this.setLoading(false);
      });
  };

  checkQueryString = (user_id?: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const network = urlSearchParams.get('network') as NetworkEnum;

    if (window.location.search && user_id && network) {
      const userIdFromURL = urlSearchParams.get('user_id') || urlSearchParams.get('userId');
      if (userIdFromURL !== user_id) {
        window.history.replaceState(null, '', window.location.pathname);
        logout().then();
        return this.clearStateFromDrawer();
      }
    }

    const fetchAccountId =
      urlSearchParams.get('account_id') || urlSearchParams.get('profile_id') || urlSearchParams.get('client_id');

    const error = urlSearchParams.get('error');
    if (network && fetchAccountId) {
      runInAction(() => {
        this.network = network;
        this.accountId = fetchAccountId;
        window.history.replaceState(null, '', window.location.pathname);
      });
    }
    if (error && network && error !== 'CLIENT_ALREADY_EXISTS') {
      this.openDrawer({ stepInDrawer: DrawerStep.ConnectStart, network: network });
      this.setError(error);
      window.history.replaceState(null, '', window.location.pathname);
    }

    if (error === 'CLIENT_ALREADY_EXISTS' && network && fetchAccountId) {
      runInAction(() => {
        this.network = network;
        this.accountId = fetchAccountId;
      });
    }
  };

  cabinetsStatusAction = async (cabinets: Cabinet[]) => {
    this.setErrorAfterSuccess('');

    const enabledCabinets = cabinets
      .filter(({ syncStatus }) => syncStatus === SyncStatusEnum.enabled)
      .map(({ key }) => key);
    const disabledCabinets = cabinets
      .filter(({ syncStatus }) => syncStatus === SyncStatusEnum.disabled)
      .map(({ key }) => key);

    const urlStringForEnable = () => {
      if (this.network === 'mt' || this.network === 'va') {
        return '/api/v1/connect/mt/integration/enable';
      }
    };

    const urlStringForDisable = () => {
      if (this.network === 'mt' || this.network === 'va') {
        return '/api/v1/connect/mt/integration/disable';
      }
    };

    await this.execRequestAllSettled([
      enabledCabinets.length
        ? httpClient2.post<CabinetsStatus>(
            urlStringForEnable() || '',
            (this.network === 'mt' || this.network === 'va') && { db_ids: enabledCabinets },
          )
        : Promise.resolve<any>(null),
      disabledCabinets.length
        ? httpClient2.post<CabinetsStatus | null>(
            urlStringForDisable() || '',
            (this.network === 'mt' || this.network === 'va') && { db_ids: disabledCabinets },
          )
        : Promise.resolve<any>(null),
      // @ts-ignore // fixme:  fixTypes
    ]).then(this.cabinetsStatusActionSuccess);
  };

  enableCabinets = (rows: Cabinet[]) => {
    const cabinets = rows.map(item => item.key);

    const req = cabinets.length
      ? httpClient2.post('/api/v1/connect/mt/integration/enable', { db_ids: cabinets })
      : (Promise.resolve({ status: 200, data: [], headers: {}, config: {}, statusText: '' }) as Promise<
          AxiosResponse<unknown>
        >);

    this.execRequestAllSettled([req])
      // @ts-ignore // fixme:  fixTypes
      .then(resp => this.enableOrDisableCabinetsResult(resp, 'enabled'));
  };

  disableCabinets = (rows: Cabinet[]) => {
    const cabinets = rows.map(item => item.key);

    const req = cabinets.length
      ? httpClient2.post<MTCabinetExtended[]>('/api/v1/connect/mt/integration/disable', {
          db_ids: cabinets,
        })
      : (Promise.resolve({ status: 200, data: [], headers: {}, config: {}, statusText: '' }) as Promise<
          AxiosResponse<MTCabinetExtended[]>
        >);

    this.execRequestAllSettled([req])
      // @ts-ignore // fixme:  fixTypes
      .then(resp => this.enableOrDisableCabinetsResult(resp, 'disabled'));
  };

  disconnectAccounts = (rows: Account[]) => {
    this.setVisibleModal(false);
    const accounts = rows.map(item => item.key);

    const req = accounts.length
      ? httpClient2.post<TDisconnectMTData>('/api/v1/connect/mt/integration/disconnect', {
          db_ids: accounts,
        })
      : (Promise.resolve({ status: 200, data: [], headers: {}, config: {}, statusText: '' }) as Promise<
          AxiosResponse<TDisconnectMTData>
        >);

    this.execRequestAllSettled([req])
      // @ts-ignore // fixme:  fixTypes
      .then(resp => this.deleteAccountsResult(resp, rows));
  };

  enableAccounts = (rows: Account[]) => {
    const accounts = rows.map(item => item.key);

    const req = accounts.length
      ? httpClient2.post<TAccountsMTStoreData>('/api/v1/connect/mt/integration/enable', {
          db_ids: accounts,
        })
      : (Promise.resolve({ status: 200, data: [], headers: {}, config: {}, statusText: '' }) as Promise<
          AxiosResponse<TAccountsMTStoreData>
        >);

    this.execRequestAllSettled([req])
      // @ts-ignore // fixme:  fixTypes
      .then(resp => this.enableOrDisableAccountsResult(resp, 'enabled'))
      .finally(() => this.fetchClients());
  };

  disableAccounts = (rows: Account[]) => {
    const accounts = rows.map(item => item.key);

    const req = accounts.length
      ? httpClient2.post<TAccountsMTStoreData>('/api/v1/connect/mt/integration/disable', {
          db_ids: accounts,
        })
      : (Promise.resolve({ status: 200, data: [], headers: {}, config: {}, statusText: '' }) as Promise<
          AxiosResponse<TAccountsMTStoreData>
        >);

    this.execRequestAllSettled([req])
      // @ts-ignore // fixme:  fixTypes
      .then(resp => this.enableOrDisableAccountsResult(resp, 'disabled'))
      .finally(() => this.fetchClients());
  };

  private enableOrDisableAccountsResult = ([mt]: [PromiseSettledResult<TAccountsMTStoreData>], actionName: string) => {
    const newAccounts = [...(this.accounts || [])];

    const data = mt.status === 'fulfilled' ? mt.value.map(item => mtToAccount(item)) : [];

    const responseRows = [...(data || [])];

    responseRows.forEach(item => {
      newAccounts.forEach((el, index) => {
        if (item.key === el.key) {
          newAccounts[index] = { ...item, cabinetCount: el.cabinetCount };
        }
      });
    });

    if (responseRows.length === 1) {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: actionName === 'enabled' ? 'Аккаунт подключен' : 'Аккаунт отключен',
        message: `Аккаунт ${responseRows[0].name} (${responseRows[0].clientId}) успешно ${
          actionName === 'enabled' ? 'подключен к платформе' : 'отключен от платформы'
        } Plarin`,
      });
    } else if (responseRows.length > 1) {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: actionName === 'enabled' ? 'Аккаунты подключены' : 'Аккаунты отключены',
        message: `Аккаунты успешно ${
          actionName === 'enabled' ? 'подключены к платформе' : 'отключены от платформы'
        } Plarin`,
      });
    }

    this.setAccounts(newAccounts);
  };

  private deleteAccountsResult = ([mt]: [PromiseSettledResult<TDisconnectMTData>], rows: Account[]) => {
    const newAccounts = [...(this.accounts || [])];

    const responseRows: Account[] = [...rows];

    if (responseRows.length === 1) {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: NotificationTitle.ACCOUNT_REMOVED,
        message: `Аккаунт ${responseRows[0].name} (${responseRows[0].clientId}) успешно удален`,
      });
    } else if (responseRows.length > 1) {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: NotificationTitle.ACCOUNT_REMOVED,
        message: NotificationMessage.ACCOUNTS_REMOVED,
      });
    }

    responseRows.forEach(el => {
      newAccounts.splice(
        newAccounts.findIndex(i => i.key === el.key),
        1,
      );
    });

    this.setAccounts(newAccounts);
  };

  private getAccountDataSuccessMT = (resp: TConnectedAccountMTStoreData) => {
    runInAction(() => {
      this.accountData = mtToConnectedAccount(resp);
      if (resp.update_after_creation) {
        this.isDrawerClosable = false;
        setTimeout(() => {
          this.getAccountDataByIdMT().then();
        }, 5000);
      } else {
        this.isDrawerClosable = true;
      }
    });
  };

  private getUrlSuccess = (resp: TConnectMTAccountResp) => {
    runInAction(() => {
      if (resp.data?.url) {
        window.location.href = resp.data.url;
      }
    });
  };

  private clearStateFromDrawer = () => {
    runInAction(() => {
      this.network = undefined;
      this.accountId = undefined;
      this.error = undefined;
      this.errorAfterSuccess = undefined;
      this.setSomeAction();
    });
  };

  private setIsDrawerVisible = (visible?: boolean) => runInAction(() => (this.isDrawerVisible = visible || false));

  private cabinetsStatusActionSuccess = ([enabled, disabled]: [
    PromiseSettledResult<CabinetsStatus>,
    PromiseSettledResult<CabinetsStatus>,
  ]) => {
    if (enabled.status === 'fulfilled' && disabled.status === 'fulfilled') {
      this.closeDrawer();
    } else {
      this.setErrorAfterSuccess('Не удалось включить/выключить рекламные кабинеты');
    }
  };

  private enableOrDisableCabinetsResult = ([mt]: [PromiseSettledResult<MTCabinetExtended[]>], actionName: string) => {
    const data = mt.status === 'fulfilled' ? mt.value.map(item => mtToCabinet(item)) || [] : [];

    const responseRows = [...data];
    responseRows.forEach(item => {
      refreshCabinets(item, this.cabinets || []);
    });

    // запрос вернул ошибку (статус не fulfilled )
    if (responseRows.length === 0) {
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.ERROR,
        // @ts-ignore
        message: mt.reason || 'Произошла ошибка',
      });
    } else if (responseRows.length === 1) {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: actionName === 'enabled' ? 'Кабинет подключен' : 'Кабинет отключен',
        message: `Кабинет ${responseRows[0].name} (${responseRows[0].clientId}) успешно ${
          actionName === 'enabled' ? 'подключен к платформе' : 'отключен от платформы'
        } Plarin`,
      });
    } else if (responseRows.length > 1) {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: actionName === 'enabled' ? 'Кабинеты подключены' : 'Кабинеты отключены',
        message: `Кабинеты успешно ${
          actionName === 'enabled' ? 'подключены к платформе' : 'отключены от платформы'
        } Plarin`,
      });
    }

    this.setCabinets([...(this.cabinets || [])]);
  };
  resetStore = () =>
    runInAction(() => {
      this.accounts = undefined;
      this.cabinets = undefined;
      this.accountData = undefined;
      this.isDrawerVisible = false;
      this.stepInDrawer = undefined;
      this.isDrawerClosable = true;
      this.visibleModal = false;
      this.accountId = undefined;
      this.network = undefined;
      this.errorAfterSuccess = undefined;
      this.someAction = undefined;
      this.isLoading = undefined;
      this.isTableLoading = false;
      this.filterValue = undefined;
      this.hasError = false;
      this.showActionBar = false;
    });
}
