import { ArrowUpIcon, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { TWorkspaceDrawerData } from '../../../../../types/workspace/types';
import { navigateTo } from '../../../../routing/navigation';
import { TEAMS } from '../../../../routing/router-path';
import { menuSectionIndexes } from '../../../../utils/constants';
import { useAppDataStore } from '../../../app-data-provider';
import { canChange } from '../utils';
import { AddTeamsList } from './add-team-list';
import { SelectedTeamItem } from './selected-teams';
import classes from './style.module.scss';

export const BlockTeams = observer(() => {
  const {
    members: { drawerData, editDrawerData },
    teams: { teamsData },
    menu: { selectMenuItem },
    profileStore: { WSRole },
  } = useAppDataStore();

  const {
    userData: { teams },
  } = drawerData;

  const isChange = canChange(WSRole);

  const deleteTeam = (id: string) =>
    editDrawerData({
      ...drawerData,
      userData: { ...drawerData.userData, teams: drawerData.userData.teams.filter(el => el._id !== id) },
    });

  const addTeam = (team: TWorkspaceDrawerData['userData']['teams'][0]) => {
    editDrawerData({
      ...drawerData,
      userData: { ...drawerData.userData, teams: [...drawerData.userData.teams, team] },
    });
  };

  const redirectToTeams = () => {
    navigateTo(TEAMS);
    selectMenuItem(TEAMS, false, menuSectionIndexes.ws);
  };

  return (
    <div className={classes.teams}>
      <span className={clsx(classes.titleTeam, !teams.length && classes.noTeams)}>
        <div className={clsx(classes.titleText)}>
          <Typography
            size="AuthContent"
            weight={600}
            color="TextPrimary"
            componentProps={{ className: classes.noTeamsPadding }}
          >
            Команды
          </Typography>
          <AddTeamsList addTeam={addTeam} memberTeams={teams} />
        </div>

        {teamsData.length ? (
          <>
            {!teams.length && (
              <Typography size="AuthContent" color="TextSecondary">
                Сотрудник еще не добавлен ни в одну команду
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography size="AuthContent" color="TextSecondary">
              У вас еще не создана ни одна команда в пространстве
            </Typography>
            {isChange && (
              <Button className={classes.buttonAddForm} onClick={redirectToTeams}>
                <div className={classes.addItem}>
                  <Typography size="AuthContent" weight={600} color="TextButton">
                    Перейти к настройкам команд
                  </Typography>
                  <ArrowUpIcon className={classes.arrowIcon} />
                </div>
              </Button>
            )}
          </>
        )}
      </span>
      <div className={classes.teamsList}>
        {teams.map(team => (
          <SelectedTeamItem team={team} deleteAction={deleteTeam} key={team._id} />
        ))}
      </div>
    </div>
  );
});
