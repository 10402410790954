import { Column, Table } from '@plarin/inputs';
import { currency, int, percent3 } from '@plarin/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { WorkspaceResp } from '../../../../../types/profile/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { ScheduleLayout } from '../schedule-layout';

const columns: Column[] = [
  {
    field: 'name',
    headerName: 'Сотрудник',
    headerComponentParams: {
      alignY: 'center',
    },
    flex: 2,
    cellRenderer: 'SimpleCircleAvatarNameRenderer',
    minWidth: 255,
    resizable: false,
  },
  {
    field: 'statistics_spent',
    headerName: 'Расход',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => currency(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
  {
    field: 'statistics_impressions',
    headerName: 'Показы',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => int(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
  {
    field: 'statistics_clicks',
    headerName: 'Клики',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => int(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
  {
    field: 'statistics_ctr',
    headerName: 'CTR',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => percent3(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
  {
    field: 'statistics_cpc',
    headerName: 'CPC',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => currency(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
  {
    field: 'statistics_cpm',
    headerName: 'CPM',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => currency(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
  {
    field: 'statistics_installs',
    headerName: 'Кол-во установок',
    headerComponentParams: {
      alignY: 'center',
    },
    valueFormatter: params => int(params.value),
    flex: 1,
    type: 'rightAligned',
    cellClass: 'right',
    resizable: false,
  },
];

export const MembersStatTable = ({ project_id }: { project_id: string }) => {
  const {
    projectOverview: { getMemberStat },
    profileStore: {
      currentWorkspace: { members },
    },
  } = useAppDataStore();

  const [data, setData] = useState<Record<string, string | WorkspaceResp['members'][0]>[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    project_id &&
      getMemberStat({
        project_id: project_id,
        network: 'mt',
      })
        .then(res => {
          const list = members.reduce((acc, value) => {
            acc[value._id] = value;
            return acc;
          }, {} as Record<string, WorkspaceResp['members'][0]>);

          setData(res.map(el => ({ ...el, name: list[el.user_id], key: el.user_id })));
        })
        .finally(() => setLoading(false));
  }, [project_id]);

  return (
    <ScheduleLayout title="Результативность сотрудников">
      <Table
        rowData={data}
        columns={columns}
        isTableLoading={loading}
        showSkeletonForLonger={true}
        needAutoHeight
        disableContainersHeight
      />
    </ScheduleLayout>
  );
};
