import { menuSectionsPaths, menuItemsPath, manageVkTabs, manageYDTabs } from '@plarin/inputs';

// разделы меню
export const { MANAGE, REPORTS, WORKSPACE, INTEGRATIONS, ALL_PROJECTS_OVERVIEW } = { ...menuSectionsPaths };

// табы вк реклкамы
export const { ACCOUNT: ACCOUNTS_VK, ADPLAN: ADPLANS_VK, AD_GROUP: AD_GROUPS_VK, AD: ADS_VK } = { ...manageVkTabs };

export const { ACCOUNTS: ACCOUNTS_YD, CAMPAIGNS: CAMPAIGNS_YD, GROUPS: GROUPS_YD, ADS: ADS_YD } = { ...manageYDTabs };

// пункты меню
export const {
  MANAGE_VK,
  MANAGE_YD,
  ALL_REPORTS,
  MEMBERS,
  TEAMS,
  PROJECTS,
  SETTINGS,
  INTEGRATIONS_YANDEX_METRICS,
  INTEGRATIONS_YANDEX_DIRECT,
  INTEGRATIONS_GOOGLE_ANALYTICS,
  INTEGRATIONS_VK,
  INTEGRATIONS_APPSFLYER,
} = { ...menuItemsPath };

export const routerPaths = {
  auth: {
    ROOT: '/',
    WSLIST: '/wslist',
    MANAGE_VK: `/${MANAGE}/${MANAGE_VK}/:id`,
    MANAGE_YD: `/${MANAGE}/${MANAGE_YD}/:id`,
    REPORTS: `/${REPORTS}/${ALL_REPORTS}`,
    MEMBERS: `/${WORKSPACE}/${MEMBERS}`,
    TEAMS: `/${WORKSPACE}/${TEAMS}`,
    PROJECTS: `/${WORKSPACE}/${PROJECTS}`,
    SETTINGS: `/${WORKSPACE}/${SETTINGS}`,
    YANDEX_METRICS: `/${INTEGRATIONS}/${INTEGRATIONS_YANDEX_METRICS}`,
    YANDEX_DIRECT: `/${INTEGRATIONS}/${INTEGRATIONS_YANDEX_DIRECT}`,
    GOOGLE_ANALYTICS: `/${INTEGRATIONS}/${INTEGRATIONS_GOOGLE_ANALYTICS}`,
    VKONTAKTE: `/${INTEGRATIONS}/${INTEGRATIONS_VK}/:id`,
    APPSFLYER: `/${INTEGRATIONS}/${INTEGRATIONS_APPSFLYER}`,
    PROFILE: '/profile',
    WORKSPACE: '/:workspaces',
    WORKSPACE_REDIRECT: '/workspace-redirect/:menuItem',
    PROJECT_OVERVIEW: '/projects/:id',
  },
  unAuth: {
    REGISTRATION: '/registration',
    REGISTRATION_CHECK_MAIL: '/check-mail',
    REGISTRATION_SOCIAL: '/registration-social',
    LOGIN: '/login',
    CONFIRM_EMAIL: '/confirm-email',
    CREATE_PASSWORD: '/create-password',
    NEW_PASSWORD: '/new-password',
    FORGOT_PASSWORD: '/reset-password',
    PROFILE: '/profile',
  },
  legal: {
    USAGE: '/legal/usage',
    POLICY: '/legal/policy',
  },
  workspaces: {
    BASE: '/:workspaces/*',
    MEMBERS: `/${WORKSPACE}/${MEMBERS}`,
    TEAMS: `/${WORKSPACE}/${TEAMS}`,
    PROJECTS: `/${WORKSPACE}/${PROJECTS}`,
    SETTINGS: `/${WORKSPACE}/${SETTINGS}`,
    REDIRECT: '/workspace-redirect',
  },
  manageVK: {
    ACCOUNTS: `/${MANAGE}/${MANAGE_VK}/${ACCOUNTS_VK}`,
    ADPLANS: `/${MANAGE}/${MANAGE_VK}/${ADPLANS_VK}`,
    AD_GROUPS: `/${MANAGE}/${MANAGE_VK}/${AD_GROUPS_VK}`,
    ADS: `/${MANAGE}/${MANAGE_VK}/${ADS_VK}`,
  },
  manageYD: {
    ACCOUNTS: `/${MANAGE}/${MANAGE_YD}/${ACCOUNTS_YD}`,
    CAMPAIGNS: `/${MANAGE}/${MANAGE_YD}/${CAMPAIGNS_YD}`,
    GROUPS: `/${MANAGE}/${MANAGE_YD}/${GROUPS_YD}`,
    ADS: `/${MANAGE}/${MANAGE_YD}/${ADS_YD}`,
  },
  integrations: {
    ACCOUNT: `/${INTEGRATIONS}/${INTEGRATIONS_VK}/accounts`,
    ADVERTS: `/${INTEGRATIONS}/${INTEGRATIONS_VK}/adverts`,
    YANDEX_METRICS: `/${INTEGRATIONS}/${INTEGRATIONS_YANDEX_METRICS}`,
    GOOGLE_ANALYTICS: `/${INTEGRATIONS}/${INTEGRATIONS_GOOGLE_ANALYTICS}`,
    YANDEX_DIRECT: `/${INTEGRATIONS}/${INTEGRATIONS_YANDEX_DIRECT}`,
    APPSFLYER: `/${INTEGRATIONS}/${INTEGRATIONS_APPSFLYER}`,
  },
  ALL_PROJECTS_OVERVIEW: {
    PROJECT_OVERVIEW: `/${ALL_PROJECTS_OVERVIEW}/`, // в конце нужно подставлять id проекта
  },
  reports: { BASE: `/${REPORTS}/${ALL_REPORTS}` },
  // пути до компонентов для React.lazy
  page: {
    ROOT: '/root',
    REGISTRATION: '/registration/registration-page',
    CHECK_MAIL: '/check-mail/check-mail-page',
    LOGIN: '/login/login-page',
    CREATE_PASSWORD: '/create-password/create-password-page',
    FORGOT_PASSWORD: '/forgot-password/forgot-password-page',
    REGISTRATION_SOCIAL: '/registration-social/registration-social-page',
    MANAGE_VK: '/manage-vk',
    MANAGE_YD: '/manage-yd',
    MANAGE_AF: '/manage-af',
    MEMBERS: '/workspace-members',
    TEAMS: '/workspace-teams',
    PROJECTS: '/workspace-projects',
    SETTINGS: '/workspace-settings',
    INTEGRATIONS: '/integrations-vk',
    YANDEX_METRICS: '/integrations-ym',
    YANDEX_DIRECT: '/integrations-yd',
    GOOGLE_ANALYTICS: '/integrations-ga',
    APPSFLYER: '/integrations-af',
    PROFILE: '/profile',
    REPORTS: '/reports',
    PROJECT_OVERVIEW: '/project-overview',
  },
  menu: {
    ALARMS: '/alarms',
    EVENT_LOG: '/event-log',
  },
} as const;
