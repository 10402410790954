import { useEffect } from 'react';
import { TDataGoalsGA } from '../../../../types/connect-ga/types';
import { TDataGoalsYM } from '../../../../types/connect-ym/types';
import { IUseComparisonInitialData } from './type';

export const itemsSourceComparison = [
  { lable: 'utm_campaign', value: 'utm_campaign' },
  { lable: 'utm_content', value: 'utm_content' },
  { lable: 'utm_term', value: 'utm_term' },
];

export const dataFirstBlock = ({
  dataGoals,
  title,
}: {
  dataGoals: Partial<TDataGoalsYM & TDataGoalsGA> | null;
  title: [string, string];
}) => [
  {
    title: title[0],
    name: dataGoals?.column1_header1 ?? '',
    id: dataGoals?.column1_header2 ?? '',
  },
  {
    title: title[1],
    name: dataGoals?.column2_header1 ?? '',
    id: dataGoals?.column2_header2 ?? '',
  },
];

export const dataSourceComparison = (dataGoals: TDataGoalsYM | TDataGoalsGA) => {
  return {
    lable: dataGoals.source ?? 'utm_content',
    value: dataGoals.source ?? 'utm_content',
  };
};

export const dataGoalsList = (dataGoals: TDataGoalsYM | TDataGoalsGA) => {
  return dataGoals.goals ? [...dataGoals.goals]?.sort((a, b) => a.id - b.id) : [];
};

export const useComparisonInitialData = ({
  initialData,
  setIsDisabled,
  valueSourceComparison,
  valueEcommerce,
  goalsList,
}: IUseComparisonInitialData) => {
  useEffect(() => {
    initialData &&
      setIsDisabled(
        JSON.stringify({
          valueSourceComparison,
          valueEcommerce,
          goalsList,
        }) !== JSON.stringify(initialData),
      );
  }, [valueSourceComparison, valueEcommerce, goalsList]);
};
