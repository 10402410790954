import { TMemberCellMenu } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SettingsMenu } from '../../../components/settings-menu';

export const MemberCellMenu = observer(({ data }: TMemberCellMenu) => {
  const {
    members: { openModalMember, deleteInviteMember, setWsDataDrawer, setUserId },
    profileStore: { WSRole },
  } = useAppDataStore();

  const cellMenuMembers = () => {
    const result = [];
    result.push({
      action: () => {
        setUserId(data._id);
        setAnchorEl(null);
      },
      name: 'Редактировать',
    });
    if (data.role !== 'wsrole:owner') {
      result.push(
        {
          action:
            data.status === 'active'
              ? () => {
                  openModalMember('block', data._id);
                  setAnchorEl(null);
                }
              : () => {
                  openModalMember('unBlock', data._id);
                  setAnchorEl(null);
                },
          name: data.status === 'active' ? 'Заблокировать' : 'Разблокировать',
        },
        {
          action: () => {
            openModalMember('remove', data._id);

            setAnchorEl(null);
          },
          name: 'Удалить',
        },
      );
    }
    return result;
  };

  const cellMenuInvites = [
    {
      action: () => deleteInviteMember(data.email),
      name: 'Удалить приглашение',
      isVisible: data.role !== 'wsrole:owner',
    },
  ];

  const cellMenu = data._id ? cellMenuMembers() : cellMenuInvites;

  // //////////////////////////

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const onOpen = useCallback((e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      {WSRole !== 'wsrole:employee' && (
        <SettingsMenu onOpen={onOpen} anchorEl={anchorEl} settingMenu={cellMenu} onClose={onClose} />
      )}
    </>
  );
});
