import { PlarinLogo, Typography } from '@plarin/design';
import { ActionMenuButton, CustomList, MenuSection, MenuItem, TMenuSection, menuItemsPath } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { navigateTo } from '../../../routing/navigation';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

export type MenuProps = {
  withHeader?: boolean;
  menuVisible?: boolean;
  toggleVisibleMenu?(): void;
  menuData: TMenuSection[];
};

export const MainMenu = observer(({ withHeader, menuVisible, toggleVisibleMenu, menuData }: MenuProps) => {
  const {
    menu: { openSection, selectMenuItem, deselectMenuItem },
    profileStore: { workspaceShortname, WSRole },
  } = useAppDataStore();

  const filteredByRoleMenuData = useMemo(
    () =>
      menuData
        .map(section => ({
          ...section,
          menuItems: section.menuItems.filter(item =>
            item.menuItemKey === menuItemsPath.SETTINGS ? WSRole === 'wsrole:admin' || WSRole === 'wsrole:owner' : true,
          ),
        }))
        .filter(section => section.menuItems.length > 0),
    [menuData, WSRole],
  );

  return (
    <div
      className={clsx(
        classes.menu,
        !withHeader && classes.menuWithoutHeader,
        !menuVisible && classes.menuNoVisible,
        !withHeader && classes.changeBorder,
      )}
    >
      {withHeader && (
        <div className={classes.menuHeader}>
          <div
            onClick={() => {
              deselectMenuItem();
              navigateTo('/' + workspaceShortname);
            }}
            className={classes.menuLogo}
          >
            <PlarinLogo variant="menu" />
          </div>
          <ActionMenuButton isOpen onClick={toggleVisibleMenu} />
        </div>
      )}
      <div className={classes.menuContent}>
        <CustomList classContainer={classes.classContainer}>
          <>
            {filteredByRoleMenuData.map(
              ({ title, sectionIndex, isOpen, menuItems, path }) =>
                WSRole !== 'wsrole:guest' && (
                  <MenuSection
                    key={sectionIndex}
                    title={title}
                    sectionIndex={sectionIndex}
                    isOpen={isOpen}
                    path={path}
                    menuItems={menuItems}
                    onClick={() => openSection(sectionIndex)}
                  >
                    {menuItems.map(({ title, icon, index, selected, link, menuItemKey }) => (
                      <MenuItem
                        key={index}
                        title={title}
                        icon={icon}
                        index={index}
                        selected={selected}
                        link={link}
                        menuItemKey={menuItemKey}
                        onClick={() => {
                          selectMenuItem(menuItemKey, true, sectionIndex);
                          !selected && navigateTo(`/${workspaceShortname}${link}`);
                        }}
                      />
                    ))}
                  </MenuSection>
                ),
            )}
          </>
        </CustomList>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerText}>
          <Typography color="TextSecondary" size="SmallText">
            Платформа Пларин
          </Typography>
          <Typography color="TextSecondary" size="SmallText">
            © 2021—2025 ООО «Пларин Технолоджис»
          </Typography>
        </div>
      </div>
    </div>
  );
});
