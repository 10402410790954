import { CurrencyEnum, currency as formatCurrency } from '@plarin/utils';
import { TGroupingData } from '../../types/manage-vk/types';
import { TMetric, TMetricGroup, TYDMetric, TYDMetricGroup } from '../../types/metrics';
import { currencyOrder } from '../dictionary/integrations';
import { sortRow } from './common';
import { parseStringToCamelCase } from './parseStatData/parseStatistics';

interface IGroupingParams {
  primaryMetric?: TMetric | TYDMetric;
  secondaryMetric?: TMetric | TYDMetric;
  tertiaryMetric?: TMetric | TYDMetric;
  data: TGroupingData[];
  metrics: TMetricGroup[] | TYDMetricGroup[];
  mainTabData: string[];
}

// Determine the metric properties based on the key and type
const metricProperties = (e: { [p: string]: TMetric | TYDMetric }[keyof { [p: string]: TMetric | TYDMetric }]) => {
  return {
    mathAllowed: e ? e.math_allowed : undefined,
    mathDefault: e ? e.math_default : undefined,
    isCurrencyMetric: e ? e.type === 'currency' : false,
  };
};

export const grouping = ({
  primaryMetric,
  secondaryMetric,
  tertiaryMetric,
  data,
  metrics,
  mainTabData,
}: IGroupingParams) => {
  const [tabMainName, tabMainId] = mainTabData;

  // Если primaryMetric не задана, возвращаем исходные данные без группировки
  if (!primaryMetric) {
    return data;
  }
  // для уникальности ключа
  let counterKey = 0;

  const groupingVariants: { [key: string]: TGroupingData[] } = {};
  // Получение метрик в необходимом формате для metricProperties
  const metricsData: { [key: string]: TMetric | TYDMetric } = {};
  metrics.forEach(metric => {
    metric.fields.forEach(field => {
      metricsData[parseStringToCamelCase(field.path)] = field;
    });
  });
  // Группировка по первичной метрике
  data?.forEach(el => {
    if (!el.isGroupRow) {
      const groupingVariant =
        primaryMetric?.path === 'mt_project'
          ? el.mtProject?.map((el: { name: string }) => el.name).join(', ')
          : el[parseStringToCamelCase(primaryMetric?.path)];
      if (groupingVariants[groupingVariant]) {
        groupingVariants[groupingVariant].push(el);
      } else {
        groupingVariants[groupingVariant] = [el];
      }
    }
  });

  const groupedData = Object.keys(groupingVariants)
    .sort((a, b) => sortRow(a, b))
    .flatMap(primaryGroupingVariant => {
      const dataForPrimaryGrouping = groupingVariants[primaryGroupingVariant];

      const primaryGroup = JSON.parse(JSON.stringify(dataForPrimaryGrouping[0]));

      Object.keys(primaryGroup).forEach(key => {
        const { mathAllowed, mathDefault, isCurrencyMetric } = metricProperties(metricsData[key]);

        // Handle only if mathAllowed is set
        if (mathAllowed) {
          if (isCurrencyMetric) {
            // Handle currency metrics
            if (
              key !== 'price' &&
              key !== 'adPlanLifetime' &&
              key !== 'adPlanDaily' &&
              key !== 'statisticsSpentToday'
            ) {
              const currencySums: Record<CurrencyEnum, { total: number; count: number }> = {} as Record<
                CurrencyEnum,
                { total: number; count: number }
              >;

              dataForPrimaryGrouping.forEach(elem => {
                const currency = elem.accountCurrency as CurrencyEnum;
                const value = +elem[key];

                if (value) {
                  if (!currencySums[currency]) {
                    currencySums[currency] = { total: 0, count: 0 };
                  }
                  currencySums[currency].total += value;
                  currencySums[currency].count += 1;
                }
              });
              // formatCurrency(total, currency)

              if (mathDefault === 'sum') {
                primaryGroup[key] = Object.entries(currencySums)
                  .sort(
                    ([currencyA], [currencyB]) =>
                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                  )
                  .map(([currency, { total }]) => `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`)
                  .join('<br />');
              }

              if (mathDefault === 'avg') {
                primaryGroup[key] = Object.entries(currencySums)
                  .sort(
                    ([currencyA], [currencyB]) =>
                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                  )
                  .map(
                    ([currency, { total, count }]) =>
                      `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                  )
                  .join('<br />');
              }

              // if (mathDefault === 'sum') {
              //   primaryGroup[key] = Object.entries(currencySums)
              //     .map(([currency, { total }]) => `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`)
              //     .join('<br />');
              // }
              // if (mathDefault === 'avg') {
              //   primaryGroup[key] = Object.entries(currencySums)

              //     .map(
              //       ([currency, { total, count }]) =>
              //         `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
              //     )
              //     .join('<br />');
              // }
            } else {
              primaryGroup[key] = null;
            }
          } else {
            // Handle non-currency metrics
            let result = 0;
            let resultLength = 0;

            dataForPrimaryGrouping.forEach(elem => {
              if (+elem[key]) {
                result += +elem[key];
                resultLength += 1;
              }
            });

            if (mathDefault === 'sum') {
              primaryGroup[key] = result;
            }
            if (mathDefault === 'avg') {
              primaryGroup[key] = resultLength ? result / resultLength : null;
            }
          }
        } else {
          // Default handling when mathAllowed is not set
          if (key !== 'accountCurrency') {
            primaryGroup[key] = null;
          }
        }
      });
      primaryGroup[tabMainName] = `${
        Array.isArray(primaryGroupingVariant) && primaryGroupingVariant.length > 0
          ? primaryGroupingVariant[0]?.code
          : primaryGroupingVariant
      }`;
      primaryGroup[tabMainId] = null;
      primaryGroup.orgHierarchy = undefined;
      primaryGroup.isGroupRow = true;
      counterKey = counterKey + 1;
      primaryGroup.key = counterKey; // в такой интерпритации исключаем дублирование ключей
      primaryGroup.price = null;

      if (secondaryMetric) {
        const secondaryVariants: { [key: string]: TGroupingData[] } = {};

        dataForPrimaryGrouping.forEach(el => {
          if (!el.isGroupRow) {
            const groupingVariant =
              secondaryMetric?.path === 'mt_project'
                ? el.mtProject?.map((el: { name: string }) => el.name).join(', ')
                : el[parseStringToCamelCase(secondaryMetric?.path)];
            if (secondaryVariants[groupingVariant]) {
              secondaryVariants[groupingVariant].push(el);
            } else {
              secondaryVariants[groupingVariant] = [el];
            }
          }
        });

        return [
          primaryGroup,
          ...[...Object.keys(secondaryVariants)]
            .sort((a, b) => sortRow(a, b))
            .flatMap((secondaryGroupingVariant, index) => {
              const dataForSecondaryGrouping = secondaryVariants[secondaryGroupingVariant];

              const secondaryGroup = JSON.parse(JSON.stringify(dataForSecondaryGrouping[0]));

              Object.keys(secondaryGroup).forEach(key2 => {
                const { mathAllowed, mathDefault, isCurrencyMetric } = metricProperties(metricsData[key2]);
                // Handle only if mathAllowed is set
                if (mathAllowed) {
                  if (isCurrencyMetric) {
                    // Handle currency metrics
                    if (
                      key2 !== 'price' &&
                      key2 !== 'adPlanLifetime' &&
                      key2 !== 'adPlanDaily' &&
                      key2 !== 'statisticsSpentToday'
                    ) {
                      const currencySums: Record<CurrencyEnum, { total: number; count: number }> = {} as Record<
                        CurrencyEnum,
                        { total: number; count: number }
                      >;

                      dataForSecondaryGrouping.forEach(elem => {
                        const currency = elem.accountCurrency as CurrencyEnum;
                        const value = +elem[key2];

                        if (value) {
                          if (!currencySums[currency]) {
                            currencySums[currency] = { total: 0, count: 0 };
                          }
                          currencySums[currency].total += value;
                          currencySums[currency].count += 1;
                        }
                      });

                      if (mathDefault === 'sum') {
                        secondaryGroup[key2] = Object.entries(currencySums)
                          .sort(
                            ([currencyA], [currencyB]) =>
                              currencyOrder.indexOf(currencyA as CurrencyEnum) -
                              currencyOrder.indexOf(currencyB as CurrencyEnum),
                          )
                          .map(
                            ([currency, { total }]) =>
                              `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                          )
                          .join('<br />');
                      }

                      if (mathDefault === 'avg') {
                        secondaryGroup[key2] = Object.entries(currencySums)
                          .sort(
                            ([currencyA], [currencyB]) =>
                              currencyOrder.indexOf(currencyA as CurrencyEnum) -
                              currencyOrder.indexOf(currencyB as CurrencyEnum),
                          )
                          .map(
                            ([currency, { total, count }]) =>
                              `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                          )
                          .join('<br />');
                      }

                      // if (mathDefault === 'sum') {
                      //   secondaryGroup[key2] = Object.entries(currencySums)
                      //     .map(
                      //       ([currency, { total }]) =>
                      //         `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                      //     )
                      //     .join('<br />');
                      // }
                      // if (mathDefault === 'avg') {
                      //   secondaryGroup[key2] = Object.entries(currencySums)
                      //     .map(
                      //       ([currency, { total, count }]) =>
                      //         `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                      //     )
                      //     .join('<br />');
                      // }
                    } else {
                      secondaryGroup[key2] = null;
                      secondaryGroup.price = null;
                    }
                  } else {
                    // Handle non-currency metrics
                    let result = 0;
                    let resultLength = 0;

                    dataForSecondaryGrouping.forEach(elem => {
                      if (+elem[key2]) {
                        result += +elem[key2];
                        resultLength += 1;
                      }
                    });

                    if (mathDefault === 'sum') {
                      secondaryGroup[key2] = result;
                    }
                    if (mathDefault === 'avg') {
                      secondaryGroup[key2] = resultLength ? result / resultLength : null;
                    }
                  }
                } else {
                  // Default handling when mathAllowed is not set
                  if (key2 !== 'accountCurrency') {
                    secondaryGroup[key2] = null;
                  }
                }
              });

              secondaryGroup[tabMainName] = `${
                Array.isArray(secondaryGroupingVariant) && secondaryGroupingVariant.length > 0
                  ? secondaryGroupingVariant[0].code
                  : secondaryGroupingVariant
              }`;
              counterKey = counterKey + 1;
              secondaryGroup.key = counterKey;

              secondaryGroup.isGroupRow = true;
              secondaryGroup.orgHierarchy = [primaryGroup.key, secondaryGroup.key];

              if (tertiaryMetric) {
                const tertiaryVariants: { [key: string]: TGroupingData[] } = {};

                dataForSecondaryGrouping.forEach(el => {
                  if (!el.isGroupRow) {
                    const groupingVariant =
                      tertiaryMetric?.path === 'mt_project'
                        ? el.mtProject?.map((el: { name: string }) => el.name).join(', ')
                        : el[parseStringToCamelCase(tertiaryMetric?.path)];
                    if (tertiaryVariants[groupingVariant]) {
                      tertiaryVariants[groupingVariant].push(el);
                    } else {
                      tertiaryVariants[groupingVariant] = [el];
                    }
                  }
                });

                return [
                  secondaryGroup,
                  ...[...Object.keys(tertiaryVariants)]
                    .sort((a, b) => sortRow(a, b))
                    .flatMap((tertiaryGroupingVariant, index) => {
                      const dataForTertiaryGrouping = tertiaryVariants[tertiaryGroupingVariant];
                      const tertiaryGroup = JSON.parse(JSON.stringify(dataForTertiaryGrouping[0]));

                      Object.keys(tertiaryGroup).forEach(key => {
                        const { mathAllowed, mathDefault, isCurrencyMetric } = metricProperties(metricsData[key]);

                        // Handle only if mathAllowed is set
                        if (mathAllowed) {
                          if (isCurrencyMetric) {
                            // Handle currency metrics
                            if (
                              key !== 'price' &&
                              key !== 'adPlanLifetime' &&
                              key !== 'adPlanDaily' &&
                              key !== 'statisticsSpentToday'
                            ) {
                              const currencySums: Record<CurrencyEnum, { total: number; count: number }> = {} as Record<
                                CurrencyEnum,
                                { total: number; count: number }
                              >;

                              dataForTertiaryGrouping.forEach(elem => {
                                const currency = elem.accountCurrency as CurrencyEnum;
                                const value = +elem[key];

                                if (value) {
                                  if (!currencySums[currency]) {
                                    currencySums[currency] = { total: 0, count: 0 };
                                  }
                                  currencySums[currency].total += value;
                                  currencySums[currency].count += 1;
                                }
                              });

                              if (mathDefault === 'sum') {
                                tertiaryGroup[key] = Object.entries(currencySums)
                                  .sort(
                                    ([currencyA], [currencyB]) =>
                                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                                  )
                                  .map(
                                    ([currency, { total }]) =>
                                      `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                                  )
                                  .join('<br />');
                              }

                              if (mathDefault === 'avg') {
                                tertiaryGroup[key] = Object.entries(currencySums)
                                  .sort(
                                    ([currencyA], [currencyB]) =>
                                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                                  )
                                  .map(
                                    ([currency, { total, count }]) =>
                                      `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                                  )
                                  .join('<br />');
                              }

                              // if (mathDefault === 'sum') {
                              //   tertiaryGroup[key] = Object.entries(currencySums)
                              //     .map(
                              //       ([currency, { total }]) =>
                              //         `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                              //     )
                              //     .join('<br />');
                              // }
                              // if (mathDefault === 'avg') {
                              //   tertiaryGroup[key] = Object.entries(currencySums)

                              //     .map(
                              //       ([currency, { total, count }]) =>
                              //         `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                              //     )
                              //     .join('<br />');
                              // }
                            } else {
                              tertiaryGroup[key] = null;
                            }
                          } else {
                            // Handle non-currency metrics
                            let result = 0;
                            let resultLength = 0;

                            dataForTertiaryGrouping.forEach(elem => {
                              if (+elem[key]) {
                                result += +elem[key];
                                resultLength += 1;
                              }
                            });

                            if (mathDefault === 'sum') {
                              tertiaryGroup[key] = result;
                            }
                            if (mathDefault === 'avg') {
                              tertiaryGroup[key] = resultLength ? result / resultLength : null;
                            }
                          }
                        } else {
                          // Default handling when mathAllowed is not set
                          if (key !== 'accountCurrency') {
                            tertiaryGroup[key] = null;
                          }
                        }
                      });

                      tertiaryGroup[tabMainName] = `${
                        Array.isArray(tertiaryGroupingVariant)
                          ? tertiaryGroupingVariant[0]?.code
                          : tertiaryGroupingVariant
                      }`;
                      counterKey = counterKey + 1;
                      tertiaryGroup.key = counterKey;
                      tertiaryGroup.isGroupRow = true;
                      tertiaryGroup.orgHierarchy = [primaryGroup.key, secondaryGroup.key, tertiaryGroup.key];
                      secondaryGroup.price = null;

                      return [
                        tertiaryGroup,
                        ...dataForTertiaryGrouping.map(dataItem => ({
                          ...dataItem,
                          orgHierarchy: [...tertiaryGroup.orgHierarchy, dataItem.key],
                        })),
                      ];
                    }),
                ];
              }

              return [
                secondaryGroup,
                ...dataForSecondaryGrouping.map(dataItem => ({
                  ...dataItem,
                  orgHierarchy: [...secondaryGroup.orgHierarchy, dataItem.key],
                })),
              ];
            }),
        ];
      }

      return [
        primaryGroup,
        ...dataForPrimaryGrouping.map(dataItem => ({ ...dataItem, orgHierarchy: [primaryGroup.key, dataItem.key] })),
      ];
    });
  return groupedData;
};
