import { Page } from '@plarin/core';
import { Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { navigateTo } from '../../routing/navigation';
import error504ImageSrc from './images/error504.svg';
import classes from './style.module.scss';

const ErrorPage: React.FC = () => {
  return (
    <Page title="Произошла ошибка на сервере">
      <div className={classes.hideSkroll}>
        <div className={clsx(classes.boxlayout)}>
          <div className={classes.errorContent}>
            <div className={classes.leftBox}>
              <Typography color="textPrimary" size="errorTitle" weight={700}>
                Не удалось загрузить данные
              </Typography>

              <Typography componentProps={{ className: classes.textBlock }} color="textPrimary" size="AuthSubheader">
                {typografText(
                  'В рамках нашего стремления предоставлять высокопроизводительные услуги в настоящее время ведется техническое обслуживание Платформы.',
                )}
                <Typography weight={700}>{typografText('Продолжительность обслуживания 10-30 минут.')}</Typography>
                <span>
                  {typografText(
                    'Приносим извинения за любые неудобства, которые может причинить вам данное обслуживание.',
                  )}
                </span>
                <Typography component="span" color="textSecondary">
                  {typografText(
                    'Пожалуйста попробуйте подключиться повторно через некоторое время. Если проблема возникает многократно, свяжитесь со службой поддержки по адресу',
                  )}{' '}
                  <Typography
                    underline
                    color="linkPrimary"
                    component="a"
                    componentProps={{ href: 'mailto:support@plarin.ru' }}
                  >
                    support@plarin.ru
                  </Typography>
                  .
                </Typography>
              </Typography>
              <div>
                <Button variant="filled" size="small" color="primary" onClick={() => navigateTo(0)}>
                  Обновить страницу
                </Button>
              </div>
            </div>
            <div className={clsx(classes.errorImage, classes.rightBox)}>
              <img src={error504ImageSrc} alt="504" />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ErrorPage;
