import { entitiesVK } from '@plarin/utils';
import { entitiesNameYD, entitiesYD, TYdFieldItem } from '../../types/manage-yd/types';
import { TObjType } from '../../types/metrics';
import { TReportEntity } from '../../types/reports/reports';

// Нейминг чипсов в дровере выбора метрик

export const getSortMetricsVK = (entities: (TReportEntity | TObjType)[]) => {
  return entities
    .map(entitie => {
      if (entitie === entitiesVK.ACCOUNT) return { name: 'Accounts', number: 0 };
      if (entitie === entitiesVK.AD_PLAN) return { name: 'Campaigns', number: 1 };
      if (entitie === entitiesVK.AD_GROUP) return { name: 'Groups', number: 2 };
      return { name: 'Ads', number: 3 };
    })
    .sort((a, b) => {
      return a.number - b.number;
    })
    .map(metric => metric.name);
};

export const getSortMetricsYD = (entities: TYdFieldItem | TObjType[]) => {
  return entities
    .map(entitie => {
      if (entitie === entitiesYD.ACCOUNT) return { name: entitiesNameYD.ACCOUNTS, number: 0 };
      if (entitie === entitiesYD.CAMPAIGN) return { name: entitiesNameYD.CAMPAIGNS, number: 1 };
      if (entitie === entitiesYD.GROUP) return { name: entitiesNameYD.GROUPS, number: 2 };
      return { name: entitiesNameYD.ADS, number: 3 };
    })
    .sort((a, b) => {
      return a.number - b.number;
    })
    .map(metric => metric.name);
};
