import { Popover } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SettingsMenu } from '../../../components/settings-menu';

interface SettingMenuProps {
  data: any;
  isEditDrawer?: boolean;
  actionAnchorEl?: HTMLDivElement | null;
  onClose: (e: React.MouseEvent | Event) => void;
}

export const SettingsMenuTeams = observer(({ data, onClose, actionAnchorEl, isEditDrawer }: SettingMenuProps) => {
  const {
    profileStore: { data: profileData, WSRole },
    teams: { onOpenDrawer, setModalData, joinTeam, leaveTeam, setMenuIsLoading },
  } = useAppDataStore();

  const isAdmin = WSRole === 'wsrole:admin' || WSRole === 'wsrole:owner';

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const openMenu = (e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
  };

  const closeMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent | Event | undefined) => {
    e?.stopPropagation();
    onClose ? e && onClose(e) : setAnchorEl(null);
  };

  const settingMenu = useMemo(() => {
    const isMember = data.member_ids.includes(profileData.user_id);
    const isLeader = data.leader_id === profileData.user_id;
    const result = [];
    if (!isEditDrawer && (isAdmin || isLeader)) {
      result.push({
        name: 'Редактировать',
        action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
          onOpenDrawer({ ...data, isEditDrawer: true });
          closeMenu(e);
        },
      });
    }
    result.push({
      name: isMember ? 'Выйти из команды' : 'Присоедениться к команде',
      action: isMember
        ? (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
            leaveTeam(data, profileData.user_id, isAdmin || false);
            setMenuIsLoading(true);
            closeMenu(e);
          }
        : (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
            joinTeam(data, profileData.user_id);
            setMenuIsLoading(true);
            closeMenu(e);
          },
    });
    if (isAdmin || isLeader) {
      result.push(
        {
          name: data.status === 'inactive' ? 'Разархивировать' : 'Архивировать',
          action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
            setModalData({ type: data.status === 'inactive' ? 'unArchive' : 'archive', data: data });
            closeMenu(e);
          },
        },
        {
          name: 'Удалить команду',
          action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
            setModalData({ type: 'delete', data: data });
            closeMenu(e);
          },
        },
      );
    }
    return result;
  }, [data]);

  useEffect(() => {
    actionAnchorEl !== undefined && setAnchorEl(actionAnchorEl);
  }, [actionAnchorEl]);

  if (!(!data.closed || isAdmin || data.leader_id === profileData.user_id)) return <></>;

  return (
    <>
      {actionAnchorEl === undefined ? (
        <SettingsMenu onOpen={openMenu} anchorEl={anchorEl} settingMenu={settingMenu} onClose={closeMenu} />
      ) : (
        <Popover anchorEl={anchorEl} isOpen={!!anchorEl} setIsOpen={closeMenu} dataList={settingMenu} />
      )}
    </>
  );
});
