import { Page } from '@plarin/core';
import { Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { navigateTo } from '../../routing/navigation';
import error400ImageSrc from './images/error400.svg';
import classes from './style.module.scss';
import { textInvalidRequest } from './text-error';

type TErrorPageInvalidRequest = {
  className?: string;
  textError: keyof typeof textInvalidRequest;
};

const ErrorPageInvalidRequest = ({ textError, className }: TErrorPageInvalidRequest) => {
  const { titlePage, textDescription, textRecommendation, textButton } = textInvalidRequest[textError];

  return (
    <Page title="Произошла ошибка запроса на сервер">
      <div className={classes.hideSkroll}>
        <div className={clsx(classes.boxlayout, className)}>
          <div className={clsx(classes.errorContent, classes.errorContent400)}>
            <div className={clsx(classes.leftBox400)}>
              <Typography color="textPrimary" size="errorTitle" weight={700}>
                {typografText(titlePage)}
              </Typography>

              <Typography
                componentProps={{ className: clsx(classes.textBlock, classes.textBlock400) }}
                color="textPrimary"
                size="AuthSubheader"
              >
                {typografText(textDescription)}
                <Typography component="span" color="textSecondary">
                  {typografText(textRecommendation)}{' '}
                  <Typography
                    underline
                    color="linkPrimary"
                    component="a"
                    componentProps={{ href: 'mailto:support@plarin.ru' }}
                  >
                    support@plarin.ru
                  </Typography>
                  .
                </Typography>
              </Typography>
              <div>
                <Button variant="filled" size="small" color="primary" onClick={() => navigateTo(0)}>
                  {textButton}
                </Button>
              </div>
            </div>
            <div className={clsx(classes.rightBox, classes.rightBox400)}>
              <img src={error400ImageSrc} alt="400" />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ErrorPageInvalidRequest;
