import { Typography } from '@plarin/design';
import { ManageVkTabNameEnum } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { BidObjectType, BidObjectTypeEnum as ObjType, TNewFixBids } from '../../../inputs';
import { getFooterText } from '../../../table';
import { TGetProjection, TRows } from '../../types';
import { ListInfoItem } from './components';
import classes from './style.module.scss';

type ListProjectionsProps = {
  itemsBids: TNewFixBids;
  selectedRows: TRows;
  removeItem: (itemId: string | number) => void;
  getProjection: TGetProjection;
  itemType: BidObjectType;
};

export const ListProjections = ({
  itemsBids,
  selectedRows,
  getProjection,
  removeItem,
  itemType,
}: ListProjectionsProps) => {
  const returnTabName = () => {
    if (itemType === ObjType.ad_plan) return ManageVkTabNameEnum.ADPLANS;
    if (itemType === ObjType.ad_group) return ManageVkTabNameEnum.ADGROUPS;
    if (itemType === ObjType.banner) return ManageVkTabNameEnum.ADS;
  };

  return (
    <div>
      <div className={clsx(classes.titles)}>
        <div className={classes.itemCol} style={{ whiteSpace: 'nowrap' }}>
          <Typography size="Main" weight={600}>
            {/* @ts-ignore*/}
            {getFooterText(returnTabName(), selectedRows.length)}
          </Typography>
        </div>
        <div className={classes.bidCol}>
          <Typography size="Main" weight={600}>
            Ставка
          </Typography>
        </div>
        <div className={classes.coverageCol}>
          <Typography size="Main" weight={600}>
            Охват
          </Typography>
        </div>
      </div>
      <ListInfoItem
        itemsBids={itemsBids}
        selectedRows={selectedRows}
        removeItem={removeItem}
        getProjection={getProjection}
        itemType={itemType}
      />
    </div>
  );
};
