import { Typography } from '@plarin/design';
import React from 'react';
import classes from './style.module.scss';

export const ArchivedAdGroup = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Среди выбранных кампаний есть архивные. <br />
        Ставка не может быть отредактирована.
      </Typography>
    </div>
  );
};

export const AdPlanHasAdGroupBudgetOptimization = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Выбрана кампания, для которой оптимизация лимита бюджета включена на уровне групп объявлений.
        <br />
        <br />
        Чтобы изменить лимиты бюджета и/или предельную стоимость, перейдите во вкладку "Группы объявлений" или
        "Объявления".
      </Typography>
    </div>
  );
};

export const IsBanned = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Редактирование такого типа кампаний в настоящий момент не поддерживается.
      </Typography>
    </div>
  );
};
