import {
  AdPlanHasAdGroupBudgetOptimization,
  IsBanned,
  EditBidFormTypes,
  FixedEditBid,
  getFormType,
  getItemsType,
  GridApi,
  itemHasAdGroupBudgetOptimization,
  itemsHasDifferentBudgetOptimizationTypes,
  strategyBidTypesAreDifferent,
  StrategyEditBid,
  strategyObjectivesAreDifferent,
  TRows,
  WhatIsEditedInStrategy,
  CurrencyFiltrator,
  ObjectiveFiltrator,
  NetworkFiltrator,
  OptimizationLevelFiltrator,
  bannedObjectives,
} from '@plarin/inputs';
import { isUrlProd } from '@plarin/utils';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useMemo, useEffect } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

type EditBidProps = {
  close: () => void;
  selectedRows: TRows;
  deselectRows?: (keysToKeep: any[]) => void;
  gridApi: GridApi;
};

const isBidType = (bidType: EditBidFormTypes, rows: TRows): boolean => rows.every(val => getFormType(val) === bidType);

export const EditBid = observer(({ close, selectedRows, gridApi, deselectRows }: EditBidProps) => {
  // Я серьёзно, перестаньте удалять. Это нужно на деве.
  useEffect(() => {
    !isUrlProd && selectedRows.length < 10 && selectedRows.forEach(row => console.log(toJS(row)));
  }, [selectedRows]);

  const {
    manageVK: { getProjection, editFixedBid: editBid, editStrategyBid, getAvailableAds },
  } = useAppDataStore();

  const hasDifferentCurrency = () => selectedRows.some(val => val.accountCurrency !== selectedRows[0].accountCurrency);

  const isSingleBidType = useMemo(
    () => isBidType('FixedEditBid', selectedRows) || isBidType('StrategyEditBid', selectedRows),
    [selectedRows],
  );

  if (!isSingleBidType) {
    return <NetworkFiltrator filteredRows={selectedRows} deselectRows={deselectRows} />;
  }

  // Только "Стратегия":
  if (isBidType('StrategyEditBid', selectedRows)) {
    if (selectedRows.length > 1 && hasDifferentCurrency()) {
      return <CurrencyFiltrator filteredRows={selectedRows} deselectRows={deselectRows} />;
    }

    // 2
    if (strategyBidTypesAreDifferent(selectedRows) || strategyObjectivesAreDifferent(selectedRows)) {
      return <ObjectiveFiltrator filteredRows={selectedRows} deselectRows={deselectRows} />;
    }

    // 3 стратегия кампании/группы
    if (itemsHasDifferentBudgetOptimizationTypes(selectedRows)) {
      return <OptimizationLevelFiltrator filteredRows={selectedRows} deselectRows={deselectRows} />;
    }

    // 4 пользователь пытается из вкладки адпланов редактировать сущности, у которых оптимизация на уровне групп.
    if (
      selectedRows.length === 1 &&
      getItemsType(window.location.pathname) === 'ad_plan' &&
      itemHasAdGroupBudgetOptimization(selectedRows)
    ) {
      return <AdPlanHasAdGroupBudgetOptimization />;
    }

    // 5 Пользователь пытается отредактировать "Опросы", для которых у нас нет формы
    if (!!bannedObjectives.find(banned => banned === selectedRows[0]?.adPlanObjective)) {
      return <IsBanned />;
    }

    return (
      <StrategyEditBid
        selectedRows={selectedRows}
        close={close}
        onSaveAction={editStrategyBid}
        gridApi={gridApi}
        getAvailableAds={getAvailableAds}
        whatIsEdited={WhatIsEditedInStrategy.bid}
      />
    );
  }

  // Только "Фиксированная ставка":
  if (isBidType('FixedEditBid', selectedRows)) {
    // разные валюты
    if (selectedRows.length > 1 && hasDifferentCurrency()) {
      return <CurrencyFiltrator filteredRows={selectedRows} deselectRows={deselectRows} />;
    }

    return (
      <FixedEditBid
        close={close}
        selectedRows={selectedRows}
        getProjection={getProjection}
        onSaveAction={editBid}
        gridApi={gridApi}
        getAvailableAds={getAvailableAds}
      />
    );
  }

  // непредвиденные исключения, либо с бэкенда пришли странные данные
  return <div className={classes.wrap}>Не получилось определить тип ставки.</div>;
});
