import { Typography, ShopCartIcon } from '@plarin/design';
import { ManageVkTabNameEnum, isUrlProd } from '@plarin/utils';
import type { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { toJS } from 'mobx';
import React, { useState } from 'react';
import { HightLightText } from '../../../hightlight-text';
import { CellLoader } from '../../../preloader';
import { EllipsisTooltip } from '../../../tooltip';
import { Column } from '../../table';
import { copy } from '../../utils';
import { StatusColorEnum } from '../status-renderer-yd';
import { RenderIcon, getOsIcon, eCommerceIcon } from './IconsToASecondLine';
import { AdsPreview } from './components/ads-preview';
import classes from './style.module.scss';

interface TwoLinesRendererProps extends ICellRendererParams {
  alternativeBullet?: string;
  firstLine: string;
  firstLineIsBold?: boolean;
  secondLine: string;
  mayCopySecondLine?: boolean;
  ydSecondLineIcon?: string;
  afSecondLineIcon?: string;
  tabName?: string;
  createSwitchButton?: (tabName: ManageVkTabNameEnum, data: any, gridApi: GridApi) => React.ReactNode;
  hasAdsPreview?: boolean;
  disableGroupCell?: true;
  ecommerceProp?: 'ecommerce';
  isQuickFilter?: boolean;
  ActionsName?: React.FC<{
    name: string;
    tabName?: string;
    data: any;
    gridApi: GridApi;
    column: Column;
    setIsHoverCell: (hover: boolean) => void;
    disableEditName?: boolean;
  }>;
  disableEditName?: boolean;
  SettingsCellMenu?: (data: any) => JSX.Element;
  BadgePro?: (data: any) => JSX.Element;
  page?: string;
  isManageYD?: boolean;
}

const messageItWasCopied = (isCopied: boolean) => (isCopied ? 'Скопировано!' : 'Нажмите, чтобы скопировать');

export const TwoLinesRenderer = ({
  firstLine,
  firstLineIsBold,
  secondLine,
  mayCopySecondLine,
  afSecondLineIcon, // иконка для второй строки. Нужно, например, для отображения иконки андроидла/ios в таблице интеграций appsflyer
  ydSecondLineIcon, // удалить, когда yd ячейки будут новым компонентом рендериться
  alternativeBullet, // удалить, когда yd ячейки будут новым компонентом рендериться
  createSwitchButton,
  hasAdsPreview,
  disableGroupCell,
  ecommerceProp,
  isQuickFilter = true,
  ActionsName, // кнопки для копирования и редактирования названия. удалить, когда yd ячейки будут новым компонентом рендериться
  disableEditName, // удалить, когда yd ячейки будут новым компонентом рендериться
  page, // указывает шестерёнке, какие экшены рендерит в попапе
  SettingsCellMenu,
  BadgePro,
  isManageYD,
  ...props
}: TwoLinesRendererProps) => {
  const { data } = props;

  const isDisabled = data.syncStatusCode === 'disabled';
  const childsActionsDisabled = !data.isParent && data._parent_status !== 'enabled';

  const filterValue = (isQuickFilter && props.api.getQuickFilter()) || '';

  // удалить, когда yd ячейки будут новым компонентом рендериться
  const editNameIsDisabled = disableEditName || (alternativeBullet && data[alternativeBullet]?.includes('Архивн'));

  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isHoverCell, setIsHoverCell] = useState(false);

  if (new Set(Object.keys(data)).has('orgHierarchy') && data.isGroupRow && !disableGroupCell) {
    return <Typography weight={600}>{data[firstLine]}</Typography>;
  }

  const updateData = (value: boolean) => {
    setIsShowTooltip(value);
  };

  const resetIsCopied = () => setIsCopied(false);

  // шестерёнка с экшенами на включение/выключение/удаление
  const settingsButton = !childsActionsDisabled && SettingsCellMenu && page && (
    <SettingsCellMenu data={props?.data} page={page} gridApi={props.api} />
  );

  // для ЯД клиента не про
  const badgeProButton = data['is_old' as const] && BadgePro && <BadgePro />;

  // удалить, когда yd ячейки будут новым компонентом рендериться
  const statusSwitcherButton = () =>
    Object.keys(data).length
      ? createSwitchButton && (
          <div className={classes.switch}>
            {createSwitchButton(props.tabName as ManageVkTabNameEnum, { ...data }, props.api)}
          </div>
        )
      : null;

  const getFirstLine = () => (
    <div className={classes.first}>
      <EllipsisTooltip
        classNameContainer={clsx(classes.firstValue, firstLineIsBold && classes.firstBold)}
        tooltipMessage={alternativeBullet && data[alternativeBullet] === 'disabled' ? 'Выключена' : data[firstLine]}
      >
        <HightLightText
          text={alternativeBullet && data[alternativeBullet] === 'disabled' ? 'Выключена' : data[firstLine]}
          filterValue={filterValue}
          textEllips={true}
        />
      </EllipsisTooltip>

      {/* кнопки для копирования и редактирования названия. удалить, когда yd ячейки будут новым компонентом рендериться */}
      {ActionsName && isHoverCell && data[firstLine] && (
        <ActionsName
          name={data[firstLine]}
          tabName={props.tabName}
          data={data}
          gridApi={props.api}
          column={props.column as any as Column}
          setIsHoverCell={setIsHoverCell}
          disableEditName={editNameIsDisabled}
        />
      )}
    </div>
  );

  const getSecondLine = () => {
    return (
      (data[secondLine] || data[secondLine] === null) && (
        <div
          className={clsx(
            classes.second,
            alternativeBullet &&
              data[alternativeBullet] &&
              (data[alternativeBullet] === 'disabled' ||
                data[alternativeBullet].includes('Архивн') ||
                data[alternativeBullet] === StatusColorEnum.ARCHIVED ||
                data.syncStatusCode === 'disabled') &&
              classes.errorGray,
          )}
        >
          {ydSecondLineIcon && RenderIcon(data[ydSecondLineIcon])}
          {afSecondLineIcon && getOsIcon(isDisabled, data[afSecondLineIcon])}

          {mayCopySecondLine ? (
            <EllipsisTooltip
              classNameContainer={classes.containerTooltip}
              tooltipMessage={isShowTooltip ? data[secondLine] : messageItWasCopied(isCopied)}
              alwaysShowTooltip={true}
            >
              <span
                className={classes.secondCopy}
                ref={ref => {
                  if (!ref) return;
                  ref.onclick = event => {
                    copy(event, data[secondLine]).then(() => setIsCopied(true));
                  };
                }}
                onMouseLeave={resetIsCopied}
              >
                <HightLightText text={data[secondLine]} filterValue={filterValue} textEllips={true} />
              </span>
            </EllipsisTooltip>
          ) : (
            <>
              {' '}
              <EllipsisTooltip tooltipMessage={data[secondLine]}>
                <HightLightText text={data[secondLine]} filterValue={filterValue} textEllips={true} />
              </EllipsisTooltip>
            </>
          )}
        </div>
      )
    );
  };

  const eCommerceBlock = (
    <div className={classes.rightBlock}>
      {eCommerceIcon()}
      {SettingsCellMenu && settingsButton}
    </div>
  );

  const getRightBlock = () => {
    // иконке e-commerce и, опционально, шестерёнка с экшенами
    if (ecommerceProp && (data[ecommerceProp] || data[ecommerceProp] !== null)) return eCommerceBlock;

    if (BadgePro && SettingsCellMenu) {
      return (
        <div className={clsx(classes.rightBlock, classes.boxNotPro)}>
          {badgeProButton}
          {settingsButton}
        </div>
      );
    }
    return settingsButton;
  };

  return (
    <div
      className={clsx(classes.container, !secondLine && classes.oneLineAligment)}
      onMouseEnter={() => setIsHoverCell(true)}
      onMouseLeave={() => setIsHoverCell(false)}
      onClick={() => !isUrlProd && console.log(toJS(data))}
    >
      <div
        className={clsx(
          classes.leftBlock,
          createSwitchButton && classes.leftBlockSpaceBetween,
          (ecommerceProp || BadgePro) && classes.withIcon,
          isManageYD && classes.leftBlockYD,
        )}
      >
        {hasAdsPreview && <AdsPreview data={data} />}

        <div className={clsx(classes.leftWrap)}>
          {getFirstLine()}
          {getSecondLine()}
        </div>
        {statusSwitcherButton()}
      </div>

      {getRightBlock()}
    </div>
  );
};
