import React from 'react';

// Типы данных для menu store

// пункт меню в сторе (не в разворачивающихся секциях)
export type TTopLevelItems = {
  id: number;
  title: string;
  link: string;
  rootLink?: string;
  isSelected?: boolean;
  icon: JSX.Element;
  isOpenDrawer?: boolean;
}[];

type TSectionItemData = {
  title: string;
  link: string;
  rootLink?: string;
  icon?: JSX.Element;
  isSelected: boolean;
  index: number;
};

export type TStoreMenuSection = {
  key: string;
  title: string;
  sectionItems: TSectionItemData[];
  type: string;
  isOpen: boolean;
};

// Типы для компонентов, рендерящих элементы меню на основе данных из стора

export type TMainMenuItem = {
  title: string;
  icon: React.ReactNode;
  isSelected?: boolean;
  onClick(): void;
};

// //////////////////////////////////////////////////////////////////////////

export enum SectionPaths {
  manage = 'manage',
  reports = 'reports',
  workspace = 'ws',
  integrations = 'integrations',
  allProjectsOverview = 'projects',
}

// основные разделы меню главного приложения
export const menuSectionsPaths = {
  MANAGE: SectionPaths.manage,
  REPORTS: SectionPaths.reports,
  WORKSPACE: SectionPaths.workspace,
  INTEGRATIONS: SectionPaths.integrations,
  ALL_PROJECTS_OVERVIEW: SectionPaths.allProjectsOverview,
};

export enum MenuItemPaths {
  manage_vk = 'vk',
  manage_yd = 'yd',
  all_reports = 'all',
  members = 'members',
  teams = 'teams',
  projects = 'projects',
  wsSettings = 'settings',
  integrations_yandex_metrika = 'ym',
  integrations_yandex_direct = 'yd',
  integrations_google_analytics = 'ga',
  integrations_appsflyer = 'af',
  integrations_vk = 'vk',
}

// пункты меню внутри основных разделов меню главного приложения
export const menuItemsPath = {
  MANAGE_VK: MenuItemPaths.manage_vk,
  MANAGE_YD: MenuItemPaths.manage_yd,
  ALL_REPORTS: MenuItemPaths.all_reports,
  MEMBERS: MenuItemPaths.members,
  TEAMS: MenuItemPaths.teams,
  PROJECTS: MenuItemPaths.projects,
  SETTINGS: MenuItemPaths.wsSettings,
  INTEGRATIONS_YANDEX_METRICS: MenuItemPaths.integrations_yandex_metrika,
  INTEGRATIONS_YANDEX_DIRECT: MenuItemPaths.integrations_yandex_direct,
  INTEGRATIONS_GOOGLE_ANALYTICS: MenuItemPaths.integrations_google_analytics,
  INTEGRATIONS_APPSFLYER: MenuItemPaths.integrations_appsflyer,
  INTEGRATIONS_VK: MenuItemPaths.integrations_vk,
};

export type TMenuItem = {
  title: string;
  layoutTitle?: string | JSX.Element;
  tabTitle?: string; // если нужно задать title во вкладке не такой, как название пункта меню приложения
  icon: JSX.Element;
  index: number;
  selected: boolean;
  link: string;
  menuItemKey: typeof menuItemsPath[keyof typeof menuItemsPath] | 'none' | string;
};

export type TMenuSection = {
  title: string;
  sectionIndex: number;
  isOpen: boolean;
  menuItems: TMenuItem[];
  path: typeof menuSectionsPaths[keyof typeof menuSectionsPaths];
  icon?: JSX.Element;
  children?: React.ReactNode;
};

export enum ManageVkTab {
  ACCOUNT = 'accounts',
  AD_PLAN = 'adplans',
  AD_GROUP = 'adgroups',
  AD = 'ads',
}

export const manageVkTabs = {
  ACCOUNT: ManageVkTab.ACCOUNT,
  ADPLAN: ManageVkTab.AD_PLAN,
  AD_GROUP: ManageVkTab.AD_GROUP,
  AD: ManageVkTab.AD,
};

export enum ManageYDTabs {
  accounts = 'accounts',
  campaigns = 'campaigns',
  groups = 'groups',
  ads = 'ads',
}

export const objYDTypes = {
  accounts: 'account',
  campaigns: 'campaign',
  groups: 'ad_group',
  ads: 'ad',
} as const;

export const manageYDTabs = {
  ACCOUNTS: ManageYDTabs.accounts,
  CAMPAIGNS: ManageYDTabs.campaigns,
  GROUPS: ManageYDTabs.groups,
  ADS: ManageYDTabs.ads,
};

export enum ManageYDTabID {
  accountId = 'accountId',
  campaignId = 'campaignId',
  adGroupId = 'adGroupId',
  adId = 'adId',
}

export enum ManageYDTabState {
  campaignState = 'campaignState',
  adState = 'adState',
}

export enum ManageYDTabStatus {
  accountStatus = 'accountStatus',
  campaignStatus = 'campaignStatus',
  adGroupStatus = 'adGroupStatus',
  adStatus = 'adStatus',
}
