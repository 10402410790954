export enum ManageVkTabNameEnum {
  ACCOUNTS = 'client_ids',
  ADPLANS = 'ad_plan_ids',
  ADGROUPS = 'ad_group_ids',
  ADS = 'ad_ids',
}

export enum ManageYDTabNameEnum {
  ACCOUNTS = 'accounts_ids',
  CAMPAIGNS = 'campaigns_ids',
  GROUPS = 'groups_ids',
  ADS = 'ads_ids',
}

export enum IntegrationsTabNameEnum {
  ACCOUNTS = 'accounts',
  CABINETS = 'cabinets',
}

// Главное хранилище строк, соответствующих названиям рекламных объектов Manage VK
export enum EntitiesVK {
  account = 'account',
  ad_plan = 'ad_plan',
  ad_group = 'ad_group',
  banner = 'banner',
}

// Главный словарь со строками, соответствующими названиям рекламных объектов Manage VK
export const entitiesVK = {
  ACCOUNT: EntitiesVK.account,
  AD_PLAN: EntitiesVK.ad_plan,
  AD_GROUP: EntitiesVK.ad_group,
  BANNER: EntitiesVK.banner
}

export const objTypeByIdName = {
  client_ids: entitiesVK.ACCOUNT,
  ad_plan_ids: entitiesVK.AD_PLAN,
  ad_group_ids: entitiesVK.AD_GROUP,
  ad_ids: entitiesVK.BANNER,
} as const;

export const manageVkTableMainNames = {
  client_ids: 'clientAccount',
  ad_plan_ids: 'adPlanName',
  ad_group_ids: 'adGroupName',
  ad_ids: 'adPlanName',
};

export const TabNameToReq = {
  account: 'account_ids',
  ad_plan: 'ad_plan_ids',
  ad_group: 'ad_group_ids',
  banner: 'ad_ids',
};

export const VkTabNameToIds = {
  account: 'accountId',
  ad_plan: 'adPlanId',
  ad_group: 'adGroupId',
  banner: 'adId',
};

export enum FakeGroupCellPages {
  af = 'af',
  ga = 'ga',
  ym = 'ym',
  yd = 'yd',
}
