import {
  AppLocalStorage,
  AUTH_ACCESS_TOKEN,
  AUTH_REFRESH_TOKEN,
  BasicStore,
  CONFIRM_EMAIL_TOKEN_KEY,
  FORGOT_PASSWORD_EMAIL,
  getApiUrl,
  httpClient,
  httpClient4,
} from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { NotificationTitle } from '@plarin/utils';
import { AxiosError } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { paths } from '../../types/profile/apispec';
import { saveAuthTokens } from '../components/app';
import { removeAuthTokens } from '../components/app';
import { TLoginResp } from '../pages/login/types';
import { TConfirmEmailReq } from '../pages/registration/types';
import { navigateTo } from '../routing/navigation';
import { routerPaths } from '../routing/router-path';
import { STATUS, STORAGE_ITEM } from '../utils/constants';
import { getAlertProps } from '../utils/get-alert-props';
import { getUrlAuthData } from '../utils/get-url-auth-data';
import { ChangeEmailResp } from './../../types/profile/types';
import { IntegrationsAFStore } from './integrationsAF.store';
import { IntegrationsGAStore } from './integrationsGA.store';
import { IntegrationsVKStore } from './integrationsVK.store';
import { IntegrationsYDStore } from './integrationsYD.store';
import { IntegrationsYMStore } from './integrationsYM.store';
import { ManageVKStore } from './manageVK.store';
import { ManageYDStore } from './manageYD.store';
import { MembersStore } from './members.store';
import { MenuStore } from './menu.store';
import { ProfileStore } from './profile.store';
import { ProjectOverviewStore } from './project-overview.store';
import { ProjectsStore } from './projects.store';
import { ReportsStore } from './reports.store';
import { TeamsStore } from './teams.store';
import { WsStore } from './ws.store';

export type TAppDataStoreProps = {
  stores: {
    profile: ProfileStore;
    manageVK: ManageVKStore;
    manageYD: ManageYDStore;
    integrationsVK: IntegrationsVKStore;
    menu: MenuStore;
    reports: ReportsStore;
    members: MembersStore;
    teams: TeamsStore;
    projects: ProjectsStore;
    integrationsGA: IntegrationsGAStore;
    integrationsYM: IntegrationsYMStore;
    integrationsYD: IntegrationsYDStore;
    integrationsAF: IntegrationsAFStore;
    ws: WsStore;
    projectOverview: ProjectOverviewStore;
  };
  storage: AppLocalStorage;
};

export class AppDataStore extends BasicStore {
  alertProps?: TAlertProps;
  profileStore: ProfileStore;
  storage: AppLocalStorage;
  integrationsVK: IntegrationsVKStore;
  manageVK: ManageVKStore;
  manageYD: ManageYDStore;
  menu: MenuStore;
  reports: ReportsStore;
  members: MembersStore;
  teams: TeamsStore;
  projects: ProjectsStore;
  integrationsGA: IntegrationsGAStore;
  integrationsYM: IntegrationsYMStore;
  projectOverview: ProjectOverviewStore;
  integrationsYD: IntegrationsYDStore;
  integrationsAF: IntegrationsAFStore;
  ws: WsStore;
  constructor(props: TAppDataStoreProps) {
    super();
    this.profileStore = props.stores.profile;
    this.storage = props.storage;
    this.integrationsVK = props.stores.integrationsVK;
    this.manageYD = props.stores.manageYD;
    this.manageVK = props.stores.manageVK;
    this.menu = props.stores.menu;
    this.reports = props.stores.reports;
    this.members = props.stores.members;
    this.teams = props.stores.teams;
    this.projects = props.stores.projects;
    this.integrationsGA = props.stores.integrationsGA;
    this.integrationsYM = props.stores.integrationsYM;
    this.integrationsYD = props.stores.integrationsYD;
    this.integrationsAF = props.stores.integrationsAF;
    this.ws = props.stores.ws;
    this.projectOverview = props.stores.projectOverview;

    makeObservable<this>(this, {
      profileStore: observable,
      reports: observable,
      init: action.bound,
      confirm: action.bound,
      confirmSuccess: action.bound,
      confirmError: action.bound,
    });

    this.init().then();
  }

  resetAlert = () => runInAction(() => (this.alertProps = undefined));

  init = async () => {
    this.storage.tableState();

    let { accessToken, refreshToken } = getUrlAuthData();
    const { token, fname, lname } = getUrlAuthData();

    const tokenToConfirmEmail = new URLSearchParams(window.location.search).get(CONFIRM_EMAIL_TOKEN_KEY);
    const emailToRestorePassword = new URLSearchParams(window.location.search).get(FORGOT_PASSWORD_EMAIL);

    const isRegistratingSocial = token && fname && lname;
    const isConfirmingEmail = token && window.location.href.includes('confirm-email');
    const isSettingPassword = token && window.location.href.includes('new-password');
    const isConfirmNewEmail = token && window.location.href.includes('profile');

    tokenToConfirmEmail && this.profileStore.setIsTokenLink(true);

    // если пользователь подверждает почту для регистрации или восстанавливает забытый пароль, удаляем авторизационные токены, чтобы его не авторизовало и не редиректнуло в приложение
    if (tokenToConfirmEmail || emailToRestorePassword) {
      removeAuthTokens();
    }

    if (isConfirmNewEmail) {
      await this.confirmNewEmail({ token });
    }

    if (!accessToken && !refreshToken) {
      accessToken = this.storage.get(AUTH_ACCESS_TOKEN);
      refreshToken = this.storage.get(AUTH_REFRESH_TOKEN);
    }

    if (refreshToken) {
      refreshToken && saveAuthTokens(accessToken || '', refreshToken).then();
      // navigate(routerPaths.auth.ROOT);
      await this.profileStore.loadProfile();
    } else if (isRegistratingSocial) {
      navigateTo(`${routerPaths.unAuth.REGISTRATION_SOCIAL}?${new URLSearchParams(window.location.search)}`, {
        replace: true,
      });
    } else if (isConfirmingEmail) {
      await this.confirm({ token });
    } else if (isSettingPassword) {
      // устанавливаем пароль при регистрации аккаунта или после того как забыли пароль
      const user = { token: tokenToConfirmEmail, email: emailToRestorePassword };
      sessionStorage.setItem(STORAGE_ITEM.token.FORGOT, JSON.stringify(user));
      navigateTo(routerPaths.unAuth.CREATE_PASSWORD);
    }

    runInAction(() => (this.isFulfilled = true));
  };

  confirm = async (data: TConfirmEmailReq) =>
    this.execRequest<[TLoginResp]>([httpClient.post(getApiUrl<paths>('/api/v1/user/confirm/email'), data)])
      .then(resp => this.confirmSuccess(resp, data))
      .catch(err => {
        this.confirmError(err);
        navigateTo(routerPaths.unAuth.REGISTRATION);
      });

  confirmSuccess = ([resp]: [TLoginResp], data: TConfirmEmailReq) => {
    if (resp.status === STATUS.SUCCESS) {
      sessionStorage.setItem(STORAGE_ITEM.token.REGISTRATION, data.token);
      navigateTo(routerPaths.unAuth.CREATE_PASSWORD);
    }
  };

  confirmError = (err: AxiosError<any>) => {
    this.alertProps = getAlertProps(err);
  };

  confirmNewEmail = async (data: TConfirmEmailReq) =>
    this.execRequest<ChangeEmailResp>(httpClient4.post('/api/v1/user/confirm/new_email', data))
      .then(resp => {
        saveAuthTokens(resp.access_token, resp.refresh_token, resp.refresh_expires);
        this.addNotification({
          type: STATUS.SUCCESS,
          title: NotificationTitle.EMAIL_CHANGED,
        });
        navigateTo(routerPaths.auth.PROFILE);
      })
      .catch(err => {
        this.confirmError(err);
        navigateTo(routerPaths.unAuth.LOGIN);
      });
}
