import Joi from '@hapi/joi';
import {
  BasicFormStore,
  EApiCodes,
  emailAllowedSymbols,
  emailValidateRule,
  getApiUrl,
  httpClient,
  TFormInitParams,
} from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { timeToSendReq } from '@plarin/utils';
import { AxiosError } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { navigateTo } from '../../routing/navigation';
import { routerPaths } from '../../routing/router-path';
import { STATUS } from '../../utils/constants';
import { getAlertProps, getApiCodeAxiosError } from '../../utils/get-alert-props';
import { TRegistrationEmailReq, TRegistrationEmailResp } from './types';

export type TRegistrationEmailFormData = TRegistrationEmailReq;

const initData: TFormInitParams<TRegistrationEmailFormData> = {
  email: {
    value: '',
    validators: {
      change: emailAllowedSymbols.allow(''),
      blur: emailValidateRule.allow(''),
      submit: Joi.string().required(),
    },
  },
  // @ts-ignore
  captcha_token: {},
};

export class RegistrationEmailFormStore extends BasicFormStore<TRegistrationEmailFormData> {
  alertProps?: TAlertProps;
  needCaptcha = false;

  constructor() {
    super(initData);
    makeObservable<this>(this, {
      alertProps: observable,
      needCaptcha: observable,
      setCaptchaToken: action.bound,
      submit: action.bound,
      registration: action.bound,
      registrationSuccess: action.bound,
      registrationError: action.bound,
    });
  }

  setCaptchaToken = (token: string) => {
    // @ts-ignore
    this.data.captcha_token = token;
  };

  submit = this.onSubmit(() => {
    this.errors = this.validateAll();
    if (this.isValid) {
      this.registration(this.data);
    }
  });

  registration = async (data: TRegistrationEmailReq) => {
    this.execRequest<[TRegistrationEmailResp]>([httpClient.post(getApiUrl<paths>('/api/v1/user/registration'), data)])
      .then(this.registrationSuccess)
      .catch(this.registrationError);
  };

  registrationSuccess = ([resp]: [TRegistrationEmailResp]) => {
    if (resp.status === STATUS.SUCCESS) {
      navigateTo(`${routerPaths.unAuth.REGISTRATION_CHECK_MAIL}?email=${this.data.email}`);
    }
  };

  registrationError = (err: AxiosError<any>) => {
    const apiCode = getApiCodeAxiosError(err);
    if (apiCode === EApiCodes.NEED_CAPTCHA) {
      this.needCaptcha = true;
    } else {
      this.alertProps = getAlertProps(err);
    }
    if (err.response?.data.error.details?.next_request_allowed) {
      setTimeout(() => {
        this.alertProps = undefined;
        this.needCaptcha = false;
      }, timeToSendReq(err.response?.data.error.details?.next_request_allowed));
    }
  };
}
