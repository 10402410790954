import { GridApi } from 'ag-grid-community';
import {
  BidObjectType,
  LimitTypes,
  TCommonBudgetOptimizationLevel,
  TNewStrategyBid,
  TNewStrategyBids,
} from '../inputs/bids';
import { autobiddingMode, WhatIsEditedInStrategy } from './index';

export type TRows = Record<string, any>[];

export type TRow = Record<string, any>;

export type ProjectionPoint = { uniqs: number; share: number; price: number };

export type Currency = 'EUR' | 'RUB' | 'USD' | 'CNY';

export type ProjectionResp = {
  chart_data: ProjectionPoint[];
  /** Тип */
  bid_type: string;
  /** Точка для указанной цены */
  point: ProjectionPoint;
  /** Минимальный лимит */
  min: number;
  /** Максимальный лимит */
  max: number;
  /** Валюта аккаунта myTarget */
  currency: Currency;
  /** ID кампании */
  ad_group_id: number;
};

export type BidResp = {
  command_results?:
    | {
        status: 'new' | 'accepted' | 'in_progress' | 'done' | 'canceled';
        success?: boolean | undefined;
        command_id: string;
      }[]
    | undefined;
  errors?:
    | {
        obj_id: number;
        code: string;
        message: string;
        kwargs: {};
      }[]
    | undefined;
  command_batch_id?: string | undefined;
};

export type TGetProjection = (id: string | number | [], allPoints: boolean) => Promise<ProjectionResp>;

export type FixBidSaveActionProps = {
  name: 'FIX_BID';
  obj_type: BidObjectType;
  obj_id: number;
  network: string;
  bid: number;
}[];

export type StrategyBidSaveActionProps = {
  name: 'AUTOBIDDING_MODE';
  obj_type: BidObjectType;
  obj_id: number;
  network: string;
  autobidding_mode?: string;
  max_price?: number | null;
  price?: number | null;
  budget_limit: number;
  budget_limit_day: number;
  date_start: string;
  date_end: string;
}[];

export type StrategySaveAction = {
  requestData: StrategyBidSaveActionProps;
  itemsType: BidObjectType;
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel;
  newBidsData: TNewStrategyBids;
  gridApi: GridApi;
  whatIsEdited: WhatIsEditedInStrategy;
};

// TODO: мб получится избавиться от этого и взять строки из другого enum
export enum AdsReqItemType {
  adGroup = 'ad_group',
  adPlan = 'ad_plan',
  ad = 'ad',
} 

export type AvailableAdsProps = {
  ids: number[];
  itemsType: AdsReqItemType;
  setIsBlockedBudget?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type AvailableAdsItemResp = {
  _id: number;
  available_ads: number;
  package_limit_bid_min?: number;
  package_limit_bid_max?: number;
};

export type TCheckForManyRows = {
  prevBidsData: string;
  newBidsData: TNewStrategyBids;
  mode: string;
  initialMode: string;
  setBlockingMessage: (value: React.SetStateAction<string>) => void;
  setMaySave: (value: React.SetStateAction<boolean>) => void;
  minPriceLimit: number | undefined;
  strategyFormType: string;
  maxPriceLimit: number | undefined;
  commonDailyLimit: number | undefined;
  commonLifetimeLimit: number | undefined;
  limitType: LimitTypes;
  minBudgetLimit: number;
  itemsType: BidObjectType;
  newStartDateHasError: boolean | undefined;
  newEndDateHasError: boolean | undefined;
  errorPackageLimitBid: boolean;
};

export type TcheckForOneRow = Omit<TCheckForManyRows, 'prevBidsData' | 'itemsType'> & {
  budgetLimitsDisabled: boolean;
  selectedRows: TRows;
  startDateWasChanged: boolean | undefined;
  endDateWasChanged: boolean | undefined;
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel;
};

export type TcheckIfCanSave = TcheckForOneRow & TCheckForManyRows;

export type TgetRequestData = {
  strategyFormType: string;
  newData: TNewStrategyBid;
  itemsType: BidObjectType;
  mode: keyof typeof autobiddingMode | 'Минимальная цена';
  startDate: string;
  stopDate: string;
};

export type EditBidFormTypes =
  | 'FixedEditBid'
  | 'StrategyEditBid'
  | 'PlugAutoPrice'
  | 'PlugMaxShows'
  | 'PlugMarginalRate'
  | 'unknown';
