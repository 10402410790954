import { To, NavigateFunction } from 'react-router-dom';

let globalNavigate: NavigateFunction | undefined;

export const setGlobalNavigate = (navigateFn: NavigateFunction) => {
  globalNavigate = navigateFn;
};

export const navigateTo = (path: To | number, options?: { replace?: boolean }) => {
  if (globalNavigate) {
    if (typeof path === 'number') {
      globalNavigate(path as any);
    } else {
      globalNavigate(path, options);
    }
  }
};

export default navigateTo;
