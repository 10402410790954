import {
  BasicFormStore,
  EApiCodes,
  getApiUrl,
  httpClient,
  passwordAllowedSymbols,
  passwordValidateRule,
  TFormInitParams,
} from '@plarin/core';
import { TNewPasswordComplexityStyle } from '@plarin/core/src/types/kateTypesTemporary';
import { InputPasswordProps, TAlertProps } from '@plarin/inputs';
import { AxiosError, AxiosResponse } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { paths } from '../../../types/profile/apispec';
import { saveAuthTokens } from '../../components/app';
import { ProtectionImageProps } from '../../components/protection-image';
import { navigateTo } from '../../routing/navigation';
import { routerPaths } from '../../routing/router-path';
import { ProfileStore } from '../../stores/profile.store';
import { STORAGE_ITEM } from '../../utils/constants';
import { getAlertProps, getApiCodeAxiosError } from '../../utils/get-alert-props';
import {
  TActivatePasswordTokenReq,
  TActivatePasswordTokenResp,
  TNewPasswordReq,
  TNewPasswordResp,
  TPasswordCheckReq,
  TPasswordCheckResp,
  TPasswordComplexityRate,
  TResetPasswordReq,
  TResetPasswordResp,
} from './types';

export type TCreatePasswordPageData = {
  password: string;
  token?: string;
};

const initData: TFormInitParams<TCreatePasswordPageData> = {
  password: {
    value: '',
    validators: {
      change: passwordAllowedSymbols.allow(''),
      blur: passwordValidateRule.allow(''),
      submit: passwordValidateRule.required(),
    },
  },
};

export class CreatePasswordPageStore extends BasicFormStore<TCreatePasswordPageData> {
  isActivate = false;
  isLoading = false;
  alertProps?: TAlertProps;
  complexity?: TPasswordComplexityRate;
  imageStyle: ProtectionImageProps['style'] = 'default';
  passwordStyle?: InputPasswordProps['passwordComplexity'];
  token?: string;
  profileStore: ProfileStore;

  constructor(profileStore: ProfileStore) {
    super(initData);
    this.profileStore = profileStore;
    makeObservable<this>(this, {
      isActivate: observable,
      isLoading: observable,
      alertProps: observable,
      imageStyle: observable,
      passwordStyle: observable,
      token: observable,
      init: action.bound,
      submit: action.bound,
      checkPassword: action.bound,
      checkPasswordSuccess: action.bound,
      // newPasswordSuccess: action.bound,
      activatePasswordSuccess: action.bound,
      onError: action.bound,
      setPasswordStyle: observable,
    });

    this.init();
  }

  init = () => {
    const regToken = sessionStorage.getItem(STORAGE_ITEM.token.REGISTRATION);
    const forgotToken = sessionStorage.getItem(STORAGE_ITEM.token.FORGOT) && JSON.parse(sessionStorage.forgot_token);

    if (regToken) {
      this.token = regToken;
      this.isActivate = false;
    } else if (forgotToken) {
      this.activatePassword({ token: forgotToken.token });
      this.token = forgotToken.token;
    } else {
      navigateTo({
        pathname: routerPaths.unAuth.LOGIN,
        search: STORAGE_ITEM.token.ERROR_SEARCH,
      });
    }
  };

  setPasswordStyle = (style?: TNewPasswordComplexityStyle) => runInAction(() => (this.passwordStyle = style));

  checkPassword = async (data: TPasswordCheckReq) => {
    httpClient
      .post<TPasswordCheckResp>(getApiUrl<paths>('/api/v1/user/password/check'), data)
      .then(this.checkPasswordSuccess)
      .catch(this.onError);
  };

  checkPasswordSuccess = (resp: AxiosResponse<TPasswordCheckResp>) => {
    this.complexity = resp.data.data?.complexity;
    switch (this.complexity) {
      case 'medium':
        this.passwordStyle = 'yellow';
        this.imageStyle = 'default';
        break;
      case 'strong':
        this.passwordStyle = 'green';
        this.imageStyle = 'success';
        break;
      default:
        this.passwordStyle = 'red';
        this.imageStyle = 'default';
    }
  };

  newPassword = async (data: TNewPasswordReq) => {
    this.execRequest<[TNewPasswordResp]>([
      httpClient.post<TNewPasswordResp>(getApiUrl<paths>('/api/v1/user/password/new'), data),
    ])
      .then(resp => {
        this.isLoading = false;
        // @ts-ignore
        resp[0] && saveAuthTokens(resp[0].data.access_token, resp[0].data.refresh_token);
      })
      .then(() => this.profileStore.loadProfile())
      .catch(this.onError)
      .finally(() => {
        navigateTo(routerPaths.auth.ROOT);
      });
  };

  resetPassword = async (data: TResetPasswordReq) => {
    this.execRequest<[TResetPasswordResp]>([
      httpClient.post<TResetPasswordResp>(getApiUrl<paths>('/api/v1/user/password/reset'), data),
    ])
      .then(this.resetPasswordSuccess)
      .catch(this.onError);
  };

  submit = this.onSubmit(() => {
    this.errors = this.validateAll();
    if (this.isValid) {
      const { password } = this.data;
      if (this.isActivate && this.token) {
        this.resetPassword({
          token: this.token,
          password,
        });
        sessionStorage.removeItem(STORAGE_ITEM.token.FORGOT);
      } else if (this.token) {
        this.newPassword({
          token: this.token,
          password,
        });
        sessionStorage.removeItem(STORAGE_ITEM.token.REGISTRATION);
      }
    }
  });

  resetPasswordSuccess = ([resp]: [TResetPasswordResp]) => {
    saveAuthTokens(resp.data!.access_token, resp.data!.refresh_token, resp.data!.refresh_expires);
    localStorage.setItem('expires', moment(resp.data!.refresh_expires).unix().toString());
    navigateTo(routerPaths.auth.ROOT);
    this.profileStore.loadProfile();
  };

  activatePassword = async (data: TActivatePasswordTokenReq) => {
    this.execRequest<[TActivatePasswordTokenResp]>([
      httpClient.post<TActivatePasswordTokenResp>(getApiUrl<paths>('/api/v1/user/password/activate'), data),
    ])
      .then(this.activatePasswordSuccess)
      .catch(this.onError);
  };

  activatePasswordSuccess = () => {
    this.isActivate = true;
    this.isLoading = false;
  };

  onError = (err: AxiosError<any>) => {
    const apiCode = getApiCodeAxiosError(err);
    if (
      apiCode === EApiCodes.TOKEN_NOT_FOUND ||
      apiCode === EApiCodes.VALIDATION_ERROR ||
      apiCode === EApiCodes.ALREADY_USED
    ) {
      navigateTo({
        pathname: routerPaths.unAuth.LOGIN,
        search: STORAGE_ITEM.token.ERROR_SEARCH,
      });
    } else {
      this.alertProps = getAlertProps(err);
    }
  };
}
