import { Typography } from '@plarin/design';
import React, { useState, useEffect } from 'react';
import { MtOptimisationLevel, BidObjectType, BidObjectTypeEnum as ObjType, getItemsType } from '../../inputs/bids/utils';
import { TRows } from '../types';
import classes from './style.module.scss';
import { filteredOption, TCurrencyFiltrator } from './utils';

export const OptimizationLevelFiltrator = ({ filteredRows, deselectRows }: TCurrencyFiltrator) => {
  const [adPlan, setAdPlan] = useState<TRows>([]);
  const [adGroup, setAdGroup] = useState<TRows>([]);
  const [unknown, setUnknown] = useState<TRows>([]);
  const [currentTab] = useState<BidObjectType>(getItemsType(window.location.pathname));

  useEffect(() => {
    const adPlanArr: TRows = [],
      adGroupArr: TRows = [],
      unknownArr: TRows = [];

    filteredRows.forEach(row => {
      if (row.budgetOptimizationLevel === MtOptimisationLevel.adPlan) {
        adPlanArr.push(row);
      } else if (row.budgetOptimizationLevel === MtOptimisationLevel.adGroup) {
        adGroupArr.push(row);
      } else {
        unknownArr.push(row);
      }
    });

    adPlanArr.length && setAdPlan(adPlanArr);
    adGroupArr.length && setAdGroup(adGroupArr);
    unknownArr.length && setUnknown(unknownArr);
  }, []);

  return (
    <div className={classes.filtratorWrap}>
      <Typography size="AuthContent" weight={700} component="p" componentProps={{ className: classes.mb12 }}>
        Массовое изменение стратегии
      </Typography>
      <Typography component="p" size="AuthContent" componentProps={{ className: classes.mb12 }}>
        Для продолжения изменения стратегий оставьте только кампании или группы.
      </Typography>
      <Typography component="p" size="AuthContent">
        Продолжить изменять:
      </Typography>

      <div>
        {filteredOption({ val: adPlan, message: 'Кампании ', deselectRows: deselectRows })}
        {filteredOption({
          val: adGroup,
          message: 'Группы ',
          deselectRows: deselectRows,
          isActive: currentTab !== ObjType.ad_plan,
        })}

        {filteredOption({
          val: unknown,
          message: 'Неизвестный уровень оптимизации бюджета',
          deselectRows: deselectRows,
          isActive: false,
        })}
      </div>
    </div>
  );
};
