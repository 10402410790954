import { ObjectValue, typografText } from '@plarin/utils';
import React from 'react';
import { TYdObjType } from '../../types/manage-yd/types';
import { TMetric, TObjType, TYDMetric } from '../../types/metrics';
import { SupportIntercomLink } from '../components/support-link/support-intercom-link';

export const FAST_STAT = {
  AdGroupId: 'ad_group_id',
  SatisticsFast1: 'satistics_fast',
  SatisticsFast72: 'satistics_fast72',
  Faststat: 'fastStat',
  Faststat72: 'fastStat72',
  DateFrom: 'satistics_fast_dt_from',
  DateFrom72: 'satistics_fast72_dt_from',
} as const;

export const FAST_STAT_IDS = {
  account: 'account_id',
  ad_plan: 'ad_plan_id',
  ad_group: 'ad_group_id',
  ad: 'ad_id',
} as const;

export const METRICS_GROUP_PATHS = {
  STATISTIC: 'statistics',
};

export const METRIC_METRIC_PATH = {
  ACCOUNT_NAME: 'account_name',
} as const;

export const ADS = {
  ALL: 'ads_all',
  ACTIVE: 'ads_active',
  PAUSED: 'ads_paused',
  ARCHIVED: 'ads_archived',
} as const;

export const STATUS = {
  STATUS: 'status',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  DISABLED: 'disabled',
  BLOCKED: 'blocked',
  DELETED: 'deleted',
  ENABLED: 'enabled',
  ACTIVE: 'active',
  LOADING: 'loading',
} as const;

export const TABS = {
  EMAIL: 'email',
} as const;

export const STORAGE_ITEM = {
  token: {
    BASE: 'token',
    REGISTRATION: 'reg_token',
    FORGOT: 'forgot_token',
    ERROR_SEARCH: '?error=FORGOT_TOKEN_NOT_FOUND',
  },
  localStorage: {
    METRICSVK: 'checkedMetricsVK',
    METRICSYD: 'checkedMetricsYD',
    LAST_UPDATE: 'lastUpdate',
    REMAIN_SYSTEM: 'remainSystem',
    SELECTED_IDS: 'selectedIds',
    LOCAL_DATES: 'localDates',
    FILTER_STASUSES: 'filterStatuses',
    EXPIRES: 'expires',
  },
} as const;

export const SOCIAL_NAME = {
  GOOGLE: 'g',
  VKONTAKTE: 'vk',
} as const;

export type SocialNameType = ObjectValue<typeof SOCIAL_NAME>;

export enum ManageVkNameCellsEnum {
  'accountName',
  'adPlanName',
  'adGroupName',
  'adName',
}

export enum ManageYDNameCellsEnum {
  'accountName',
  'groupName',
  'campaignName',
  'adId',
}

export const ignoredVKGroupingMetrics: Record<TObjType, TMetric['path'][]> = {
  account: ['account_id', 'account_name', 'account_username'],
  ad_plan: ['ad_plan_id', 'issues', 'ad_plan_name'],
  ad_group: ['ad_group_id', 'issues'],
  banner: ['ad_name', 'issues'],
};

export const ignoredYDGroupingMetrics: Record<TYdObjType, TYDMetric['path'][]> = {
  account: [],
  ad_group: ['ad_group_id', 'issues', 'ad_group_name'],
  campaign: ['campaign_id', 'issues'],
  ad: ['ad_id', 'issues'],
};

export const PREVIEW = {
  SMALL: 'previewSmall',
} as const;

export const API_URL_ROOT_DEV = 'ussr-api.plarin.ru';

export const API_URL_ROOT_PROD = 'api-gateway.plarin.ru';

export const ymDisableClass = 'ym-disable-keys';

export const menuSectionIndexes = {
  projectsOverview: 1,
  manage: 2,
  reports: 3,
  ws: 4,
  integrations: 5,
};

export const DownloadExcelError = () => {
  return (
    <p>
      {typografText('Произошла ошибка при создании файла с отчетом. Попробуйте еще раз или обратитесь в')}{' '}
      <SupportIntercomLink>{typografText('поддержку')}</SupportIntercomLink>.
    </p>
  );
};
