import { TObjType } from '../../types/metrics';

export enum MTManageVkStatusEnum {
  active = 'Активная',
  blocked = 'Остановлена',
  deleted = 'Архивная',
}

export const FastStatTypes = {
  ACCOUNT: 'account',
  ADPLAN: 'ad_plan',
  AD_GROUPS: 'ad_group',
  ADS: 'ad',
} as const;

export const mainTabData: Record<TObjType, string[]> = {
  account: ['accountName', 'accountUserName'],
  ad_plan: ['adPlanName', 'adPlanId'],
  ad_group: ['adGroupName', 'adGroupId'],
  banner: ['adName', 'adId'],
};
