import { Page } from '@plarin/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAppDataStore } from '../../components/app-data-provider';
import { AuthConfirmPhoneForm } from '../../components/auth/auth-confirm-phone-form';
import {
  AuthFormContainerSocialButtonsTemplate,
  AuthFormContainerTemplate,
} from '../../components/auth/auth-form-template';
import { AuthLoginPageTemplate } from '../../components/auth/auth-page-template';
import { AuthTabsTemplate } from '../../components/auth/auth-tabs-template';
import { TABS } from '../../utils/constants';
import { LoginEmailForm } from './login-email-form';
import { LoginPageStore } from './login-page.store';
import { LoginPhoneForm } from './login-phone-form';

const LoginPage: React.FC = observer(() => {
  const { profileStore, alertProps: appAlertProps, resetAlert } = useAppDataStore();
  const [pageStore] = useState(() => new LoginPageStore(profileStore));
  const { phoneFormStore, emailFormStore, alertProps, tabs, selectTab, activeTab, getSocialUrl } = pageStore;
  const { boot } = useIntercom();

  useEffect(() => {}, [tabs, emailFormStore.isPending, phoneFormStore.isPending]);
  useEffect(() => {
    setTimeout(
      () =>
        boot({
          hideDefaultLauncher: false,
        }),
      0,
    );
  }, [boot]);

  return (
    <Page title="Вход">
      <AuthLoginPageTemplate>
        {phoneFormStore.needConfirm ? (
          <AuthFormContainerTemplate title="Подтвердите вход">
            <AuthConfirmPhoneForm store={phoneFormStore} />
          </AuthFormContainerTemplate>
        ) : (
          <AuthFormContainerSocialButtonsTemplate
            title="Вход"
            onSocialButtonClick={getSocialUrl}
            alertProps={appAlertProps || alertProps || emailFormStore.alertProps || phoneFormStore.alertProps}
            disabled={emailFormStore.isPending || phoneFormStore.isPending}
          >
            <>
              <AuthTabsTemplate
                tabs={tabs}
                tab={activeTab}
                onTabChange={() => {
                  selectTab(activeTab);
                  resetAlert();
                }}
              >
                {tab =>
                  tab === TABS.EMAIL ? (
                    <LoginEmailForm store={emailFormStore} />
                  ) : (
                    <LoginPhoneForm store={phoneFormStore} />
                  )
                }
              </AuthTabsTemplate>
            </>
          </AuthFormContainerSocialButtonsTemplate>
        )}
      </AuthLoginPageTemplate>
    </Page>
  );
});

export default LoginPage;
