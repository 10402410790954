import { Typography } from '@plarin/design';
import { Modal, ModalActionNamesEnum } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import React from 'react';
import classes from './style.module.scss';

export const ConnectionsPro = ({
  firstAction,
  secondAction,
  onCloseModal,
}: {
  firstAction: () => void;
  secondAction: () => void;
  onCloseModal: () => void;
}) => {
  return (
    <Modal
      width={384}
      open={true}
      closeModal={onCloseModal}
      classNameIcon={classes.iconPro}
      title="Подключение"
      secondAction={{
        actionTitle: ModalActionNamesEnum.Cancel,
        action: firstAction,
      }}
      firstAction={{
        actionTitle: ModalActionNamesEnum.Continue,
        action: secondAction,
      }}
    >
      <Typography size="AuthContent">
        {typografText('При подключении Яндекс Директ обратите внимание, что ваш аккаунт должен быть')}
        <Typography component="span" weight={600}>
          {' '}
          Яндекс Про
        </Typography>
        {typografText(', иначе статистика не будет отображаться.')}
      </Typography>
    </Modal>
  );
};
