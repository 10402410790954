import { Typography } from '@plarin/design';
import { HintPopup } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import classes from './style.module.scss';

export const BadgePro = observer(() => {
  return (
    <HintPopup
      isHeaderBox
      isShowIcon={false}
      marginTop={6}
      saveZoneEvent
      propsNameError="Не подключен Директ Про"
      triggerIcon={
        <Typography
          size="SmallText"
          component="div"
          align="center"
          componentProps={{ className: classes.nameNotPro }}
        />
      }
      textBody="Данные невозможно загрузить, необходимо подключить Яндекс Про."
    />
  );
});
