import Joi from '@hapi/joi';
import { getApiUrl, httpClient, phoneAllowedSymbols, phoneValidateRule, TFormInitParams } from '@plarin/core';
import { action, makeObservable } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { saveAuthTokens } from '../../components/app';
import { AConfirmPhoneStore } from '../../components/auth/auth-confirm-phone-form/confirm-phone.store';
import { navigateTo } from '../../routing/navigation';
import { routerPaths } from '../../routing/router-path';
import { ProfileStore } from '../../stores/profile.store';
import { STATUS, STORAGE_ITEM } from '../../utils/constants';
import { TLoginPhoneCodeReq, TLoginPhoneCodeResp, TLoginPhoneReq, TLoginPhoneResp } from './types';

export type TLoginPhoneFormData = TLoginPhoneReq & TLoginPhoneCodeReq;

const initData: TFormInitParams<TLoginPhoneFormData> = {
  phone: {
    validators: {
      change: phoneAllowedSymbols.allow(''),
      blur: phoneValidateRule.allow(''),
      submit: Joi.string().required(),
    },
  },
  code: {
    validators: {
      blur: Joi.string().required(),
      submit: Joi.string().required(),
    },
  },
  // @ts-ignore
  captcha_token: {},
};

export class LoginPhoneFormStore extends AConfirmPhoneStore {
  profileStore: ProfileStore;
  retryCodeToken?: string;

  constructor(profileStore: ProfileStore) {
    super(initData);
    this.profileStore = profileStore;
    makeObservable<this>(this, {
      submit: action.bound,
      onSendCode: action.bound,
      login: action.bound,
      loginSuccess: action.bound,
      phoneCode: action.bound,
      phoneCodeSuccess: action.bound,
    });
  }

  onSendCode = () => {
    this.login(this.data);
  };

  submit = this.onSubmit(() => {
    this.errors = this.validateAll('phone');
    this.phoneCode(this.data);
  });

  login = async (data: TLoginPhoneReq) => {
    this.execRequest<[TLoginPhoneResp]>([
      httpClient.post(getApiUrl<paths>('/api/v1/user/auth/login/phone'), { phone: `+${data.phone}`, code: data.code }),
    ])
      .then(this.loginSuccess)
      .catch(this.onError);
  };

  loginSuccess = ([resp]: [TLoginPhoneResp]) => {
    localStorage.setItem(STORAGE_ITEM.localStorage.REMAIN_SYSTEM, 'false');
    localStorage.setItem(STORAGE_ITEM.localStorage.LAST_UPDATE, Date.now().toString());
    saveAuthTokens(resp.data!.access_token, resp.data!.refresh_token, resp.data!.refresh_expires);
    this.profileStore.loadProfileWithCheckInvites().finally(() => {
      navigateTo(routerPaths.auth.ROOT);
    });
  };

  phoneCode = async (data: TLoginPhoneCodeReq) => {
    this.execRequest<[TLoginPhoneResp]>([
      httpClient.post(getApiUrl<paths>('/api/v1/user/auth/login/phone/code'), { phone: `+${data.phone}` }),
    ])
      .then(this.phoneCodeSuccess)
      .catch(this.onError);
  };

  phoneCodeSuccess = (resp: any) => {
    if (resp.status === STATUS.SUCCESS) {
      this.needConfirm = true;
      this.error = undefined;
      this.alertProps = undefined;
      // нет типизации на бэкенде
      this.retryCodeToken = resp.data!.token as string;
    }
  };

  retryCode = async () =>
    httpClient.post(getApiUrl<paths>('/api/v1/user/auth/login/phone/resend_code'), { token: this.retryCodeToken });
}
