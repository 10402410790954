import { ActionBar, GridApi, IRowNode, Table } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateOptions } from '../../../../../types/common-types';
import { MetricRecord } from '../../../../../types/manage-vk/types';
import { TMetric } from '../../../../../types/metrics';
import { useAppDataStore } from '../../../../components/app-data-provider';
import {
  dynamicColumnsRenderer,
  getVkSelectionColumnDef,
} from '../../../../components/manage-vk-custom-columns/dynamic-column-renderer';
import { FastStatTypes } from '../../../../dictionary/manage-vk';
import { FAST_STAT_IDS } from '../../../../utils/constants';
import { getColumnsForTable } from '../../../../utils/manage-vk';
import { useFilter } from '../utils/quickFilterSwitcher';
import { useGetStatTable } from '../utils/useGetStatTable';
import { useGroupingSelect } from '../utils/useGrouping';
import { ClientsActionBar } from './clients-action-bar';

export const ClientsTab = observer(() => {
  const {
    manageVK: {
      tableLoading,
      filterValue,
      data,
      getSelectedIds,
      setSelectedIds,
      getStat,
      setDeleteAction,
      errorTable,
      date,
      setCurrentTab,
      dictionary,
      metricsVK,
      getFastStat,
      fast72Checked,
      isGroupDestroyed,
      setIsGroupDestroyed,
      quantitySelected,
    },
    profileStore: { wsDictionary },
  } = useAppDataStore();
  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [loading, setLoading] = useState(true);
  const [names, setNames] = useState<TMetric[]>([]);

  const { storeLabel, storeStartDate, storeEndDate } = date as unknown as DateOptions;
  const isGroupingActive = !!data?.length && data[0].hasOwnProperty('orgHierarchy');

  const getStatUp = useGetStatTable(ManageVkTabNameEnum.ACCOUNTS);
  const handleBodyScroll = (nodeRendered: IRowNode<any>[], api: GridApi<any>) => {
    // без этого условия не отображается заглушка, если таблица пустая (PYT-7010)
    if (api && api?.getDisplayedRowCount() !== 0) {
      getFastStat(api, nodeRendered, fast72Checked, FastStatTypes.ACCOUNT, FAST_STAT_IDS.account);
    }
  };
  // Получаем фаст стату при раскрытии вложенных строк
  const onRowGroupOpened = useCallback(() => {
    if (gridApi && data?.length) {
      const nodes = gridApi.getRenderedNodes();
      if (nodes && nodes.length) {
        getFastStat(gridApi, nodes, fast72Checked, FastStatTypes.ACCOUNT, FAST_STAT_IDS.account);
      }
    }
  }, [gridApi, data, tableLoading]);

  useEffect(() => {
    getStatUp().then(() => {
      setLoading(false);
    });
  }, [
    storeLabel,
    storeStartDate,
    storeEndDate,
    quantitySelected.selectedAdPlans,
    quantitySelected.selectedAdGroups,
    quantitySelected.selectedAds,
  ]);

  useEffect(() => {
    setCurrentTab(ManageVkTabNameEnum.ACCOUNTS);
    setDeleteAction(() => {
      gridApi.deselectAll();
    });
  }, [setCurrentTab, gridApi, setDeleteAction]);

  useEffect(() => {
    data?.length &&
      dictionary &&
      metricsVK &&
      setNames(getColumnsForTable(Object.keys(data[0]), dictionary, metricsVK, fast72Checked));
  }, [data, dictionary, metricsVK]);

  useFilter(filterValue, gridApi);
  useGroupingSelect(gridApi, isGroupDestroyed, setIsGroupDestroyed, getSelectedIds, ManageVkTabNameEnum.ACCOUNTS, data);

  return (
    <>
      <ActionBar>
        <ClientsActionBar />
      </ActionBar>

      <Table<MetricRecord>
        showStatusBar
        columns={useMemo(
          () => dynamicColumnsRenderer(names, ManageVkTabNameEnum.ACCOUNTS, 'accountName', wsDictionary),
          [names],
        )}
        rowData={data || []}
        onGridReady={setGridApi}
        getSelectedIds={getSelectedIds}
        setSelectedKeys={setSelectedIds}
        tabName={ManageVkTabNameEnum.ACCOUNTS}
        ActionError={() => getStat(ManageVkTabNameEnum.ACCOUNTS, wsDictionary)}
        errorTable={errorTable}
        setDeleteAction={setDeleteAction}
        onBodyScroll={handleBodyScroll}
        isTableLoading={loading || tableLoading}
        isGroupDestroyed={isGroupDestroyed}
        setGroupDestroyed={setIsGroupDestroyed}
        onRowGroupOpened={onRowGroupOpened}
        selectionColumnDef={getVkSelectionColumnDef(isGroupingActive)}
      />
    </>
  );
});
