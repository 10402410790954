import { observer } from 'mobx-react';
import { useAppDataStore } from '../components/app-data-provider';
import { Notification } from '../components/notification';
import { SuggestIdeaDrawer } from '../components/suggest-idea';

export const NotificationRenders = observer(() => {
  const {
    profileStore: { notifications: profileNotif, deleteNotification: delNotifProfile },
    manageYD: { notifications: manageYDNotif, deleteNotification: delNotifmanageYD },
    integrationsVK: { notifications: integraVKNotif, deleteNotification: delNotifIntegraVK },
    manageVK: { notifications: manageVKNotif, deleteNotification: delNotifmanageVK },
    reports: { notifications: reportNotif, deleteNotification: delReportNotif },
    projects: { notifications: projectNotif, deleteNotification: delProjectsNotif },
    members: { notifications: wSNotif, deleteNotification: delWSNotif },
    teams: { notifications: teamsNotif, deleteNotification: delTeamsNotif },
    integrationsYM: { notifications: integraYMNotif, deleteNotification: delNotifIntegraYM },
    integrationsGA: { notifications: integraGANotif, deleteNotification: delNotifIntegraGA },
    integrationsYD: { notifications: integraYDNotif, deleteNotification: delNotifIntegraYD },
    integrationsAF: { notifications: integraAFNotif, deleteNotification: delNotifIntegraAF },
    deleteNotification,
    notifications,
  } = useAppDataStore();
  return (
    <>
      <SuggestIdeaDrawer />
      <Notification
        notifications={
          (notifications.length && notifications) ||
          (profileNotif.length && profileNotif) ||
          (integraVKNotif.length && integraVKNotif) ||
          (manageYDNotif.length && manageYDNotif) ||
          (manageVKNotif.length && manageVKNotif) ||
          (reportNotif.length && reportNotif) ||
          (wSNotif.length && wSNotif) ||
          (projectNotif.length && projectNotif) ||
          (teamsNotif.length && teamsNotif) ||
          (integraYMNotif.length && integraYMNotif) ||
          (integraGANotif.length && integraGANotif) ||
          (integraYDNotif.length && integraYDNotif) ||
          (integraAFNotif.length && integraAFNotif) ||
          []
        }
        deleteNotification={
          (notifications.length && deleteNotification) ||
          (profileNotif.length && delNotifProfile) ||
          (manageYDNotif.length && delNotifmanageYD) ||
          (integraVKNotif.length && delNotifIntegraVK) ||
          (manageVKNotif.length && delNotifmanageVK) ||
          (reportNotif.length && delReportNotif) ||
          (projectNotif.length && delProjectsNotif) ||
          (wSNotif.length && delWSNotif) ||
          (teamsNotif.length && delTeamsNotif) ||
          (integraYMNotif.length && delNotifIntegraYM) ||
          (integraGANotif.length && delNotifIntegraGA) ||
          (integraYDNotif.length && delNotifIntegraYD) ||
          (integraAFNotif.length && delNotifIntegraAF) ||
          deleteNotification
        }
      />
    </>
  );
});
