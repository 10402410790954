import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDataStore } from '../../components/app-data-provider';
import { navigateTo } from '../../routing/navigation';
import { SpmSpsStatTable } from './components/cpm-cpc-stat';
import { InstallationStatTable } from './components/installations-stat';
import { MembersStatTable } from './components/members-stat-table';
import { SpentStatTable } from './components/spent-stat';
import TabsLayout from './components/tabs-layout';

const ProjectOverview = observer(() => {
  const { id = '' } = useParams<{ id: string }>();

  const {
    profileStore: {
      workspaceShortname,
      wsDictionary: { projects },
    },
  } = useAppDataStore();

  useEffect(() => {
    // если такого проекта нет - редирект на список воркспейсов
    !Object.keys(projects).includes(id) && navigateTo(`/${workspaceShortname}/projects`);
  }, []);

  return (
    <TabsLayout>
      <SpentStatTable project_id={id} />
      <SpmSpsStatTable project_id={id} />
      <InstallationStatTable project_id={id} />
      <MembersStatTable project_id={id} />
    </TabsLayout>
  );
});

export default ProjectOverview;
