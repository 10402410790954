import { GridApi, Lockscreen, SearchDefaultInput, Table, StatusPanelDef } from '@plarin/inputs';
import { RowClickedEvent } from 'ag-grid-community';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import { СommonStatusPanel, CommonStatusPanelTypes } from '../../components/common-status-panel/common-status-panel';
import { WsEmptyTableOverlay } from '../../components/ws-table-overlay/ws-table-overlay';
import { sortRow } from '../../utils/common';
import { useFilter } from '../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { ActionBar, ColumnsMembers, EditMemberDrawer, MemberEditModals } from './components';
import classes from './style.module.scss';

const Members = observer(() => {
  const {
    members: {
      searchTableMember,
      setSearchTableMember,
      members,
      setRowCountMembers,
      invites,
      isTableLoading,
      getWSMembers,
      isLoading,
      setMembers,
      setUserId,
      hasLoadingError,
      idAddedMember,
      setIdAddedMember,
    },
    teams: { getTeams },
  } = useAppDataStore();

  const [blockEnabled, setBlockEnabled] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);

  const rowData = blockEnabled
    ? // @ts-ignore
      [...members, ...invites].sort((a, b) => sortRow(a.fname, b.fname))
    : [...members.filter(el => el.status !== 'blocked'), ...invites].sort((a, b) =>
        // @ts-ignore
        sortRow(a.fname, b.fname),
      );

  const onRowClicked = useCallback((event: RowClickedEvent) => {
    event.data._id && setUserId(event.data._id);
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: СommonStatusPanel,
          align: 'left',
          statusPanelParams: { type: CommonStatusPanelTypes.members },
        },
      ],
    };
  }, []);

  useEffect(() => {
    setRowCountMembers(rowData.length);
  }, [rowData]);

  useEffect(() => {
    getWSMembers().then();
    getTeams().then();
    return () => {
      setMembers([]);
      setUserId('');
      setIdAddedMember('');
    };
  }, []);

  useFilter(searchTableMember, gridApi, rowData);

  return (
    <>
      <SearchDefaultInput
        placeholder="Поиск"
        filterValue={searchTableMember}
        setFilter={setSearchTableMember}
        searchIconColor="main"
        inputStyle="tableSearch"
        className={classes.search}
      />
      <div
        className={clsx(
          classes.tableContent,
          classes.fixFlexColumns,
          (hasLoadingError || !members.length) && classes.emptyTable,
        )}
      >
        <ActionBar blockEnabled={blockEnabled} setBlockEnabled={setBlockEnabled} />

        <Table
          columns={ColumnsMembers}
          statusBar={members.length && statusBar}
          rowData={rowData}
          isRowGroup
          onGridReady={setGridApi}
          isTableLoading={isTableLoading}
          showSkeletonForLonger={true}
          onRowClicked={onRowClicked}
          needAutoHeight
          agNoRowsOverlay={() =>
            WsEmptyTableOverlay({
              type: 'members',
              hasLoadingError,
              hasAdminRights: false,
              openDrawer: () => null,
              archived: 0,
              completed: 0,
            })
          }
          spaceAboveTable={254}
          extraFooterHeight={40}
          getRowClass={el => (el?.data?.hash === idAddedMember ? 'ag-row-selected' : undefined)}
        />
      </div>

      <EditMemberDrawer />
      <MemberEditModals />
      <Lockscreen visible={isLoading} />
    </>
  );
});

export default Members;
