import { Preloader } from '@plarin/design';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { MainTooltip } from '../tooltip';
import { IconButton } from './icon-button';
import classes from './icon-button.module.scss';

type ReloadButtonProps = {
  action: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  className?: string;
  icon: JSX.Element;
  textTooltip: JSX.Element | string;
  placement?: 'top' | 'bottom';
  active?: boolean;
  isReloadButton?: boolean;
  isButtonDisabled?: boolean;
  badge?: string;
  component?: 'span' | 'div';
};

export const ActionTableButton = ({
  action,
  loading,
  className,
  textTooltip,
  icon,
  placement = 'top',
  active,
  isReloadButton,
  isButtonDisabled,
  badge,
  component = 'span',
}: ReloadButtonProps) => {
  const isMac = navigator.userAgent.indexOf('Mac OS X') !== -1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const keyModifierText = isMac ? (isSafari ? 'CTRL' : 'CMD') : 'CTRL';

  const handleKeyBindings = (event: KeyboardEvent) => {
    const key = event.key?.toLowerCase();
    const ctrlOrMeta = isMac ? (isSafari ? event.ctrlKey : event.metaKey) : event.ctrlKey;
    if (isReloadButton && ctrlOrMeta && (key === 'r' || key === 'к')) {
      event.preventDefault();
      action();
    }
  };

  const counterBadge = Number(badge) ? (Number(badge) <= 99 ? Number(badge) : '99+') : '';
  const growBadge = counterBadge === '99+';

  const reloadTooltipText = (
    <div>
      Обновить
      <br />({keyModifierText} + R)
    </div>
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyBindings);
    return () => {
      document.removeEventListener('keydown', handleKeyBindings);
    };
  }, []);

  return (
    <MainTooltip
      isTypograf={false}
      tooltipMessage={isReloadButton ? reloadTooltipText : textTooltip}
      isVisible={!loading}
      component={component}
      placement={placement}
    >
      <IconButton
        onClick={action}
        className={clsx(className, loading && classes.buttonWidth)}
        disabled={loading || isButtonDisabled}
        active={active}
      >
        {loading ? <Preloader size={16} thickness={3} /> : icon}
        {badge && <div className={clsx(classes.iconBadge, growBadge && classes.growBadge)}>{counterBadge}</div>}
      </IconButton>
    </MainTooltip>
  );
};
