import { Typography } from '@plarin/design';
import { currency, useIntersection } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { EllipsisTooltip } from '../../../../tooltip';
import { ProjectionPoint } from '../../../types';
import classes from '../style.module.scss';
import { Coverage } from './coverage';
import { RowInfoProps } from './types';

export const RowInfoItem = ({
  name,
  bid,
  curr,
  id,
  bidValue,
  removeItem,
  projectionData,
  getProjection,
}: RowInfoProps) => {
  const { loading, error, projection, fullfiled } = projectionData;

  const [currentRate, setCurrentRate] = useState<ProjectionPoint>({
    uniqs: 0,
    share: 0,
    price: bid,
  });
  const [newRate, setNewRate] = useState<ProjectionPoint>();

  const element = useRef<HTMLInputElement | null>(null);
  const intersection = useIntersection(element, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const uniqs = newRate?.uniqs ? newRate?.uniqs : currentRate?.uniqs;
  const share = newRate?.share ? newRate?.share : currentRate?.share;

  const addNewBidChart = () => {
    if (!projection.chart_data) {
      if (!bidValue) {
        setNewRate(currentRate);
      } else {
        setNewRate({
          price: bidValue,
          uniqs: 0,
          share: 0,
        });
      }
    }
    if (projection && projection.chart_data) {
      const data = projection.chart_data;
      const diffArr = data.map(x => bidValue - x.price);

      if (!bidValue) {
        setNewRate(currentRate);
      } else if (projection.point.price === bidValue) {
        setNewRate({
          price: projection.point.price,
          uniqs: projection.point.uniqs,
          share: projection.point.share,
        });
      } else if (data.some(point => point.price === bidValue)) {
        const index = data.findIndex(point => point.price === bidValue);
        setNewRate({
          price: bidValue,
          uniqs: data[index].uniqs,
          share: data[index].share,
        });
      } else {
        const index = diffArr.findIndex(x => x <= 0) - 1;
        const lastIndex = index + 1;

        const uniqs = Math.round(
          ((bidValue - data[index].price) * (data[lastIndex].uniqs - data[index].uniqs)) /
            (data[lastIndex].price - data[index].price) +
            data[index].uniqs,
        );
        const proc = Math.round(
          ((bidValue - data[index].price) * (data[lastIndex].share - data[index].share)) /
            (data[lastIndex].price - data[index].price) +
            data[index].share,
        );
        const newPoint = {
          price: bidValue,
          uniqs: uniqs,
          share: proc,
        };

        setNewRate(newPoint);
      }
    }
  };

  useLayoutEffect(() => {
    if (intersection && intersection.intersectionRatio >= 1 && !projection.point && !error) {
      loading && !fullfiled && getProjection(id);
    }
  }, [intersection, loading, fullfiled, error]);

  useEffect(() => {
    bidValue !== -1 && addNewBidChart();
  }, [bidValue, projection.point]);

  useEffect(() => {
    projection.point && setCurrentRate(projection.point);
  }, [projection.point]);

  return (
    <div className={clsx(classes.rowInfo)} ref={element}>
      <EllipsisTooltip classNameContainer={classes.itemCol} tooltipMessage={name}>
        <Typography size="AuthContent" ellips={true}>
          {name}
        </Typography>
      </EllipsisTooltip>

      <div className={classes.bidCol}>
        <Typography size="AuthContent">
          {currency(newRate && bidValue !== -1 ? newRate.price : currentRate.price, curr)}
        </Typography>
      </div>
      <Coverage loading={loading} error={error} share={share} uniqs={uniqs} removeItem={removeItem} id={id} />
    </div>
  );
};
