import { BasicStore, httpClient2 } from '@plarin/core';
import { GridApi } from '@plarin/inputs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  GADisableReq,
  GAEnableReq,
  GARemoveReq,
  GAResponse,
  ModalDataGA,
  TDataGoalsGA,
  TGAConnectResp,
  TGAProfileSettingsGoals,
  TGAProfileSettingsGoalsUpdate,
  TGAStoreData,
  TEnableGA,
  TIntegrationChange,
} from '../../types/connect-ga/types';
import { routerPaths } from '../routing/router-path';
import { STATUS } from '../utils/constants';
import {
  getAccountsIntegrationsStatus,
  getAccountsItemsSyncStatus,
  getChildsReqObject,
} from '../utils/integration-converters';

const emptyModalData: ModalDataGA = {
  type: '',
  names: [],
  selectedRows: [],
  gridApi: undefined,
};

export class IntegrationsGAStore extends BasicStore {
  data: TGAStoreData[] = [];
  dataGoalsGA: TDataGoalsGA | null = null;
  tableLoading: boolean = false;
  tableError: boolean = false;
  modalData: ModalDataGA = { ...emptyModalData } as ModalDataGA;
  isLoading: boolean = false;
  searchTableGa: string = '';
  isOpenGoalsDrawerGA: boolean = false;
  isLoadingPostGoalsGA: boolean = false;
  constructor() {
    super();
    makeObservable<this>(this, {
      isOpenGoalsDrawerGA: observable,
      data: observable,
      dataGoalsGA: observable,
      tableLoading: observable,
      tableError: observable,
      modalData: observable,
      isLoading: observable,
      connectGoogleAccount: action.bound,
      searchTableGa: observable,
      isLoadingPostGoalsGA: observable,
    });
  }

  setTableLoading = (value: boolean) => runInAction(() => (this.tableLoading = value));
  setTableError = (value: boolean) => runInAction(() => (this.tableError = value));
  setSearchTableGa = (value: string) => runInAction(() => (this.searchTableGa = value));

  setIsOpenGoalsDrawerGA = (open: boolean) => runInAction(() => (this.isOpenGoalsDrawerGA = open));
  setIsLoadingPostGoalsGA = (loading: boolean) => runInAction(() => (this.isLoadingPostGoalsGA = loading));
  setModalData = (data: ModalDataGA) => runInAction(() => (this.modalData = data));
  private setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  clearModalData = () => this.setModalData({ ...emptyModalData });

  getData = async () => {
    this.setTableLoading(true);
    this.setTableError(false);

    setTimeout(async () => {
      await this.execRequest<GAResponse[]>(httpClient2.get('/api/v1/connect/ga/integration/list'))
        .then(res =>
          runInAction(
            () =>
              (this.data = res.reduce((accumulator: TGAStoreData[], account) => {
                // дети внутри аккаунта
                if (account.items.length) {
                  account.items.forEach(item => {
                    accumulator.push({
                      ...item,
                      key: item.header2,
                      _id: account._id,
                      _parent_status: account.plarin_status,
                      orgHierarchy: [account.header1, item.header2],
                      syncStatusCode: item.issues.length ? STATUS.BLOCKED : item.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        item.plarin_status,
                        item.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      // у детей также отображаем статус интеграции
                      integrationStatus: getAccountsIntegrationsStatus(
                        item.plarin_status,
                        item.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      integrationStatusCode: item.issues?.length ? STATUS.BLOCKED : item.plarin_status,
                    });
                  });
                }

                // аккаунт
                accumulator.push({
                  ...account,
                  key: account._id,
                  isParent: true,
                  integrationStatus: getAccountsIntegrationsStatus(
                    account.plarin_status,
                    account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                });
                return accumulator;
              }, [])),
          ),
        )
        .catch(e => {
          this.setTableError(!!e);
          this.setTableLoading(false);
        })
        .finally(() => this.setTableLoading(false));
    }, 500);
  };

  connectGoogleAccount = async (workspace: string) => {
    await this.execRequest<TGAConnectResp>(
      httpClient2.get('/api/v1/connect/ga/url', {
        params: {
          url: `${window.location.origin}/${workspace}${routerPaths.integrations.GOOGLE_ANALYTICS}`,
        },
      }),
    )
      .then(res =>
        runInAction(() => {
          if (res.url) {
            window.location.href = res.url;
          }
        }),
      )
      .catch(() => {});
  };
  checkQueryString = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const error = urlSearchParams.get('error');
    const errorDescription = urlSearchParams.get('error_description');

    if (error) {
      this.addNotification({
        type: STATUS.ERROR,
        title: error,
        message: errorDescription ? errorDescription : '',
      });
    }
    if (urlSearchParams.get('ga_account_id')) {
      window.history.replaceState(null, '', window.location.pathname);
      this.addNotification({
        type: STATUS.SUCCESS,
        title: `Аккаунт ${urlSearchParams.get('ga_account_id')} успешно подключен`,
      });
    }
  };

  enableGA: TEnableGA = async ({ parentRows, childRows, gridApi }) => {
    const reqBody: TIntegrationChange = {
      db_ids: parentRows.map(row => row._id),
      profile_ids: getChildsReqObject(childRows),
    };

    const parentIds = parentRows.map(row => row._id);
    const childIds = childRows.map(row => row.id);

    runInAction(
      () =>
        (this.data = this.data.map(row => {
          // это родитель
          if (row.isParent && parentIds.includes(row._id)) {
            return { ...row, integrationStatusCode: 'loading' };
          }
          // если это чайлд: отображаем лоадер одиночно редактируемым чайлдам
          // но также отображает лоадер детям редактируемых аккаунтов, если аккаунт редактируется
          if ((!row.isParent && parentIds.includes(row._id)) || childIds.includes(row.id)) {
            return {
              ...row,
              syncStatusCode: 'loading',
              integrationStatusCode: 'loading',
            };
          }
          return row;
        })),
    );

    setTimeout(async () => {
      await this.execRequest<GAResponse[]>(httpClient2.post('/api/v1/connect/ga/integration/enable', reqBody))
        .then(res => {
          let newData = [...this.data];

          res.forEach(resAccount => {
            newData = newData.map(tableRow => {
              // Это родитель
              if (tableRow._id === resAccount._id && tableRow.isParent) {
                return {
                  ...tableRow,
                  plarin_status: resAccount.plarin_status,
                  integrationStatus: getAccountsIntegrationsStatus(
                    resAccount.plarin_status,
                    resAccount.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: resAccount.issues.length ? STATUS.BLOCKED : resAccount.plarin_status,
                };
              } else {
                // это чайлд
                const child = resAccount.items?.find(app => app.id === tableRow.id);
                if (child) {
                  return {
                    ...tableRow,
                    _parent_status: resAccount.plarin_status,
                    plarin_status: child.plarin_status,
                    integrationStatus: getAccountsIntegrationsStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,
                    syncStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }
                return tableRow;
              }
            });
          });

          runInAction(() => (this.data = [...newData]));

          this.addNotification({
            type: STATUS.SUCCESS,
            title:
              parentRows.length + childRows.length === 1
                ? 'Интеграция успешно включена'
                : 'Интеграции успешно включены',
          });
        })

        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  disableGA = async ({
    parentRows,
    childRows,
    gridApi,
  }: {
    parentRows: TGAStoreData[];
    childRows: TGAStoreData[];
    gridApi?: GridApi;
  }) => {
    this.setIsLoading(true);

    const reqBody: TIntegrationChange = {
      db_ids: parentRows.map(row => row._id),
      profile_ids: getChildsReqObject(childRows),
    };

    setTimeout(async () => {
      await this.execRequest<GAResponse[]>(httpClient2.post('/api/v1/connect/ga/integration/disable', reqBody))
        .then(res => {
          let newData = [...this.data];

          res.forEach(resAccount => {
            newData = newData.map(tableRow => {
              // Это родитель
              if (tableRow._id === resAccount._id && tableRow.isParent) {
                return {
                  ...tableRow,
                  plarin_status: resAccount.plarin_status,
                  integrationStatus: getAccountsIntegrationsStatus(
                    resAccount.plarin_status,
                    resAccount.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: resAccount.issues.length ? STATUS.BLOCKED : resAccount.plarin_status,
                };
              } else {
                // это чайлд
                const child = resAccount.items?.find(app => app.id === tableRow.id);

                if (child) {
                  return {
                    ...tableRow,
                    _parent_status: resAccount.plarin_status,
                    plarin_status: child.plarin_status,
                    integrationStatus: getAccountsIntegrationsStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,

                    syncStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }
                return tableRow;
              }
            });
          });

          runInAction(() => (this.data = [...newData]));

          this.addNotification({
            type: STATUS.SUCCESS,
            title:
              parentRows.length + childRows.length === 1
                ? 'Интеграция успешно выключена'
                : 'Интеграции успешно выключены',
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.clearModalData();
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  removeGA = async (data: GARemoveReq) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<string[]>(httpClient2.post('/api/v1/connect/ga/integration/disconnect', data))
        .then(res => {
          const removeNames = this.data
            .filter(el => el.isParent && data.db_ids.includes(el._id))
            .map(el => el.header1)
            .join(', ');
          runInAction(() => {
            this.data = this.data.filter(el => !data.db_ids.includes(el._id));
          });
          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? `Аккаунт ${removeNames} отключен` : `Аккаунты ${removeNames} отключены`,
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.clearModalData();
          this.setIsLoading(false);
        });
    }, 500);
  };

  getGoalsGA = async ({ gaUserId, profileId }: { gaUserId: string; profileId: string }) => {
    this.setIsOpenGoalsDrawerGA(true);
    this.setIsLoading(true);
    this.execRequest<TGAProfileSettingsGoals>(
      httpClient2.get('/api/v1/connect/ga/profile_settings', {
        params: {
          ga_user_id: gaUserId,
          profile_id: profileId,
        },
      }),
    )
      .then(res => {
        runInAction(() => {
          this.dataGoalsGA = { ...res, gaUserId, profileId };
        });
        this.setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        this.addNotification({
          type: STATUS.ERROR,
          title: error,
        });
        this.setIsOpenGoalsDrawerGA(false);
      });
  };

  postGoalsGA = async (data: TGAProfileSettingsGoalsUpdate) => {
    this.setIsLoadingPostGoalsGA(true);
    await this.execRequest<TGAProfileSettingsGoalsUpdate>(httpClient2.post('/api/v1/connect/ga/profile_settings', data))
      .then(res => {
        runInAction(() => {
          this.data = this.data?.map(el => {
            if (el.id === data.profile_id) {
              return { ...el, goals: data.enabled_goals.length };
            }
            return el;
          });
        });
        this.addNotification({
          type: STATUS.SUCCESS,
          title: 'Цели изменены',
        });
      })
      .catch(error => {
        console.log(error);
        this.addNotification({
          type: STATUS.ERROR,
          title: error,
        });
      })
      .finally(() => {
        this.setIsOpenGoalsDrawerGA(false);
      });
  };
}
