import { SettingsIcon } from '@plarin/design';
import { Popover } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

interface ISettingsMenu {
  settingMenu: { name: string; action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => void }[];
  onClose: (e: React.MouseEvent | Event) => void;
  anchorEl: HTMLDivElement | null;
  onOpen: (e: MouseEvent, ref: HTMLDivElement) => void;
}

export const SettingsMenu = ({ onOpen, anchorEl, onClose, settingMenu }: ISettingsMenu) => {
  return (
    <>
      <div
        ref={ref => {
          if (!ref) return;
          ref.onclick = e => onOpen(e, ref);
        }}
        className={clsx(classes.settingsIcon, !!anchorEl && classes.forceVisibility)}
      >
        <SettingsIcon size={16} color="secondary" />
      </div>
      <Popover anchorEl={anchorEl} isOpen={!!anchorEl} setIsOpen={onClose} dataList={settingMenu} />
    </>
  );
};
