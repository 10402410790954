import { Popover, GridApi, TRows, IRowNode, getGridApi, ActionTableButton } from '@plarin/inputs';
import { ManageVkTabNameEnum, sendMetricGoal } from '@plarin/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { MTStatus } from '../../../types/manage-vk/types';
import { returnId } from '../../utils/manage-vk';
import { useAppDataStore } from '../app-data-provider';
import { ActionKeysEnum, EditListEnum } from './commonEnums';
import { EditBid } from './edit-bid';
import { EditMenuLayout } from './edit-menu-layout';
import { editMenuList, TEditMenuList } from './edit-menu-list';
import classes from './style.module.scss';

type ManageVkEditMenuProps = {
  disabled?: boolean;
  gridApi: GridApi;
  tabName: ManageVkTabNameEnum;
  selectedGridRows: number[];
};

// вычисляем, сколько выбрано строк в таблице, кроме строк с определённым ненужным нам статусом
const notArchivedAmount = (selectedRows: TRows) => {
  const num = selectedRows.filter(row => !row.status?.includes('Архивн')).length;
  return num ? `${num}` : '';
};

const editRenderer = (
  visionBox: string,
  close: () => void,
  selectedRows: TRows,
  gridApi: GridApi,
  deselectRows: (keysToKeep: any[]) => void,
) => {
  switch (visionBox) {
    case EditListEnum.bid:
      return <EditBid close={close} selectedRows={selectedRows} gridApi={gridApi} deselectRows={deselectRows} />;
    // TODO: когда появятся новые пункты в меню, появятся новые кейсы в свитче
    default:
      return <></>;
  }
};

export const ManageVkEditMenu = ({ disabled, gridApi, tabName, selectedGridRows }: ManageVkEditMenuProps) => {
  const {
    manageVK: { changeManageVkStatus },
  } = useAppDataStore();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRows, setSelectedRows] = useState<TRows>([]);

  const updateSelectedRows = () => {
    getGridApi(gridApi) && setSelectedRows(gridApi.getSelectedRows().filter(el => !el.isGroupRow));
  };

  useEffect(() => {
    if (getGridApi(gridApi)) {
      gridApi?.addEventListener('rowDataUpdated', updateSelectedRows);
      return () => {
        gridApi?.removeEventListener('rowDataUpdated', updateSelectedRows);
      };
    }
  }, [gridApi]);

  useEffect(() => {
    updateSelectedRows();
  }, [selectedGridRows]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const idsRowsByStatus = useCallback(
    (status: MTStatus) => {
      switch (status) {
        case 'active':
          return selectedRows.filter(row => row.status.includes('Останов')).map(row => row[returnId(tabName)]);
        case 'blocked':
          return selectedRows.filter(row => row.status.includes('Актив')).map(row => row[returnId(tabName)]);
        case 'deleted':
          return selectedRows
            .filter(row => row.status.includes('Актив') || row.status.includes('Останов'))
            .map(row => row[returnId(tabName)]);
        case 'recovery':
          return selectedRows.filter(row => row.status.includes('Архив')).map(row => row[returnId(tabName)]);
      }
    },
    [selectedRows],
  );

  const changeStatus = (status: MTStatus, gridApi: GridApi) => {
    const ids = idsRowsByStatus(status).map(el => +el);
    const newStatus = status === 'recovery' ? 'blocked' : status;
    changeManageVkStatus(ids, newStatus, tabName, gridApi).then();
    handleClose();
  };

  const getAmountByStatus = (status?: MTStatus) => {
    const amount = status ? idsRowsByStatus(status).length : selectedRows?.length;

    return amount ? `${amount}` : '';
  };

  // снимает галочку у строк в таблице, кроме тех, чьи ключи переданы в качестве аргумента функции
  const deselectRows = (keysToKeep: any[]) => {
    const nodesToDeselect: IRowNode[] = [];

    // находим те строки таблицы, у которых нужно снять галочку. Для этого мы вынуждены сначала получить вообще все строки из таблицы строго методом forEachNodeAfterFilter
    gridApi.forEachNodeAfterFilter(node => {
      // @ts-ignore берём те строки, которые выделены галочкой и не являются строкой группировки
      if (node.selected && !node.allChildrenCount) {
        const keepThisNode = keysToKeep.some(keepKey => String(keepKey) === node.key);

        if (node.key && !keepThisNode) {
          nodesToDeselect.push(node);
        }
      }
    });

    // в таблице снимаем галочку у отфильтрованных строк
    nodesToDeselect.length && gridApi.setNodesSelected({ nodes: nodesToDeselect, newValue: false });

    // в текущем компоненте обновляем список строк таблицы с галочкой
    setTimeout(() => gridApi && setSelectedRows(gridApi.getSelectedRows().filter(el => !el.isGroupRow)), 100);
  };

  const getAmountBadge = (item: TEditMenuList[0]) => {
    if (item.key === ActionKeysEnum.bid) {
      return notArchivedAmount(selectedRows);
    }
    return getAmountByStatus(item.status);
  };

  const buttonIconList = editMenuList.map(el => {
    return {
      ...el,
      badge: getAmountBadge(el),
      tooltipText: `${el.label} ${getAmountBadge(el) ? `(${getAmountBadge(el)})` : ''}`,
      action: () => {
        el.status && changeStatus(el.status, gridApi);
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('usage_vk_massaction_change_status', 'manage/vk');
      },
      isDisabled:
        (!el.status &&
          ((selectedRows.length === 1 && selectedRows[0].status?.includes('Архивн')) ||
            selectedRows.length - selectedRows.filter(val => val.status?.includes('Архивн')).length === 0)) ||
        (el.status && !idsRowsByStatus(el.status).length) ||
        disabled,
    };
  });

  return (
    <>
      {buttonIconList.map(el => {
        if (el.typeEdit === EditListEnum.bid) {
          return (
            <div key={el.key}>
              <ActionTableButton
                component="div"
                isButtonDisabled={el.isDisabled}
                badge={el.badge}
                textTooltip={el.tooltipText}
                action={ev => {
                  ev && handleOpen(ev);
                }}
                icon={el.icon}
              />
              <Popover
                classNameWrapper={classes.popoverWrapper}
                zIndex={1299}
                setIsOpen={handleClose}
                anchorEl={anchorEl}
                isOpen={!!anchorEl}
              >
                <EditMenuLayout visionBox={EditListEnum.bid}>
                  {editRenderer(
                    EditListEnum.bid,
                    handleClose,
                    selectedRows.filter(row => !row.status?.includes('Архивн')),
                    gridApi,
                    deselectRows,
                  )}
                </EditMenuLayout>
              </Popover>
            </div>
          );
        }
        return (
          <ActionTableButton
            component="div"
            isButtonDisabled={el.isDisabled}
            key={el.key}
            badge={el.badge}
            textTooltip={el.tooltipText}
            action={el.action}
            icon={el.icon}
          />
        );
      })}
    </>
  );
};
