import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { TAccountWorkspaces, WorkspaceResp } from '../../types/profile/types';
import { InviteData } from '../../types/profile/types';
import { navigateTo } from '../routing/navigation';
import { routerPaths } from '../routing/router-path';

interface IuseRedirectRoutes {
  currentWorkspace: WorkspaceResp;
}

interface IuseRedirectRoot {
  isRootRedirect?: boolean;
  wsUrlPart: string;
  invites?: InviteData[];
  workspaceShortname: string;
  selectWs?: string;
  setIsRootRedirect: (e: boolean) => void;
  workspaces: TAccountWorkspaces[];
}

interface IuseRedirectPageTitle {
  workspaceShortname: string;
  setLocatedInProfile: (value: React.SetStateAction<boolean>) => void;
}

export const useRedirectRoutes = ({ currentWorkspace }: IuseRedirectRoutes) => {
  useEffect(() => {
    if (!currentWorkspace._id) {
      navigateTo(routerPaths.auth.ROOT);
    }
  }, [currentWorkspace]);
};

export const useRedirectRoot = ({
  isRootRedirect,
  wsUrlPart,
  invites,
  workspaceShortname,
  selectWs,
  setIsRootRedirect,
  workspaces,
}: IuseRedirectRoot) => {
  useEffect(() => {
    if (isRootRedirect) {
      setIsRootRedirect(false);
      !invites?.length && navigateTo(`/${workspaceShortname}`);
    } else {
      !wsUrlPart && workspaceShortname && navigateTo(`/${workspaceShortname}`);
      workspaces.length === 1 &&
        wsUrlPart !== 'wslist' &&
        wsUrlPart !== 'profile' &&
        wsUrlPart &&
        navigateTo(`/${workspaces[0]?.shortname}`);
    }
  }, []);

  useEffect(() => {
    if (wsUrlPart === 'wslist' && !selectWs) {
      navigateTo(routerPaths.auth.ROOT);
    }
  }, [wsUrlPart, selectWs, navigateTo]);
};

export const useRedirectPageTitle = ({ setLocatedInProfile, workspaceShortname }: IuseRedirectPageTitle) => {
  const location = useLocation();
  useEffect(() => {
    const urlWorkspace = window.location.pathname;
    let urlPath = '';
    urlWorkspace.split('/').forEach((el, index) => {
      index === 1 && (urlPath = decodeURIComponent(el));
    });

    setLocatedInProfile(urlWorkspace === routerPaths.auth.PROFILE || urlWorkspace === routerPaths.auth.WSLIST);
    if (
      urlWorkspace !== routerPaths.auth.PROFILE &&
      urlWorkspace !== routerPaths.auth.WSLIST &&
      !!urlPath &&
      !!workspaceShortname &&
      urlPath !== workspaceShortname
    ) {
      navigateTo(
        `${urlWorkspace
          .split('/')
          .map((el, index) => (index === 1 ? workspaceShortname : el))
          .join('/')}`,
      );
    }
  }, [location]);
};
