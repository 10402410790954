import { Text, Typography } from '@plarin/design';
import React from 'react';
import { Link } from 'react-router-dom';
import { routerPaths } from '../../../routing/router-path';
import { useAppDataStore } from '../../app-data-provider';
import { AuthContentTemplate } from './auth-content-template';

interface AuthContentProps {
  children: React.ReactNode;
}

export const AuthContentRegTemplate: React.FC<AuthContentProps> = ({ children }) => {
  const { resetAlert } = useAppDataStore();

  return AuthContentTemplate({
    children,
    footer: (
      <Text color="textTertiary" size="s">
        Уже зарегистрированы?{' '}
        <Typography
          underline
          color="linkPrimary"
          component={Link}
          componentProps={{ to: routerPaths.unAuth.LOGIN, onClick: resetAlert }}
        >
          Войти
        </Typography>
      </Text>
    ),
  });
};
