import { Typography } from '@plarin/design';
import { CustomSelect, Popover } from '@plarin/inputs';
import { AlertIntegrations } from '@plarin/inputs/src/alerts/alert-integrations';
import { typografText } from '@plarin/utils';
import React, { useEffect, useRef, useState } from 'react';
import { TConnectFormData } from '../../../../types/connect-af/types';
import { TextArrowButton } from '../../../components/text-arrow-button';
import { ConnectionError } from './ConnectionError';
import { ConnectionInputs } from './ConnectionInputs';
import classes from './style.module.scss';
import { TYPE_CONNECTION_AF } from './utils';

interface ITypeConnection {
  data: { name: string; token: string };
  isLoading: boolean;
  updateData: (key: keyof TConnectFormData, value: string) => void;
  isConnectFailed: boolean;
  connectionError: string;
}

export const TypeConnection = ({ data, isLoading, updateData, isConnectFailed, connectionError }: ITypeConnection) => {
  const [typeConnection, setTypeConnection] = useState('placeholder');
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    isConnectFailed &&
      scrollRef.current &&
      (scrollRef.current as HTMLDivElement)?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  }, [isConnectFailed]);

  return (
    <div className={classes.scrollBox}>
      <div ref={scrollRef} className={classes.children}>
        {isConnectFailed && <ConnectionError errorCode={connectionError} />}
        <Typography
          color="TextPrimary"
          size="AuthContent"
          align="left"
          component="div"
          componentProps={{ className: classes.typeName }}
        >
          Выберите тип подключения:
        </Typography>
        <CustomSelect
          value={typeConnection}
          onChange={el => {
            setTypeConnection(el);
            setOpenAlert(el === 'agency');
            updateData('type', el);
          }}
          listVariants={[
            { value: 'advertiser', label: TYPE_CONNECTION_AF.advertiser },
            { value: 'agency', label: TYPE_CONNECTION_AF.agency },
            { value: 'placeholder', label: 'Тип подключения' },
          ]}
          menuHeight={144}
          className={classes.mr24}
          placeholderClassName={typeConnection === 'placeholder' ? classes.placeholderClassName : ''}
        />

        {typeConnection !== 'placeholder' && (
          <>
            {typeConnection === 'agency' && isOpenAlert && (
              <AlertIntegrations
                className={classes.alertWarning}
                severity="warning"
                closeAlert={() => setOpenAlert(false)}
              >
                <Typography componentProps={{ className: classes.mr4 }} weight={700} component="div" size="AuthContent">
                  Внимание
                </Typography>
                {typografText(
                  'Ключи типа Агентства к Платформе подключаются корректно, однако, в связи с ограничениями и архитектурой API Appsflyer, отсутствует возможность получить список приложений Агентства. Свяжитесь с Технической поддержкой и мы поможем добавить приложения вручную.',
                )}
              </AlertIntegrations>
            )}

            <ConnectionInputs data={data} isLoading={isLoading} updateData={updateData} />

            <div ref={anchorRef} className={classes.divider}>
              <TextArrowButton onClickButton={() => setOpen(!isOpen)} isOpen={isOpen}>
                Инструкция для подключения интеграции
              </TextArrowButton>
            </div>
            <Popover
              marginTop={-8}
              isOpenTop
              zIndex={1302}
              setIsOpen={() => {
                setOpen(false);
              }}
              onEscape={() => {
                setOpen(false);
              }}
              anchorEl={anchorRef.current}
              isOpen={isOpen}
            >
              <div className={classes.listWrapper}>
                <Typography size="AuthContent">
                  {typografText('1) В интерфейсе Appsflyer откройте')}{' '}
                  <span className={classes.textAccent}>{typografText('меню пользователя')}</span>{' '}
                  {typografText(' (раскрывающийся список под адресом электронной почты в верхнем правом углу);')}
                </Typography>
                <Typography size="AuthContent">
                  {typografText('2) Выберите ')}{' '}
                  <span className={classes.textAccent}>{typografText('«Центр безопасности»')}</span>;
                </Typography>
                <Typography size="AuthContent">
                  {typografText('3) В разделе ')}{' '}
                  <span className={classes.textAccent}>{typografText('«Токены API AppsFlyer»')}</span>{' '}
                  {typografText(' нажмите ')}{' '}
                  <span className={classes.textAccent}>{typografText('«Управление токенами API AppsFlyer»')}</span>;
                </Typography>
                <Typography size="AuthContent">{typografText('4) Отобразится доступный токен (V2.0);')}</Typography>
                <Typography size="AuthContent">
                  {typografText('5) Скопируйте необходимый токен и вставьте в текстовое поле ниже.')} <br />
                </Typography>
              </div>
            </Popover>
            <Typography component="p" size="Caption" color="TextSecondary">
              <span className={classes.textGray}>{typografText('Внимание:')}</span>{' '}
              {typografText('получить Токен API может только администратор в аккаунте Appsflyer.')}{' '}
              <a
                href="https://support.appsflyer.com/hc/ru/articles/360004562377-Управление-токенами-API#retrieving-api-tokens"
                target="_blank"
                className={classes.link}
                rel="noreferrer"
              >
                Подробнее
              </a>
              .
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
