import {
  ManageVkTabNameEnum,
  IntegrationsTabNameEnum,
  useInterval,
  useTabVisibility,
  ManageYDTabNameEnum,
  getSafeParsedLocalStorage,
  CurrencyEnum,
} from '@plarin/utils';
import {
  BodyScrollEvent,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowClickedEvent,
  RowHeightParams,
  SelectionChangedEvent,
  SelectionColumnDef,
  RowClassParams,
  RowStyle,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { ModelUpdatedEvent, RowGroupOpenedEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Lockscreen } from '../lockscreen';
import { SkeletonTable } from '../preloader';
import {
  AdsSumRenderer,
  BidCellRenderer,
  BidRenderer,
  BudgetRenderer,
  BudgetRendererYd,
  CircleAvatarNameRenderer,
  CustomFooterRenderer,
  DefaultAvgRenderer,
  DefaultSumRenderer,
  DefaultTooltipCell,
  GroupNameRenderer,
  HeaderNameRenderer,
  LinkCellRenderer,
  MemberRender,
  NameCountRenderer,
  NameEmailUser,
  NameRenderer,
  PercentsRenderer,
  PeriodRenderer,
  PreloaderRenderer,
  AvatarsInRowCell,
  ProjectsRenderer,
  RangeDateRenderer,
  RightsRenderer,
  SimpleCircleAvatarNameRenderer,
  SquareAvatarNameRenderer,
  StatusRenderer,
  TeamMembersRenderer,
  TranslationRenderer,
  TranslationRendererYd,
  TwoLinesRenderer,
  GoalsRenderer,
  IntegrationStatusRenderer,
  FakeGroupCellRenderer,
} from './cell-renderers';
import { FastStatRenderer } from './cell-renderers/fast-stat';
import { handleKeyBindings } from './checkboxUtils';
import { comparator } from './comparator';
import { ErrorTable } from './error-table/ErrorTable';
import { EmptyDataAfterFilter } from './error-table/variants';
import classNames from './table.module.scss';
import {
  applyColumnState,
  customSelectNodes,
  saveColumnState,
  useAutoHeightFix,
  isNotResizable,
  toggleClassFillHeightScroll,
  getGridApi,
  saveIsColumnMoved,
  getIsColumnMoved,
} from './utils';

export interface Column extends ColDef {}

interface TableProps<T> {
  rowData: T[];
  columns: Column[];
  onGridReady?: (gridApi: GridApi) => void;
  showStatusBar?: boolean;
  statusBar?: any;
  onRowClicked?: (e: RowClickedEvent) => void;
  isRowGroup?: boolean;

  onBodyScroll?(nodeRendered: IRowNode[], api: GridApi): void;
  onBodyScrollFastStat?(nodeRendered: IRowNode[], api: GridApi): void;

  ActionError?: () => Promise<void>;
  errorTable?: boolean;
  colDef?: ColDef;
  selectedRowKeys?: number[];
  getSelectedIds?: (tabName: any) => number[];
  tabName?: ManageVkTabNameEnum | ManageYDTabNameEnum | IntegrationsTabNameEnum;
  setSelectedKeys?: (ids: number[], tabName: any) => void;
  setLeftNavigationIds?: (selectedRows: T[], tabName: ManageVkTabNameEnum) => void;
  setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
  setDeleteAction?: (action?: () => void) => void;
  // emptyDataComponent?: JSX.Element | React.ReactNode;
  isTableLoading: boolean;
  columnState?: ColumnState[];
  saveColumnState?: (columnState: ColumnState[]) => void;
  isGroupDestroyed?: boolean;
  setGroupDestroyed?: (value: boolean) => void;
  needAutoHeight?: boolean;
  noRowsContent?: () => JSX.Element;
  agNoRowsOverlay?: () => JSX.Element;
  getRowClass?: (e: RowClassParams<any, any>) => string | string[] | undefined;
  groupDefaultExpanded?: number;
  extraFooterHeight?: number;
  spaceAboveTable?: number;
  disableContainersHeight?: boolean;
  onRowGroupOpened?: (event: RowGroupOpenedEvent) => void;
  selectionColumnDef?: SelectionColumnDef;
  showSkeletonForLonger?: boolean; // выключает загрузочный скелетон после того как таблица отрендерится, вычислит ширину колонок, отрендерит футер и приведёт себя в божеский вид. Эта фича нужна таблицам с колонками на флексах и автовысотой
}

const defColDef: ColDef = {
  sortable: true,
  sortingOrder: ['desc', 'asc', null],
  minWidth: 75,
  width: 150,
  comparator,
  resizable: true,
  cellDataType: false,
};

const defaultSpaceAboveTable = 250;

export const Table = <RD,>({
  rowData = [],
  columns = [],
  onGridReady,
  showStatusBar,
  statusBar,
  onBodyScroll,
  onBodyScrollFastStat,
  ActionError,
  errorTable,
  selectedRowKeys,
  getSelectedIds,
  tabName,
  setSelectedKeys,
  setLeftNavigationIds,
  setSelectedRows,
  isTableLoading,
  isGroupDestroyed,
  needAutoHeight,
  agNoRowsOverlay,
  noRowsContent,
  onRowClicked,
  groupDefaultExpanded = 0,
  extraFooterHeight = 0,
  disableContainersHeight = false,
  spaceAboveTable = defaultSpaceAboveTable,
  onRowGroupOpened,
  selectionColumnDef, // настройки колонки с чекбоксами
  getRowClass,
  showSkeletonForLonger,
}: TableProps<RD>) => {
  const gridRef = useRef<AgGridReact>(null);
  const [apiGrid, setApiGrid] = useState<GridApi<any> | null>(null);
  const [showTable, setShowTable] = useState(false);
  const [showSkeletonLayout, setShowSkeletonLayout] = useState(false);
  const href = window.location.href;
  const columnState = getSafeParsedLocalStorage<Record<string, ColumnState[]>>('columns'); // Указываем, что это объект с ключами-строками

  // определяем высоту всех видимых строк таблицы + футер
  const needHeight = () => {
    const allRowsHeight = document.querySelector('.ag-center-cols-container')?.clientHeight || 1;
    return allRowsHeight + extraFooterHeight;
  };

  // определяет, сколько свободного места есть таблицы
  const currentFreeSpace = () => {
    const pageDomElement = document.querySelector('.autoHeightAnchor');
    return pageDomElement ? pageDomElement!.getBoundingClientRect().height - spaceAboveTable : 0;
  };

  // Применяем эту функцию при фильтрации таблицы и при изменении ее размера.
  // Позволяет использовать горизонтальный скролл по белому пространству над скроллбаром.
  const setFillHeightScroll = (api: GridApi) => {
    toggleClassFillHeightScroll(classNames.fillHeightScroll, needAutoHeight);
  };

  // для того что бы при отрисовке таблицы, не было мигания во время построения колонок
  const upDefaultColumnsFromLocalStorage = () => {
    const isColumnsMoved = getIsColumnMoved(href); // Указываем, что это объект с ключами-строками

    let columnStateLS: ColumnState[] = [];
    if (columnState) {
      // Проверяем, существует ли элемент с индексом href
      if (columnState[href] !== undefined) {
        columnStateLS = columnState[href];
      }
    }

    const updatedColumns = columns.map(column => {
      const matchingColumnState = columnStateLS.find(columnLS => column.field === columnLS.colId);
      if (matchingColumnState) {
        // Если колонка есть в columnStateLS, объединяем данные
        return {
          ...column,
          width: matchingColumnState.width,
          pinned: matchingColumnState.pinned,
          sort: matchingColumnState.sort,
        };
      }
      return column; // Если данных в columnStateLS нет, возвращаем колонку как есть
    });

    // какие колонки добавить в конце таблицы если колонки двигали
    const addColumn = columns.filter(column => {
      return !columnStateLS.filter(columnLS => {
        return column.field === columnLS.colId;
      }).length;
    });

    if (isColumnsMoved) {
      return [
        ...columnStateLS?.map((columnLS: ColumnState) => {
          return {
            ...columns.filter(column => {
              return column.field === columnLS.colId;
            })[0],
            width: columnLS.width,
            pinned: columnLS.pinned,
            sort: columnLS.sort,
          };
        }),
        ...addColumn,
      ].filter(el => el.field);
    } else {
      return updatedColumns;
    }
  };

  const isManageAds =
    tabName === ManageVkTabNameEnum.ACCOUNTS ||
    tabName === ManageVkTabNameEnum.ADPLANS ||
    tabName === ManageVkTabNameEnum.ADS ||
    tabName === ManageVkTabNameEnum.ADGROUPS ||
    tabName === ManageYDTabNameEnum.ACCOUNTS ||
    tabName === ManageYDTabNameEnum.GROUPS ||
    tabName === ManageYDTabNameEnum.ADS ||
    tabName === ManageYDTabNameEnum.CAMPAIGNS;

  const onGridReadyWrapper = ({ api }: GridReadyEvent) => {
    api && onGridReady && onGridReady(api);
    setApiGrid(api);

    const headerViewport = document.querySelector('.ag-header-viewport ');
    const headerContainer = document.querySelector('.ag-header-container');
    // setTimeout нужен чтоб при переключении табов успевала отработать фильтрация
    setTimeout(() => {
      if (api && getGridApi(api)) {
        const nodes = api && api?.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
        onBodyScroll && onBodyScroll(nodes, api);
      }
    }, 100);

    if (
      headerContainer &&
      headerViewport &&
      headerContainer.clientWidth < headerViewport.clientWidth &&
      !isNotResizable(columns)
    ) {
      api.sizeColumnsToFit();
    }

    applyColumnState(api, tabName);

    toggleClassFillHeightScroll(classNames.fillHeightScroll, needAutoHeight);

    // без этой строчки таблица рендерится в нормальном режиме, а через секунду переключается на автовысоту, если соблюдены условия, при которых должна быть автовысота. Чтобы таблица не "прыгала" видимо для пользователя, вызываем checkDomLayout в этом месте
    needAutoHeight && gridRef.current?.api && checkDomLayout(gridRef.current?.api);

    // nodes.length === 0 ? api.showNoRowsOverlay() : api.hideOverlay();
  };

  const onFirstDataRendered = ({ api }: FirstDataRenderedEvent) => {
    if (isManageAds && tabName && getSelectedIds) {
      customSelectNodes(getSelectedIds(tabName), false, api);
    } else {
      customSelectNodes(selectedRowKeys, false, api);
    }
  };

  const multiFooterCount = () => {
    const currencySums: Record<string, number> = {} as Record<CurrencyEnum, number>;

    rowData.map(data => {
      // @ts-ignore
      const currency = (data?.accountCurrency || data.currency) as CurrencyEnum;
      // @ts-ignore

      const value = data?.statisticsSpent;
      // @ts-ignore
      if (value || (!data.isGroupRow && !data.orgHierarchy) || (data.parentId && !data.orgHierarchy)) {
        if (currency) {
          if (!currencySums[currency]) {
            currencySums[currency] = 0;
          }
          currencySums[currency] += +value || 0;
        }
      }
    });

    return Object.values(currencySums).length;
  };

  // Настройка высоты строк
  const getRowHeight = (params: RowHeightParams) => {
    // высота футера мне мультивалюте
    if (params.node.rowPinned && multiFooterCount() > 2) {
      return 64;
    } else {
      return 40;
    }
  };

  const onSelectionWrapper = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows().filter(el => !el.isGroupRow);
    const isGroupingEnabled = selectedRows.length && selectedRows[0].hasOwnProperty('orgHierarchy');

    // если в таблице есть группировка и родитель выбран, то добавим его в список вырбанных елементов таблицы
    if (isGroupingEnabled) {
      event.api.getBestCostNodeSelection()?.forEach(el => selectedRows.push(el?.data));
    }

    // При выборе чекбокса передаем данные строки для навигации справа налево
    if (tabName && Object.values(ManageVkTabNameEnum).includes(tabName as ManageVkTabNameEnum)) {
      setLeftNavigationIds && setLeftNavigationIds(selectedRows, tabName as ManageVkTabNameEnum);
    }

    if (!isGroupDestroyed && isManageAds && setSelectedKeys && tabName) {
      setSelectedKeys(
        selectedRows.reduce((acc: number[], row) => {
          if (isGroupingEnabled) {
            row.hasOwnProperty('orgHierarchy') && row.orgHierarchy && acc.push(row.key);
          } else {
            acc.push(row.key);
          }

          return acc;
        }, []),
        tabName,
      );
    } else {
      setSelectedRows && setSelectedRows(selectedRows.filter(row => !(row.rights === 'Множественное подключение')));
    }
  };

  // здесь происходит переключение режима таблицы с автовысоты на максимальную высоту
  const checkDomLayout = (api: GridApi) => {
    if (api) {
      if (needHeight() > currentFreeSpace()) {
        api.setGridOption('domLayout', 'normal');
      }

      if (needHeight() < currentFreeSpace()) {
        getGridApi(api)?.setGridOption('domLayout', 'autoHeight');
      }
    }
  };

  const onModelUpdated = ({ api }: ModelUpdatedEvent) => {
    needAutoHeight && checkDomLayout(api);

    toggleClassFillHeightScroll(classNames.fillHeightScroll, needAutoHeight);
    // const nodes = api ? api?.getRenderedNodes() : [];
    // const fastStatNodes = nodes?.filter(el => el.data.fastStat === 0);
    // const previewNodes = nodes?.filter(el => el.data.previewSmall === 'none');
    // (fastStatNodes?.length || previewNodes.length) && onBodyScroll && onBodyScroll(nodes, api);
  };

  const onGridSizeChanged = () => {
    showSkeletonForLonger && !isTableLoading && showSkeletonLayout && setShowSkeletonLayout(false); // выключаем отображение загрузочного скелетона после того как таблица приведёт себя в божеский вид, выставит колонкам правильную ширину, посчитает автовысоту и т.п.

    if (apiGrid && getGridApi(apiGrid)) {
      setFillHeightScroll(apiGrid);
      needAutoHeight && checkDomLayout(apiGrid);
      const headerViewport = document.querySelector('.ag-header-viewport ');
      const headerContainer = document.querySelector('.ag-header-container');

      if (
        headerContainer &&
        headerViewport &&
        headerContainer.clientWidth < headerViewport.clientWidth &&
        !isNotResizable(columns)
      ) {
        apiGrid.sizeColumnsToFit();
      }

      const nodes = apiGrid?.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
      onBodyScroll && onBodyScroll(nodes, apiGrid);
    }
  };

  const onBodyScrollWrapper = ({ type, direction }: BodyScrollEvent) => {
    if (apiGrid && getGridApi(apiGrid)) {
      needAutoHeight && checkDomLayout(apiGrid);
      const nodes = apiGrid?.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
      type === 'bodyScroll' && direction === 'vertical' && onBodyScroll && onBodyScroll(nodes, apiGrid);
    }
  };

  // При изменении ширины экрана к примеру 25%, таблица автоматически увеличивая колонки вызывает метод onColumnResized -
  // для предотвращения сохранения в LS автомтически изменененной ширины колонок,
  // мы уточняем были ли изменены колонки именно пользователем через event.source === 'uiColumnResized'
  const onColumnResized = (event: ColumnResizedEvent) => {
    event.source === 'uiColumnResized' && isManageAds && saveColumnState(event.api);
  };

  const onColumnMoved = ({ api }: ColumnMovedEvent) => {
    isManageAds && saveColumnState(api);

    saveIsColumnMoved();
  };

  const onSortChanged = useCallback(
    ({ api }: SortChangedEvent) => {
      if (getGridApi(api)) {
        isManageAds && saveColumnState(api);
        const nodes = api.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
        onBodyScroll && onBodyScroll(nodes, api);
      }
    },
    [rowData],
  );

  const onRowClick = (event: RowClickedEvent) => {
    // раскрывает/сворачивает группировку по клику на строку
    if (event.node.childrenAfterFilter?.length) {
      event.api.setRowNodeExpanded(event.node, !event.node.expanded);
    }

    if (onRowClicked) {
      onRowClicked(event);
    }
  };

  // update fastStat 1 min
  const handleBodyScroll = useCallback(() => {
    if (onBodyScrollFastStat && gridRef.current) {
      const nodes = gridRef.current.api.getRenderedNodes().filter(node => !node.data.isGroupRow);
      nodes && onBodyScrollFastStat(nodes, gridRef.current.api);
    }
  }, [onBodyScrollFastStat, gridRef]);

  const isTabActive = useTabVisibility();

  // при изменение размеров экрана подставляем в таблицу сохраненые данные из LS, после чего если ширина таблица короче
  // чем ширина экрана - она (таблица) сама изменит ширину колонок
  const windowResize = () => {
    if (apiGrid) {
      const columnState = getSafeParsedLocalStorage<Record<string, ColumnState[]>>('columns');
      if (columnState && columnState[href]) {
        apiGrid.applyColumnState({ state: columnState[href] });
      }
    }
  };

  // для решения проблемы - когда срабатывает тригер visibilitychange таблица должна заново понять показывать ли ей Overlay
  // (при выходе из приложения на другую вкладку когда есть заглушка в таблице, чтоб она не пропадала)
  useEffect(() => {
    apiGrid?.getDisplayedRowCount() === 0 ? apiGrid?.showNoRowsOverlay() : apiGrid?.hideOverlay();
  }, [isTabActive]);

  useInterval(
    () => {
      if (gridRef.current && isTabActive) {
        handleBodyScroll();
      }
    },
    60000,
    isTabActive,
  );

  useEffect(() => {
    return () => {
      onGridReady && onGridReady(null as unknown as GridApi);
    };
  }, [onGridReady]);

  useEffect(() => {
    const handleKeyBindingsWrapper = (event: KeyboardEvent) => handleKeyBindings(event, gridRef, selectionColumnDef);

    document.addEventListener('keydown', handleKeyBindingsWrapper);

    return () => {
      document.removeEventListener('keydown', handleKeyBindingsWrapper);
    };
  }, []);

  useEffect(() => {
    // Подписываемся на изменение размеров экрана и если у таблицы isCampaigns === true и нет стейта в LS, то добавим его
    if (apiGrid && !columnState?.[href] && isManageAds) {
      saveColumnState(apiGrid);
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, [apiGrid]);

  useLayoutEffect(() => {
    if (isTableLoading) {
      setApiGrid(null);
    }
  }, [isTableLoading]);

  useEffect(() => {
    if (isTableLoading) {
      setShowTable(false);
      setShowSkeletonLayout(true);
    }
    if (!isTableLoading) {
      setShowTable(true);
      !showSkeletonForLonger && setShowSkeletonLayout(false); // выключаем отображение загрузочного скелетона сразу как закончится загрузка, если не указано, что скелетон нужно показывать подольше
    }
  }, [isTableLoading]);

  useAutoHeightFix(!!needAutoHeight, gridRef, needHeight(), spaceAboveTable); // фикс переключения автовысоты для редких случаев ресайза окна

  const loaderLayout = (
    <>
      <Lockscreen visible={isTableLoading} />
      <SkeletonTable />
    </>
  );

  if (errorTable) return <ErrorTable error={errorTable} action={ActionError} />;

  const restProps: AgGridReactProps = {};
  showStatusBar && (restProps.pinnedBottomRowData = [{}]);
  return (
    <div className={clsx(!disableContainersHeight ? classNames.tableShadow : classNames.tableShadowWithoutHeight)}>
      {showSkeletonLayout && loaderLayout}

      {showTable && (
        <div
          className={clsx(
            classNames.table,
            'ag-theme-alpine',
            showStatusBar && classNames.fitXScroll,
            multiFooterCount() > 2 && classNames.fitXScrollHight,
            onRowClicked && classNames.rowPointer,
          )}
        >
          <AgGridReact
            ref={gridRef}
            {...restProps}
            rowSelection={{
              mode: 'multiRow',
              selectAll: 'filtered',
              groupSelects: 'filteredDescendants',
              checkboxes: !!selectionColumnDef,
              headerCheckbox: !!selectionColumnDef,
              enableClickSelection: !!selectionColumnDef,
            }}
            selectionColumnDef={selectionColumnDef}
            getRowHeight={getRowHeight}
            rowData={rowData}
            getRowId={({ data }) =>
              `${isManageAds ? data.key : data.orgHierarchy ? data.orgHierarchy.join('/') : data.key}`
            }
            getDataPath={data => data.orgHierarchy || [data.key]}
            tooltipShowDelay={0}
            tooltipMouseTrack={true}
            processUnpinnedColumns={() => {
              return [];
            }}
            components={{
              agColumnHeader: HeaderNameRenderer,
              NameRenderer,
              CustomFooterRenderer,
              GoalsRenderer,
              TwoLinesRenderer,
              RightsRenderer,
              NameCountRenderer,
              DefaultSumRenderer,
              AdsSumRenderer,
              DefaultAvgRenderer,
              BudgetRenderer,
              BudgetRendererYd,
              RangeDateRenderer,
              PercentsRenderer,
              BidRenderer,
              NameEmailUser,
              FastStatRenderer,
              BidCellRenderer,
              PreloaderRenderer,
              StatusRenderer,
              TranslationRenderer,
              TranslationRendererYd,
              PeriodRenderer,
              GroupNameRenderer,
              DefaultTooltipCell,
              MemberRender,
              noRowsContent,
              agNoRowsOverlay: () => <EmptyDataAfterFilter<RD> gridRef={gridRef} agNoRowsOverlay={agNoRowsOverlay} />,
              SquareAvatarNameRenderer,
              CircleAvatarNameRenderer,
              TeamMembersRenderer,
              AvatarsInRowCell,
              ProjectsRenderer,
              SimpleCircleAvatarNameRenderer,
              LinkCellRenderer,
              IntegrationStatusRenderer,
              FakeGroupCellRenderer,
            }}
            defaultColDef={defColDef}
            suppressCellFocus
            suppressContextMenu
            suppressDragLeaveHidesColumns
            cacheQuickFilter
            rowBuffer={0}
            animateRows
            treeData
            // groupSelectsChildren
            // groupSelectsFiltered
            statusBar={statusBar}
            groupDisplayType="custom"
            onGridReady={onGridReadyWrapper}
            onFirstDataRendered={onFirstDataRendered}
            onSelectionChanged={onSelectionWrapper}
            columnDefs={columnState && columnState[href] ? upDefaultColumnsFromLocalStorage() : columns}
            onGridSizeChanged={debounce(onGridSizeChanged, 500)}
            onBodyScroll={debounce(onBodyScrollWrapper, 500)}
            onSortChanged={debounce(onSortChanged, 500)}
            onModelUpdated={e =>
              // здесь убран дебаунс для ситуации, когда мы разворачиваем группировку, чтобы футер быстро отскочил к низу страницы при переключении с автовысоты на максимальную высоту
              // в остальных случаях дебаунс срабатывает, и футер отскакивает с задержкой
              needHeight() > currentFreeSpace() && needAutoHeight ? onModelUpdated(e) : debounce(onModelUpdated, 500)(e)
            }
            onRowClicked={onRowClick}
            onFilterChanged={({ api }) => {
              const nodes = api.getRenderedNodes();
              isManageAds && onBodyScroll && onBodyScroll(nodes, api);
              setFillHeightScroll(api);
              // не удалять, иначе падает
              setTimeout(() => {
                getGridApi(api) && api.redrawRows();
              });
              api.getDisplayedRowCount() === 0 ? api.showNoRowsOverlay() : api.hideOverlay();
            }}
            rowClassRules={{
              'background-row-error': param => {
                return (
                  param.data.plarin_status === 'enabled' &&
                  (param.data.integrationStatusCode === 'blocked' || param.data.syncStatusCode === 'blocked')
                );
              },
              hasOrgHierarchy1: params => params.node.data.isGroupRow && !params.node.data?.orgHierarchy?.length,
              hasOrgHierarchy2: params => params.node.data.isGroupRow && params.node.data?.orgHierarchy?.length > 1,
              hasOrgHierarchy3: params => params.node.data.isGroupRow && params.node.data?.orgHierarchy?.length > 2,
              'row-is-parent': param => param.data.isGroupRow,
            }}
            onColumnResized={onColumnResized}
            onColumnMoved={onColumnMoved}
            groupDefaultExpanded={groupDefaultExpanded}
            onRowGroupOpened={onRowGroupOpened}
            getRowClass={el => (getRowClass ? getRowClass(el) : undefined)}
          />
        </div>
      )}
    </div>
  );
};
