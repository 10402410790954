import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SettingsMenu } from '../../../components/settings-menu';

interface SettingMenuProps {
  data: any;
  isEditDrawer?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MenuProjects = observer(({ data, isEditDrawer }: SettingMenuProps) => {
  const {
    projects: { setStatusWSroles, setModalData, setEditProject, setIsEditProject, setIsOpenDrawer },
    profileStore: { data: profileData, WSRole },
    teams: { teamsData },
  } = useAppDataStore();

  const isAdmin = WSRole === 'wsrole:admin' || WSRole === 'wsrole:owner';

  const isTeamsLeader: boolean = data.team_ids
    .map((el: any) => teamsData.find(i => i._id === el)?.leader_id === profileData.user_id)
    .includes(true);

  const isManager = data.manager_id === profileData.user_id;

  const settingMenu = useMemo(() => {
    const result = [];

    if (isTeamsLeader || isManager || isAdmin) {
      result.push({
        name: 'Редактировать',
        action: () => {
          setStatusWSroles({
            isAdmin: isAdmin,
            isManager: isManager,
            isLeader: isTeamsLeader,
          });
          setEditProject('all', data);
          setIsEditProject(true);
          setIsOpenDrawer(true);
          setAnchorEl(null);
        },
      });
      if (isTeamsLeader || isManager || isAdmin) {
        result.push({
          name: data.is_archived === true ? 'Разархивировать' : 'Архивировать',
          action: () => {
            setModalData({ type: data.is_archived === true ? 'unArchive' : 'archive', data: data });
            setAnchorEl(null);
          },
        });
      }
      if (isAdmin) {
        result.push({
          name: 'Удалить',
          action: () => {
            setModalData({ type: 'delete', data: data });
            setAnchorEl(null);
          },
        });
      }
    }
    return result;
  }, [data]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const onOpen = useCallback((e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      {(isAdmin || isTeamsLeader || isManager) && (
        <SettingsMenu onOpen={onOpen} anchorEl={anchorEl} settingMenu={settingMenu} onClose={onClose} />
      )}
    </>
  );
});
