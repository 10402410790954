import { CustomSelect, Input } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import { TAgencyAppFormData } from 'types/connect-af/types';
import classes from './style.module.scss';
import { CurrencyOptions, PlatformOptions, TimezoneOptions } from './utils';

interface AppConnectionInputsProps {
  data: { appId: string; name: string; platform: string; timezone: string; currency: string };
  isLoading: boolean;
  updateData: (key: keyof TAgencyAppFormData, value: string) => void;
}

export const AppConnectionInputs: React.FC<AppConnectionInputsProps> = ({ data, isLoading, updateData }) => {
  return (
    <div className={classes.inputsWrapper}>
      <Input
        type="text"
        inputRootWrapperDefault
        value={data.name}
        size={48}
        onChange={value => updateData('name', value)}
        onBlur={() => {
          updateData('name', data?.name.trim());
        }}
        label="Название приложения"
        disabled={isLoading}
        className="input"
        maxLength={45}
        maxCounterValue={45}
      />
      <div className={classes.optionsWrapper}>
        <div className={classes.rowWrapper}>
          <Input
            type="text"
            inputRootWrapperDefault
            value={data.appId}
            size={48}
            onChange={value => updateData('appId', value)}
            onBlur={() => {
              updateData('appId', data?.appId.trim());
            }}
            label="ID"
            disabled={isLoading}
            className="input"
          />
          <CustomSelect
            value={data.platform}
            onChange={value => updateData('platform', value)}
            listVariants={PlatformOptions}
            label={data.platform === 'placeholder' ? undefined : 'Платформа'}
            menuHeight={144}
            placeholderClassName={data.platform === 'placeholder' ? classes.placeholderClassName : ''}
            disabledRead={isLoading}
          />
        </div>
        <div className={classes.rowWrapper}>
          <CustomSelect
            value={data.timezone}
            onChange={value => updateData('timezone', value)}
            listVariants={TimezoneOptions}
            label={data.timezone === 'placeholder' ? undefined : 'Часовой пояс'}
            menuHeight={144}
            placeholderClassName={data.timezone === 'placeholder' ? classes.placeholderClassName : ''}
            disabledRead={isLoading}
          />
          <CustomSelect
            value={data.currency}
            onChange={value => updateData('currency', value)}
            listVariants={CurrencyOptions}
            label={data.currency === 'placeholder' ? undefined : 'Валюта'}
            menuHeight={144}
            placeholderClassName={data.currency === 'placeholder' ? classes.placeholderClassName : ''}
            disabledRead={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
