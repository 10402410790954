import { DateRange, StaticDateRangePicker, LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import { TextField, Box, Button } from '@mui/material';
import { Typography } from '@plarin/design';
import { shortDate } from '@plarin/utils';
import clsx from 'clsx';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import ru from 'date-fns/locale/ru';
import React, { useEffect, useState } from 'react';
import { timeZone } from '../date-range-input/constants';
import { daysAbbreviation, stylesDateRangePicker } from './constants';
import classes from './style.module.scss';
import { IDateRangePicker } from './types';
import { getDateString } from './utils';

export const DateRangePicker = ({
  classDatePicker,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setOpen,
  setOpenDatePicker,
  setRangeValue,
  setSelected,
  menuItems,
  setLabel,
  calendarIdx,
  open,
}: IDateRangePicker) => {
  const [dateRange, setDateRange] = useState<DateRange<Date | undefined>>([undefined, undefined]);
  const [currentDate] = useState(new Date());

  // эта манипуляция с DOM необходима, чтобы аббревиатуры неделей были не "П", а "Пн" для понедельника и тд. Другого способа замены аббревиатур для данной версии этого календаря нет.
  useEffect(() => {
    const rootElement = document.getElementById('DateRangePicker');

    if (rootElement) {
      const daysCollection = rootElement.getElementsByClassName('MuiTypography-caption');

      if (daysCollection) {
        const arrayOfDays = Array.from(daysCollection);

        arrayOfDays.forEach((_, index) => {
          arrayOfDays[index].textContent = daysAbbreviation[index];
        });
      }
    }
  }, []);

  useEffect(() => {
    startDate && endDate && setDateRange([new Date(startDate), new Date(endDate)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setOpen(false);
    setOpenDatePicker(false);
  };

  // определяет, какому пункту меню соответствует выбранный в календаре интервал. Если найдено соответствие, делает этот пункт меню активным
  const selectMenuItem = (firstDate: Date, lastDate: Date) => {
    const menuItemIndex = menuItems.findIndex(item => {
      if (item.isDivider) {
        return false;
      }
      if (item.startDay && item.endDay) {
        return (
          getDateString(item.startDay()) === firstDate.toDateString() &&
          getDateString(item.endDay()) === lastDate.toDateString()
        );
      }
      return false;
    });

    setLabel(menuItems[calendarIdx].label);

    if (menuItemIndex !== -1) {
      return setSelected(menuItemIndex);
    }

    return setSelected(calendarIdx);
  };

  const applyDateRange = () => {
    if (!dateRange[0] && !dateRange[1]) {
      return;
    }

    if (dateRange[0]) {
      const firstDate = dateRange[0];
      const lastDate = dateRange[1] ? dateRange[1] : dateRange[0];

      setStartDate(+new Date(firstDate));
      setEndDate(+new Date(lastDate));
      setRangeValue(`${format(firstDate, 'dd.MM.yyyy')}-${format(lastDate, 'dd.MM.yyyy')}`);
      selectMenuItem(firstDate, lastDate);
      setOpen(false);
      setOpenDatePicker(false);
    }
  };

  return (
    <Box
      id="DateRangePicker"
      sx={stylesDateRangePicker}
      className={clsx(open && classes.calendarOpen)}
      onClick={e => e.stopPropagation()}
    >
      <Box className={clsx(classes.calendarContainer, classDatePicker)}>
        <LocalizationProvider locale={ru} dateAdapter={DateFnsAdapter}>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            label="date range"
            value={dateRange}
            className="StaticDateRangePicker"
            maxDate={utcToZonedTime(currentDate, timeZone)}
            onChange={newValue => setDateRange(newValue)}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> до </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
        <div className={classes.buttonContainer}>
          <Typography size="Caption" color="TextSecondary">
            Время по Москве (UTC +3)
          </Typography>
          <div>
            <Button variant="outlined" onClick={onClose}>
              Отменить
            </Button>
            <Button variant="contained" onClick={applyDateRange}>
              Применить
            </Button>
          </div>
        </div>
      </Box>
    </Box>
  );
};
