import { ShapeIcon, Typography } from '@plarin/design';
import { Drawer, TitleOptions } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TDataGoalsYM } from '../../../../types/connect-ym/types';
import { sortRow } from '../../../utils/common';
import { useAppDataStore } from '../../app-data-provider';
import { WsDrawerPreloader } from '../preloaders/preloaders';
import { BodyFirstBlock, SourceComparison, GoalsList, Ecommerce, FooterGoals } from './components';
import { ModalCorrection } from './components/modal-correction';
import { IGoalsDrawer, IGoalsList, IinitialData } from './type';
import {
  dataFirstBlock,
  dataGoalsList,
  dataSourceComparison,
  itemsSourceComparison,
  useComparisonInitialData,
} from './utils';

export const GoalsDrawerYM = observer(({ isDrawerOpen }: IGoalsDrawer) => {
  const {
    manageVK: { getMetricsVK },
    manageYD: { getMetricsYD },
    integrationsYM: {
      dataGoalsYM,
      isLoading,
      setIsOpenGoalsDrawerYM,
      postGoalsYM,
      isLoadingPostGoalsYM,
      setIsLoadingPostGoalsYM,
    },
  } = useAppDataStore();

  const [valueSourceComparison, setValueSourceComparison] = useState({ lable: 'utm_content', value: 'utm_content' });
  const [valueEcommerce, setValueEcommerce] = useState<boolean | undefined>(false);
  const [goalsList, setGoalsList] = useState<IGoalsList[]>([]);
  const [initialData, setInitialData] = useState<IinitialData | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpenModalCorrection, setIsOpenModalCorrection] = useState(false);

  const textStub = `В счётчике ${dataGoalsYM?.column2_header1} нет ни одной цели. Добавьте цели в Яндекс Метрике и они появятся здесь.`;

  const onCloseDrawer = () => {
    if (isDrawerOpen && !isLoadingPostGoalsYM) {
      isDisabled ? setIsOpenModalCorrection(true) : setIsOpenGoalsDrawerYM(false);
    }
  };

  const editGoalsYM = () => {
    postGoalsYM({
      ym_user_id: dataGoalsYM?.ymUserId ?? '',
      counter_id: Number(dataGoalsYM?.counterId ?? 0),
      ecommerce: valueEcommerce,
      source: valueSourceComparison.value as keyof TDataGoalsYM['source'],
      enabled_goals: goalsList.reduce((prev, current) => {
        const result = prev;
        if (current.enabled) {
          result.push(current.id);
        }
        return result;
      }, [] as number[]),
    }).then(() => {
      getMetricsVK();
      getMetricsYD();
    });
  };

  useEffect(() => {
    if (dataGoalsYM) {
      const goalsList = [...dataGoalsList(dataGoalsYM)?.sort((a, b) => sortRow(a.name, b.name))];

      setValueEcommerce(dataGoalsYM.ecommerce);
      setGoalsList(goalsList);
      setValueSourceComparison(dataSourceComparison(dataGoalsYM));

      setInitialData({
        valueSourceComparison: dataSourceComparison(dataGoalsYM),
        valueEcommerce: dataGoalsYM.ecommerce,
        goalsList,
      });
    }
  }, [dataGoalsYM]);

  useComparisonInitialData({
    valueSourceComparison,
    valueEcommerce,
    goalsList,
    initialData,
    setIsDisabled,
  });

  useEffect(() => {
    if (!isDrawerOpen) {
      setIsDisabled(false);
      setInitialData(null);
      setGoalsList([]);
    }
  }, [isDrawerOpen]);

  return (
    <>
      <Drawer
        isOpen={isDrawerOpen}
        workspaceDrawer
        title={
          <TitleOptions
            circle
            iconBlock={<ShapeIcon />}
            titleName={
              <Typography weight={600} size="DrawerProject" componentProps={{ className: 'mt2' }}>
                Настройка Яндекс Метрика
              </Typography>
            }
          />
        }
        preloader={<WsDrawerPreloader />}
        loading={isLoading}
        closeDrawer={onCloseDrawer}
        actionBlock={
          <FooterGoals
            isLoadingPost={isLoadingPostGoalsYM}
            isDisabled={isDisabled}
            setIsDrawerOpen={setIsOpenGoalsDrawerYM}
            onEdit={editGoalsYM}
            onDestroyed={() => setIsLoadingPostGoalsYM(false)}
          />
        }
      >
        <div>
          <BodyFirstBlock data={dataFirstBlock({ dataGoals: dataGoalsYM, title: ['Аккаунт', 'Счетчик'] })} />
          {initialData && (
            <>
              <SourceComparison
                items={itemsSourceComparison}
                title="Источник сопоставления"
                defaultValue={valueSourceComparison.value}
                onChangeValue={item => {
                  setValueSourceComparison(item);
                }}
              />
              {dataGoalsYM?.ecommerce !== null && <Ecommerce value={!!valueEcommerce} onChange={setValueEcommerce} />}
              <GoalsList
                idShow={true}
                title="Цели"
                isCount={true}
                subTitle="Все цели"
                items={goalsList ?? []}
                onChangeValue={items => {
                  setGoalsList(items);
                }}
              >
                {textStub}
              </GoalsList>
            </>
          )}
        </div>
      </Drawer>
      {isDisabled && isOpenModalCorrection && !isLoadingPostGoalsYM && (
        <ModalCorrection
          onCloseModal={() => setIsOpenModalCorrection(false)}
          firstAction={() => {
            setIsOpenModalCorrection(false);
            setIsOpenGoalsDrawerYM(false);
          }}
          secondAction={() => {
            setIsOpenModalCorrection(false);
            editGoalsYM();
          }}
        />
      )}
    </>
  );
});
