import { Typography } from '@plarin/design';
import { GridApi, Modal, ModalActionNamesEnum } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ModalTypes } from '../../../../src/utils/integration-converters';
import { TAFStoreData } from '../../../../types/connect-af/types';
import { useAppDataStore } from './../../../components/app-data-provider';
import { AppConnectionInputs } from './AppConnectionInputs';
import { ConnectionError } from './ConnectionError';
import { ConnectionInputs } from './ConnectionInputs';
import { TypeConnection } from './TypeConnection';
import classes from './style.module.scss';

const textRemove = {
  one: {
    title: 'Интеграция ',
    text: ' будет удалена из Plarin вместе с ранее накопленной статистикой.',
  },
  many: {
    title: 'Интеграции ',
    text: ' будут удалены из Plarin вместе с ранее накопленной статистикой.',
  },
};

export const MetricsConfirmModal = observer(({ gridApi }: { gridApi?: GridApi }) => {
  const {
    integrationsAF: {
      connectFormData,
      agencyAppFormData,
      modalData,
      updateData,
      updateAgencyAppFormData,
      resetConnectForm,
      resetAgencyAppForm,
      isLoading,
      setIsConnectFailed,
      isConnectFailed,
      connectAppsflyerAccount,
      connectionError,
      clearModalData,
      disableIntegrationAF,
      deleteAccountsAF: disconnectAccounts,
      addAgencyApp,
      deleteAgencyApp,
    },
  } = useAppDataStore();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleCloseConnectModal = () => {
    setIsConnectFailed(false);
    clearModalData();
    resetConnectForm();
  };

  const handleCloseAddAgencyAppModal = () => {
    setIsConnectFailed(false);
    clearModalData();
    resetAgencyAppForm();
  };

  useEffect(() => {
    if (modalData.type) {
      if (modalData.type === ModalTypes.addApp) {
        const shouldDisable =
          !agencyAppFormData.name ||
          !agencyAppFormData.appId ||
          agencyAppFormData.platform === 'placeholder' ||
          agencyAppFormData.timezone === 'placeholder' ||
          agencyAppFormData.currency === 'placeholder';
        setIsDisabled(shouldDisable);
      } else {
        const shouldDisable = !connectFormData.name || !connectFormData.token;
        setIsDisabled(shouldDisable);
      }
    }
  }, [
    modalData.type,
    agencyAppFormData.name,
    agencyAppFormData.appId,
    agencyAppFormData.platform,
    agencyAppFormData.timezone,
    agencyAppFormData.currency,
    connectFormData.name,
    connectFormData.token,
  ]);

  const getParentRows = (rows: TAFStoreData[]) => rows.filter(row => row.isParent);
  const getChildsRows = (rows: TAFStoreData[]) => rows.filter(row => !row.isParent);

  const getEnabledRows = (rows: TAFStoreData[]) => rows.filter(row => row.plarin_status === 'enabled');

  const parentRows = getParentRows(modalData.selectedRows || []);
  const childRows = getChildsRows(modalData.selectedRows || []);

  const textAccounts = React.useMemo(
    () =>
      modalData.name &&
      modalData.name.map((element, index) => (index === modalData.name!.length - 1 ? element : element + ', ')),
    [modalData.name],
  );

  switch (modalData.type) {
    case ModalTypes.connectAccount:
      return (
        <Modal
          width={592}
          open={modalData.type === ModalTypes.connectAccount}
          closeModal={handleCloseConnectModal}
          workspaceHeader="connectAppsflyer"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: handleCloseConnectModal,
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Connect,
            isLoading: isLoading,
            action: connectAppsflyerAccount,
          }}
          title="Подключение Appsflyer"
          classNameChildren={classes.modalChildren}
          classNameActionBar={connectFormData.type && classes.actionBar}
          classNameIcon={classes.icon}
          disabledFirstAction={isDisabled}
          classNameHeader={classes.headerModal}
        >
          <TypeConnection
            connectionError={connectionError}
            isConnectFailed={isConnectFailed}
            data={connectFormData}
            isLoading={isLoading}
            updateData={updateData}
          />
        </Modal>
      );

    case ModalTypes.addApp:
      return (
        <Modal
          width={592}
          open={modalData.type === ModalTypes.addApp}
          closeModal={handleCloseAddAgencyAppModal}
          workspaceHeader="connectAppsflyer"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: handleCloseAddAgencyAppModal,
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Add,
            isLoading: isLoading,
            action: () => addAgencyApp(modalData.selectedRows![0]),
          }}
          title="Добавить приложение"
          classNameHeader={classes.addAppHeader}
          classNameChildren={classes.addAppChildren}
          classNameIcon={classes.icon}
          disabledFirstAction={isDisabled}
        >
          <>
            {isConnectFailed && <ConnectionError errorCode={connectionError} />}
            <AppConnectionInputs data={agencyAppFormData} isLoading={isLoading} updateData={updateAgencyAppFormData} />
          </>
        </Modal>
      );

    case ModalTypes.pause:
      return (
        <Modal
          open={modalData.type === ModalTypes.pause}
          closeModal={() => clearModalData()}
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Unplug,
            isLoading: isLoading,
            action: () =>
              disableIntegrationAF({
                parentRows: getEnabledRows(parentRows),
                childRows: getEnabledRows(childRows),
                gridApi: gridApi,
              }),
          }}
          title="Выключить интеграцию?"
        >
          <Typography size="AuthContent">
            {' '}
            {'После выключения '}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            {' синхронизация будет остановлена и новые данные поступать не будут до возобновления интеграции.'}
          </Typography>
        </Modal>
      );

    case ModalTypes.remove:
      return (
        <Modal
          open={modalData.type === ModalTypes.remove}
          closeModal={() => clearModalData()}
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Delete,
            isLoading: isLoading,
            action: () => disconnectAccounts(parentRows),
          }}
          title="Удалить интеграцию?"
        >
          <Typography size="AuthContent">
            {parentRows?.length === 1 ? textRemove.one.title : textRemove.many.title}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>

            {parentRows?.length === 1 ? textRemove.one.text : textRemove.many.text}
          </Typography>
        </Modal>
      );

    case ModalTypes.deleteApp:
      return (
        <Modal
          open={modalData.type === ModalTypes.deleteApp}
          closeModal={() => clearModalData()}
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Delete,
            isLoading: isLoading,
            action: () => deleteAgencyApp(modalData.selectedRows![0]),
          }}
          title="Удалить приложение"
        >
          <Typography size="AuthContent">
            Вы уверены, что хотите удалить приложение{' '}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            ?
          </Typography>
        </Modal>
      );

    default:
      return null;
  }
});
