import { CloseCircleIcon, ErrorIcon, Typography } from '@plarin/design';
import {
  Checkbox,
  CustomList,
  CustomMenuItem,
  DefaultInput,
  HightLightText,
  MainTooltip,
  SkeletonList,
} from '@plarin/inputs';
import { switchFilter, TooltipError, TooltipMessage } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { TReportClient, TShortClientsData } from '../../../../../types/reports/reports';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { filterClients, getSearchResults } from '../../../../utils/searchList';
import classes from './styles.module.scss';

type TListItemProps = {
  client: TReportClient;
  changeClientSelection: (client: TReportClient) => void;
  isFoundItem?: boolean;
  getIsSelected: (client: TReportClient) => boolean;
  searchValue?: string;
};

type TClientsListProps = {
  clientsListToRender: TReportClient[];
  selectedClients?: TReportClient[];
  changeClientSelection: (client: TReportClient) => void;
  isFoundItem: boolean;
  searchValue?: string;
  setSelectedClients: React.Dispatch<React.SetStateAction<TReportClient[]>>;
};

type TPopupClientsProps = {
  selectedClients: TReportClient[];
  setSelectedClients: React.Dispatch<React.SetStateAction<TReportClient[]>>;
};

const filtersShortClients = (searchVal: string, allClients: TShortClientsData): TReportClient[] => {
  const foundClients = filterClients(searchVal, allClients);

  // @ts-ignore нужно сделать строже тип у client.network
  return foundClients.map(client => {
    return {
      id: client.account_id,
      username: client.account_username,
      client_name: client.account_name ? client.account_name : undefined,
      network: client.network, // проблема с типами у запроса getClientsShort. Cвойство network должно иметь тип "mt" | "vk" | "va" а не string,
      has_error: false, // тоже лучше бы получать эту инфу из запроса о списке р.к.
    };
  });
};

const ListItem = ({ client, changeClientSelection, isFoundItem, getIsSelected, searchValue = '' }: TListItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const clientName = client.client_name ? (
    <>
      <HightLightText text={client.client_name} filterValue={searchValue} />
      &nbsp;
      <HightLightText
        size="caption"
        color={isHovered ? 'TextWhite' : 'TextSecondary'}
        text={`(${client.username}, ${client.id})`}
        filterValue={searchValue}
      />
    </>
  ) : (
    <HightLightText text={`${client.username} (${client.username}, ${client.id})`} filterValue={searchValue} />
  );

  const nameTooltipContent = client.client_name
    ? `${client.client_name} (${client.username}, ${client.id})`
    : `${client.username} (${client.username}, ${client.id})`;

  const regularItem = (
    <>
      <div className={classes.regularWrap}>
        {client.has_error && (
          <MainTooltip
            tooltipMessage={TooltipError.CLIENT_NOT_AVAILABLE}
            isVisible={true}
            component="span"
            followCursor={true}
            componentClassName={classes.errorTooltip}
          >
            <ErrorIcon color={isHovered ? 'white' : 'status_error'} />
          </MainTooltip>
        )}

        <MainTooltip
          tooltipMessage={nameTooltipContent}
          isTypograf={false}
          isVisible={true}
          component="span"
          followCursor={true}
          componentClassName={classes.tooltip}
          maxWidth={480}
        >
          <Typography
            size="Main"
            color={isHovered ? 'TextWhite' : 'TextPrimary'}
            componentProps={{ className: classes.listItemText }}
          >
            {clientName}
          </Typography>
        </MainTooltip>
      </div>

      {isHovered && (
        <MainTooltip
          tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
          component="span"
          isVisible={true}
          followCursor
          componentClassName={classes.listIcon}
        >
          <CloseCircleIcon
            color="white"
            // @ts-ignore нужно исправить типизацию client.network для getClientsShort
            onClick={() => {
              changeClientSelection(client);
            }}
          />
        </MainTooltip>
      )}
    </>
  );

  const foundItem = (
    <>
      <Checkbox
        value={getIsSelected(client)}
        size="small"
        className={classes.checkbox}
        // @ts-ignore нужно исправить типизацию client.network для getClientsShort
        onChange={() => changeClientSelection(client)}
      />

      <MainTooltip
        tooltipMessage={nameTooltipContent}
        isTypograf={false}
        isVisible={true}
        component="span"
        followCursor={true}
        maxWidth={480}
        componentClassName={clsx(classes.tooltip, classes.foundTooltip)}
      >
        <Typography
          size="Main"
          color={isHovered ? 'TextWhite' : 'TextPrimary'}
          componentProps={{ className: classes.listItemText }}
        >
          {clientName}
        </Typography>
      </MainTooltip>
    </>
  );

  return (
    <CustomMenuItem
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
      onClick={() => isFoundItem && changeClientSelection(client)}
      className={clsx(classes.cabinetListItem, isFoundItem && classes.foundCabinet)}
    >
      {isFoundItem ? foundItem : regularItem}
    </CustomMenuItem>
  );
};

const ClientsList = ({
  clientsListToRender,
  selectedClients,
  changeClientSelection,
  isFoundItem,
  searchValue,
  setSelectedClients,
}: TClientsListProps) => {
  const getISelected = (client: TReportClient): boolean => {
    return selectedClients ? !!selectedClients.find(item => item.id === client.id) : false;
  };

  const areAllSelected =
    clientsListToRender.length > 0 &&
    clientsListToRender.every(client => selectedClients && selectedClients.some(c => c.id === client.id));

  const isPartiallyChecked =
    clientsListToRender.some(client => selectedClients && selectedClients.some(c => c.id === client.id)) &&
    !areAllSelected;

  const toggleSelectAll = () => {
    if (selectedClients) {
      const newSelection = areAllSelected
        ? selectedClients.filter(client => !clientsListToRender.some(c => c.id === client.id))
        : [...selectedClients, ...clientsListToRender.filter(client => !selectedClients.some(c => c.id === client.id))];
      setSelectedClients(newSelection);
    }
  };

  return (
    <CustomList classProps={classes.listRoot}>
      <>
        {isFoundItem && clientsListToRender.length > 1 && (
          <div className={classes.selectAllWrapper} onClick={toggleSelectAll}>
            <Checkbox partChecked={isPartiallyChecked} value={areAllSelected || isPartiallyChecked} size="small" />
            <Typography size="Main" weight={600}>
              Выбрать все
            </Typography>
          </div>
        )}
        {clientsListToRender.map(client => (
          <ListItem
            client={client}
            changeClientSelection={changeClientSelection}
            isFoundItem={isFoundItem}
            key={client.id}
            getIsSelected={getISelected}
            searchValue={searchValue}
          />
        ))}
      </>
    </CustomList>
  );
};

export const PopupClients = ({ selectedClients, setSelectedClients }: TPopupClientsProps) => {
  const {
    reports: { shortClients },
  } = useAppDataStore();

  const [searchValue, setSearchValue] = useState('');

  const [filteredClients, setFilteredClients] = useState<null | TReportClient[]>(null);

  useEffect(() => {
    shortClients.clients &&
      getSearchResults(searchValue, filtersShortClients, setFilteredClients, shortClients.clients);
  }, [shortClients.clients, searchValue]);

  const changeClientSelection = (client: TReportClient) => {
    if (!!selectedClients.find(item => item.id === client.id)) {
      setSelectedClients(selectedClients.filter(item => item.id !== client.id));
    } else {
      setSelectedClients([...selectedClients, client]);
    }
  };

  const renderSearchResults = () => {
    if (shortClients.loading) {
      return (
        <div className={classes.marginSkeleton}>
          <SkeletonList size="maxLong" height={17} sumList={7} />
        </div>
      );
    }

    if (shortClients.error) {
      return (
        <div className={classes.wrap}>
          <Typography size="Main" color="TextSecondary">
            Произошла ошибка при загрузке рекламных кабинетов.
          </Typography>
        </div>
      );
    }

    if (shortClients.clients && filteredClients && filteredClients.length > 0) {
      return (
        <ClientsList
          clientsListToRender={filteredClients}
          selectedClients={selectedClients}
          changeClientSelection={changeClientSelection}
          setSelectedClients={setSelectedClients}
          isFoundItem={true}
          searchValue={
            filtersShortClients(searchValue, shortClients.clients).length ? searchValue : switchFilter(searchValue)
          }
        />
      );
    }

    if (shortClients.clients && filteredClients && filteredClients.length === 0) {
      return (
        <div className={classes.wrap}>
          <Typography size="Main" color="TextSecondary">
            По вашему запросу не найден ни один рекламный кабинет.
          </Typography>
        </div>
      );
    }

    // досюда не должно доходить
    return (
      <div className={classes.wrap}>
        <Typography size="Main" color="TextSecondary">
          Что-то пошло не так.
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.cabinetsList}>
      <div className={classes.search}>
        <DefaultInput
          value={searchValue}
          setValue={setSearchValue}
          placeholder="Поиск рекламного кабинета"
          searchIcon
          clearInputAction={() => setSearchValue('')}
        />
      </div>

      {searchValue ? (
        renderSearchResults()
      ) : (
        <ClientsList
          clientsListToRender={selectedClients}
          changeClientSelection={changeClientSelection}
          isFoundItem={false}
          setSelectedClients={setSelectedClients}
        />
      )}

      <div className={classes.footer}>
        <hr className={classes.divider} />
        <Typography componentProps={{ className: classes.footerItem }} size="Main" color="TextSecondary" weight={400}>
          Выбранные кабинеты: {selectedClients.length}
        </Typography>
      </div>
    </div>
  );
};
