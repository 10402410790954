import { AuthorizedOnly, isInvalidRequestServer, isServerError, UnAuthorizedOnly } from '@plarin/core';
import { Preloader } from '@plarin/design';
import { isUrlProd, isUrlDev } from '@plarin/utils';
import { getYmCounter } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useAppDataStore } from '../components/app-data-provider';
import { DataLoader } from '../components/data-loader';
import { CheckWorkspaceLayout } from '../components/layout/checkWorkspaceLayout';
import { RoutesLayout } from '../components/layout/routes-layout';
import CheckMailPage from '../pages/check-mail/check-mail-page';
import CreatePasswordPage from '../pages/create-password/create-password-page';
import ErrorPage from '../pages/error-page/error-page';
import ErrorPageInvalidRequest from '../pages/error-page/error-page-invalid-request';
import ForgotPasswordPage from '../pages/forgot-password/forgot-password-page';
import IntegrationsAF from '../pages/integrations-af';
import IntegrationsGA from '../pages/integrations-ga';
import IntegrationsVK from '../pages/integrations-vk';
import IntegrationsYD from '../pages/integrations-yd';
import IntegrationsYM from '../pages/integrations-ym';
import LoginPage from '../pages/login/login-page';
import ManageVkPage from '../pages/manage-vk';
import ManageYD from '../pages/manage-yd';
import Profile from '../pages/profile/profile';
import ProjectOverview from '../pages/project-overview';
import RegistrationSocialPage from '../pages/registration-social/registration-social-page';
import RegistrationPage from '../pages/registration/registration-page';
import Reports from '../pages/reports';
import RootPage from '../pages/root';
import Members from '../pages/workspace-members';
import Projects from '../pages/workspace-projects';
import WorkspaceRoot from '../pages/workspace-root';
import WorkSpaceSettings from '../pages/workspace-settings';
import Teams from '../pages/workspace-teams';
import { useRedirectRoutes } from '../utils/redirect';
import { NotificationRenders } from './route-content';
import { routerPaths } from './router-path';

const RedirectToLogin = () => <Navigate to={routerPaths.unAuth.LOGIN} />;

export const RedirectToRoot = () => <Navigate to={routerPaths.auth.ROOT} />;

export const RedirectToWsBase = () => <Navigate to={routerPaths.workspaces.BASE} />;

const WorkspaceRoutes = observer(() => {
  const {
    profileStore: { WSRole, currentWorkspace },
  } = useAppDataStore();
  const path = useLocation().pathname;
  useRedirectRoutes({ currentWorkspace });

  return (
    <CheckWorkspaceLayout>
      {WSRole === 'wsrole:guest' ? (
        <Routes>
          <Route index element={<WorkspaceRoot />} />
          <Route path="*" element={<RedirectToRoot />} />
        </Routes>
      ) : (
        <Routes>
          <Route index element={<WorkspaceRoot />} />
          <Route path={routerPaths.auth.MANAGE_VK} element={<ManageVkPage />} />
          <Route path={routerPaths.auth.MANAGE_YD} element={<ManageYD />} />
          <Route path={routerPaths.auth.REPORTS} element={<Reports />} />
          <Route path={routerPaths.auth.VKONTAKTE} element={<IntegrationsVK />} />
          <Route path={routerPaths.auth.YANDEX_DIRECT} element={<IntegrationsYD />} />
          <Route path={routerPaths.auth.YANDEX_METRICS} element={<IntegrationsYM />} />
          <Route path={routerPaths.auth.GOOGLE_ANALYTICS} element={<IntegrationsGA />} />
          <Route path={routerPaths.auth.APPSFLYER} element={<IntegrationsAF />} />
          <Route path={routerPaths.auth.MEMBERS} element={<Members />} />
          <Route path={routerPaths.auth.TEAMS} element={<Teams />} />
          <Route path={routerPaths.auth.PROJECTS} element={<Projects />} />
          <Route path={routerPaths.auth.PROJECT_OVERVIEW} element={<ProjectOverview />} />
          <Route path={routerPaths.auth.PROFILE} element={<Profile />} />
          {(WSRole === 'wsrole:owner' || WSRole === 'wsrole:admin') && (
            <Route path={routerPaths.auth.SETTINGS} element={<WorkSpaceSettings />} />
          )}
          <Route path="*" element={<RedirectToRoot />} />
        </Routes>
      )}
    </CheckWorkspaceLayout>
  );
});

export const AppRoutes: React.FC = observer(() => {
  const {
    profileStore: { isAuthorized, error, logout, loadVersion, isTokenLink },
  } = useAppDataStore();

  useEffect(() => {
    !isUrlProd && loadVersion();
  }, []);

  const location = useLocation();
  if (isUrlProd || isUrlDev) {
    useEffect(() => {
      if (location.pathname !== '/') {
        const sendHit = () => {
          // @ts-ignore
          window.ym(getYmCounter(), 'hit', location.pathname);
        };
        const timeoutId = setTimeout(sendHit, 500);
        return () => clearTimeout(timeoutId);
      }
    }, [location.pathname]);
  }

  return (
    <DataLoader fallback={<Preloader />}>
      <AuthorizedOnly isAuthorized={isAuthorized} logout={logout}>
        <RoutesLayout>
          <Routes>
            {error && isServerError(error) && <Route element={<ErrorPage />} />}
            {error && isInvalidRequestServer(error) && (
              <Route element={<ErrorPageInvalidRequest textError="errorUserAccount" />} />
            )}
            <Route path={routerPaths.auth.WSLIST} element={<RootPage />} />
            <Route path={routerPaths.auth.ROOT} element={<RootPage />} />
            <Route path={routerPaths.auth.PROFILE} element={<Profile />} />
            <Route path={routerPaths.workspaces.BASE} element={<WorkspaceRoutes />} />
          </Routes>
        </RoutesLayout>
      </AuthorizedOnly>
      <UnAuthorizedOnly isAuthorized={isAuthorized}>
        <Routes>
          {error && isServerError(error) && <Route element={<ErrorPage />} />}
          {error && isInvalidRequestServer(error) && (
            <Route element={<ErrorPageInvalidRequest textError="errorUserAccount" />} />
          )}
          <Route path={routerPaths.unAuth.REGISTRATION} element={<RegistrationPage />} />
          <Route path={routerPaths.unAuth.CONFIRM_EMAIL} element={<RegistrationPage />} />
          <Route path={routerPaths.unAuth.NEW_PASSWORD} element={<RegistrationPage />} />
          <Route path={routerPaths.unAuth.REGISTRATION_CHECK_MAIL} element={<CheckMailPage />} />
          <Route path={routerPaths.unAuth.LOGIN} element={<LoginPage />} />
          <Route path={routerPaths.unAuth.CREATE_PASSWORD} element={<CreatePasswordPage />} />
          <Route path={routerPaths.unAuth.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          <Route path={routerPaths.unAuth.REGISTRATION_SOCIAL} element={<RegistrationSocialPage />} />
          {isTokenLink && <Route path={`${routerPaths.unAuth.PROFILE}/:params*`} element={<Profile />} />}
          <Route path="*" element={<RedirectToLogin />} />
        </Routes>
      </UnAuthorizedOnly>
      <NotificationRenders />
    </DataLoader>
  );
});
